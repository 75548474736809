import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBase, ObjectTypeInfo, RentalObjectInfo } from 't4core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ObjectTypeInfoWithIntervalPrice } from '../../../../lib/Models/ObjectTypeInfoWithIntervalPrice';

@Component({
  selector: 'app-addon-popup',
  templateUrl: './addon-popup.component.html',
  styleUrls: ['./addon-popup.component.css']
})
export class AddonPopupComponent extends DialogBase implements OnInit {

  public product: ObjectTypeInfoWithIntervalPrice;
  public haveInsurance: boolean = true;
  public bookingConfirmed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddonPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { product: ObjectTypeInfoWithIntervalPrice, confirmed: boolean }) {
    super("AddonPopupComponent");
    if (this.data.product) this.product = this.data.product;
    this.bookingConfirmed = this.data.confirmed;
    
  }

  public onNoClick(): void {
    if (this.product.Id == 1004 || this.product.Id == 901) {
      this.haveInsurance = false;
    }
    else {
      this.dialogRef.close(false);
    }
  }

  public confirm() {
    this.dialogRef.close(true);
  }

  public onNoInsurance() {
    this.dialogRef.close(false);
  }

  async ngOnInit() {
  }

  public closeDialog() {
    this.dialogRef.close(true);
  }
}
