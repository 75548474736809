import { Injectable } from '@angular/core';
import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { SettingsService, T4Environment } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class InsightsService {

  private appInsights: ApplicationInsights;
  private properties: any = {};

  // Add/Update a give property
  public setProperty(key: string, value: string) {
    this.properties[key] = value;
  }

   constructor(private settings: SettingsService) {
      this.init();
  }

  private init() {
    if (!this.settings.appInsightsKey) return;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.settings.appInsightsKey,
        maxAjaxCallsPerView: -1,
        enableAutoRouteTracking: false,
        
      }
    });

    this.appInsights.loadAppInsights();
  }

  private currentPage: { name: string, url: string };
  public logPageView(name?: string, url?: string) {
    if (!this.appInsights) this.init();
    if (!this.appInsights) return;

    if (this.currentPage) {
      this.appInsights.stopTrackPage(this.currentPage.name, this.currentPage.url);
      this.currentPage = null;
    }

    var x: IPageViewTelemetry;
    this.appInsights.trackPageView({
      name: name,
      uri: url,
      properties: this.AddGlobalProperties()
    });

    this.currentPage = { name: name, url: url };
  }

  public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    if (!this.appInsights) this.init();
    if (!this.appInsights) return;

    this.appInsights.trackEvent({
      name: name,
      properties: this.AddGlobalProperties(properties),
      measurements: measurements 
    });
    } 

  public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    if (!this.appInsights) this.init();
    if (!this.appInsights) return;

      this.appInsights.trackException({
        error: error,
        properties: this.AddGlobalProperties(properties),
        measurements: measurements
      });
    }

  public logDependency(method: string, url: string, path: string, time: number, success: boolean, resultCode: number) {
    if (!this.appInsights) this.init();
    if (!this.appInsights) return;

      this.appInsights.trackDependencyData(
        {
          id: this.newId(),
          type: method,
          name: url,
          data: path,
          duration: time,
          success: success,
          responseCode: resultCode,
          properties: this.AddGlobalProperties()
        });
    }

  private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
      properties = {};
    }

    //add your custom properties such as app version
    properties.appName = this.settings.appName;
    properties.appVersion = this.settings.appVersion;
    properties.Environment = T4Environment[this.settings.Environment];

    // Add other properties
    for (var key in this.properties)
      properties[key] = this.properties[key];

    return properties;
  }

    private newId():string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public setAuthenticatedUserId(userId: string, impersonating: string = null): void {
      if (!this.appInsights) this.init();
      if (!this.appInsights) return;

      var val = userId;
        if (impersonating) val += " (" + impersonating + ")";
        this.appInsights.setAuthenticatedUserContext(val);
    }
} 
