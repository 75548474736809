/// <reference types="@angular/google-maps" />
import { UIService, InsightsService, ApiService, SettingsService } from 't4core';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Observable, timer } from 'rxjs';
import * as moment from 'moment';

import { DeviceServiceState, DeviceStatus } from '../../models/deviceStatus';
import { Coordinates } from '../../models/coordinates';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { GPSLocation } from '../../models/gps';
import { PermissionCheckResult } from '../../models/permission-check-result';
import { PhotoResult } from '../../models/camera';
import { FindLockResult, UnlockIglooPinResult, UnlockResult } from '../../models/lock';

/* App Interface */
declare var appTakePhoto: Function;
declare var appEnsureCamera: Function;
declare var appEnsureBluetooth: Function;
declare var appEnsureLocation: Function;
declare var appPermissions: Function;
declare var appLocationSettings: Function;
declare var appLoadingComplete: Function;
declare var appGetLocation: Function;
declare var appFindLock: Function;
declare var appfindMultipleLocks: Function;
declare var appUnlock: Function;
declare var appOfflineUnlock: Function;
declare var openExternal: Function;
declare var appGetStore: Function;
declare var appRestartApp: Function;

declare var appStartNfcSearch: Function;
declare var appRequestAppReview: Function;

declare var appRequestState: Function;
/* App Interface */
var fireAndForgetCommands = [
  "appRequestState",
  "appRequestAppReview"
]



@Injectable({
  providedIn: 'root'
})
export class AppIntegrationService {

  private _pendingCalls: any = {};
  public testData: any;

  public locationChanged: EventEmitter<Coordinates> = new EventEmitter<Coordinates>();
  private loadingInterval: number;

  public Device: DeviceStatus = {
    Bluetooth: DeviceServiceState.None,
    Location: DeviceServiceState.None,
    Camera: DeviceServiceState.None,
    Nfc: DeviceServiceState.None,

    IsInitialized: false
  };

  // Notifies any subscriber when a nfc tag has been detected
  public nfcDetected: EventEmitter<string> = new EventEmitter<string>();
  public nfcSearchStopped: EventEmitter<boolean> = new EventEmitter<boolean>();

  public uid: number = Math.random(); 

  constructor(
    zone: NgZone,
    private ui: UIService,
    private insights: InsightsService,
    private Api: ApiService,
    private settings: SettingsService,
    private appSettings: AppSettingsService) {

    // wire up interface
    window['showPopup'] = (headline, message) => {
      zone.run(() => {
        this.ui.alert("Test", headline, "Test1", message);
      });
    };

    window['returnValue'] = (key, returnValue) => {
      zone.run(() => {
        this.markAsInitialized();

        if (key == "appGetLocation") {
          var y = JSON.parse(returnValue);
          this.lastKnownLocation = { Latitude: y.Latitude, Longitude: y.Longitude };
          this.lastKnowLocationTime = new Date();
        }

       //this.insights.logEvent("ReturnValue: " + key + "=" + returnValue);
        this._pendingCalls[key] = returnValue;
      });
    };

    window['notifyGPSState'] = (state: DeviceServiceState) => {
      zone.run(() => {
        this.Device.Location = state;
        this.markAsInitialized();
      });
    };

    window['notifyBTState'] = (state: DeviceServiceState) => {
      zone.run(() => {
        this.Device.Bluetooth = state;
        this.markAsInitialized();
      });
    };

    window['notifyNfcState'] = (state: DeviceServiceState) => {
      zone.run(() => {
        this.Device.Nfc = state;
        this.markAsInitialized();
      });
    };

    window['notifyCameraState'] = (state: DeviceServiceState) => {
      zone.run(() => {
        this.Device.Camera = state;
        this.markAsInitialized();
      });
    };

    window['notifyDeviceInitialized'] = () => {
      zone.run(() => {
        this.markAsInitialized();
      });

      return true;
    };

    // Receive nfc tags from device
    window['nfcDetected'] = (data: string) => {
      this.markAsInitialized();

      zone.run(() => {
        this.nfcDetected.emit(data);
      });

      return true;
    };

    // Receive nfc search stopped event
    window['nfcSearchStopped'] = (data: string) => {
      this.markAsInitialized();

      zone.run(() => {
        this.nfcSearchStopped.emit(true);
      });

      return true;
    };

    // For backwards compatibility befor 4.3
    if (!this.appSettings.meetsMinimumAppVersion(4.3)) {
      this.Device.IsInitialized = (this.appSettings.appType === 'app');
      this.Device.Camera = 3;
    }

    this.insights.logEvent("Web app initialized");
  }

  private markAsInitialized() {
    if (!this.Device.IsInitialized) {
      this.Device.IsInitialized = true;
      this.insights.logEvent("App initialized: " + this.Device.IsInitialized + " - " + this.uid);

        // Send any cached commands
      if (this.webLoadingComplete) {
        this.sendCommand(appLoadingComplete, {});
      }
    }
  }

  /* Misc */
  private webLoadingComplete = false;
  public notifyLoadingComplete() {
    if (this.appSettings.meetsMinimumAppVersion(4.3) && this.appSettings.appType === 'app') {
      if (!this.Device.IsInitialized) {
        this.webLoadingComplete = true;
      } else {
        this.sendCommand(appLoadingComplete, {});
      }
    }
  }

  public requestDeviceState() {
    if (this.Device.IsInitialized && !this.appSettings.meetsMinimumAppVersion(4.0)) return;

    try {
      // Do this asynchronously to avoid stopping execution in dev
      setTimeout(() => this.sendCommand<boolean>(appRequestState), 10);
    } catch (ex) { }
  }

  // Start searching for NFC devices (Required only on IOS)
  public async requestAppReview() {
    if (!this.appSettings.meetsMinimumAppVersion(4.5)) return;

    try {
      await this.sendCommand<boolean>(appRequestAppReview);
    } catch (ex) {
      this.insights.logError(ex);
    }
  }

  public openPermissions() {
    this.sendCommand<boolean>(appPermissions);
  }

  public openLocations() {
    this.sendCommand<boolean>(appLocationSettings);
  }

  /* GPS */
  public setLocationUpdateInterval(interval?: number) {
    if (this.loadingInterval) window.clearInterval(this.loadingInterval);

    if (interval) {
      window.setTimeout(() => this.updateCoordinates(), 50);
      this.loadingInterval = window.setInterval(() => this.updateCoordinates(), interval);
    }
  }

  private async updateCoordinates() {
    var y = await this.sendCommand<GPSLocation>(appGetLocation, null, 5);
    if (y && y.Success) {
      this.lastKnownLocation = { Latitude: y.Latitude, Longitude: y.Longitude };
      this.lastKnowLocationTime = new Date();
      this.locationChanged.emit(this.lastKnownLocation);
      this.appSettings.registerLastKnownPosition(this.lastKnownLocation);
    }
  }

  public async ensureLocation(): Promise<PermissionCheckResult> {
    if (!this.appSettings.meetsMinimumAppVersion(3.1)) return { Success: true };

    try {
      return await this.sendCommand<PermissionCheckResult>(appEnsureLocation);
    } catch (ex) {
      this.insights.logError(ex);
    }

    return { Success: false, Reason: -1 };
  }

  private lastKnownLocation: Coordinates;
  private lastKnowLocationTime: Date;
  private browserPosition: Coordinates;
  public async getCoordinates(isRequired: boolean = false): Promise<Coordinates> {
    // Integrate with device only if running in app mode
    if (this.appSettings.appType == 'app' && document.location.host.indexOf("localhost") < 0) {
      if (isRequired) {
        var ret = await this.ensureLocation()
        if (!ret.Success) {
          // Show dialog
          this.insights.logEvent("Ensure location failed", { Result: JSON.stringify(ret) });
        }
      } else {
        // Cache for 15 seconds
        if (this.lastKnownLocation && this.lastKnowLocationTime > new Date(new Date().getTime() - 15000)) { // Older than 15?
          return this.lastKnownLocation;
        }
      }

      try {
        var currentLast = this.lastKnowLocationTime;
        var y = await this.sendCommand<GPSLocation>(appGetLocation, null, 15);
        if (y && y.Success) {
          this.lastKnownLocation = { Latitude: y.Latitude, Longitude: y.Longitude };
          this.lastKnowLocationTime = new Date();
          this.appSettings.registerLastKnownPosition(this.lastKnownLocation);

          return this.lastKnownLocation;
        } else {
          if (currentLast != this.lastKnowLocationTime) {
            return this.lastKnownLocation;
          }
        }
      } catch (ex) {
        this.insights.logError(ex);
      }

      if (this.lastKnownLocation) this.insights.logEvent("Position failed", { Cached: JSON.stringify(this.lastKnownLocation), CacheTime: this.lastKnowLocationTime.toISOString() });
      else this.insights.logEvent("Position failed", { Cached: 'None' });
    } else {
      if (this.browserPosition) return this.browserPosition;

      return await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resp => {
          this.lastKnownLocation = { Latitude: resp.coords.latitude, Longitude: resp.coords.longitude };
          this.lastKnowLocationTime = new Date();
          this.locationChanged.emit(this.lastKnownLocation);
          this.appSettings.registerLastKnownPosition(this.lastKnownLocation);
          this.browserPosition = this.lastKnownLocation

          resolve(this.lastKnownLocation);
        },
          err => {
            this.lastKnownLocation = { Latitude: this.settings.clientSettings.DefaultLatitude, Longitude: this.settings.clientSettings.DefaultLongitude };
            this.lastKnowLocationTime = new Date();
            this.locationChanged.emit(this.lastKnownLocation);
            this.browserPosition = this.lastKnownLocation
            this.appSettings.registerLastKnownPosition(this.lastKnownLocation);
            resolve(this.lastKnownLocation);
          });
      });
    }

    return this.lastKnownLocation;
  }

  public async getDistanceFromUser(lat: number, lng: number): Promise<number> {
    return this.getDistance({ Latitude: lat, Longitude: lng }, await this.getCoordinates())
  }

  public getDistance(a: Coordinates, b: Coordinates): number {
    var radLatA: number = a.Latitude * (Math.PI / 180.0);
    var radLngA: number = a.Longitude * (Math.PI / 180.0);
    var radLatB: number = b.Latitude * (Math.PI / 180.0);
    var radLngB: number = b.Longitude * (Math.PI / 180.0);

    var dLat = radLatB - radLatA;
    var dLng = radLngB - radLngA;

    var tmp = Math.pow(Math.sin(dLat / 2), 2) + Math.cos(radLatA) * Math.cos(radLatB) * Math.pow(Math.sin(dLng / 2), 2);

    var greatCircle = 2 * Math.atan2(Math.sqrt(tmp), Math.sqrt(1 - tmp));

    var distance = greatCircle * 6376.5; // Multiply with earth radius

    return distance; // Convert to meters
  }

  /* Camera */

  public async ensureCameraAccess(): Promise<boolean> {
    console.log("app-integration-service", this.appSettings.appVersion);
    if (!this.appSettings.meetsMinimumAppVersion(4.6)) return true;
    try {
      var res = await this.sendCommand<PermissionCheckResult>(appEnsureCamera);
      if (res && res.Success) return true;
    } catch (ex) {
      this.insights.logError(ex);
    }

    return false;
  }

  public async takePhoto(uploadUri: string, uploadToken: string): Promise<PhotoResult> {
    try {
      return await this.sendCommand<PhotoResult>(appTakePhoto, { UploadUri: uploadUri, UploadToken: uploadToken });

    } catch (ex) {
      this.insights.logError(ex);
    }

    return null;
  }

  public findPendingPhoto(): PhotoResult {
    if (this._pendingCalls[appTakePhoto.name] != undefined) {
      return JSON.parse(this._pendingCalls[appTakePhoto.name]);
    }

    return null;
  }

  /* NFC */
  // Start searching for NFC devices (Required only on IOS)
  public async startNfcSearch(): Promise<boolean> {
    if (!this.appSettings.meetsMinimumAppVersion(4.4)) return false;

    try {
      return await this.sendCommand<boolean>(appStartNfcSearch);
    } catch (ex) {
      this.insights.logError(ex);
    }

    return false;
  }

  /* Lock */
  public async ensureBluetooth(): Promise<PermissionCheckResult> {
    if (!this.appSettings.meetsMinimumAppVersion(3.1)) return { Success: true };

    try {
      return await this.sendCommand<PermissionCheckResult>(appEnsureBluetooth);
    } catch (ex) {
      this.insights.logError(ex);
    }

    return { Success: true, Reason: -1 };
  }

  public async findLock(id: string): Promise<FindLockResult> {
    var access = await this.ensureBluetooth();
    if (!access) return {
      Success: false,
      Mac: "",
      Session: ""
    };


    try {

      return await this.sendCommand<FindLockResult>(appFindLock, { LockId: id });
    } catch (ex) {
      this.insights.logError(ex);
    }

    return null;
  }

  public async findOneLockOfMany(ids: string): Promise<FindLockResult> {
    var access = await this.ensureBluetooth();
    if (!access.Success) return {
      Success: false,
      Mac: "",
      Session: "",
      Reason: access.Reason
    };

    try {
      return await this.sendCommand<FindLockResult>(appfindMultipleLocks, { LockId: ids });
    } catch (ex) {
      this.insights.logError(ex);
    }

    return null;
  }

  public async unlockNoke(id: string, rentalId: number, sessionString: string): Promise<UnlockResult> {
    if (!rentalId) {
      throw new Error('Attempted to call appIntegrationService.unlockNoke without the required rentalId');
    }
    let nokeResult: string = await this.Api.get<string>("Rental/UpdatedUnlockNoke", { macAddress: id, sessionString: sessionString, rentalId: rentalId });
    return this.handleNokeResult(id, nokeResult);
  }

  public async unlockNokeByAdmin(id: string, sessionString: string): Promise<UnlockResult> {
    let nokeResult: string = await this.Api.get<string>("Rental/UpdatedUnlockNokeByAdmin", { macAddress: id, sessionString: sessionString });
    return this.handleNokeResult(id, nokeResult);
  }

  public async unshackleNoke(id: string, sessionString: string): Promise<UnlockResult> {
    let nokeResult: string = await this.Api.get<string>("Rental/UpdatedUnshackleNoke", { macAddress: id, sessionString: sessionString });
    return this.handleNokeResult(id, nokeResult);
  }

  public async firmwareUpdateNoke(id: string, sessionString: string): Promise<UnlockResult> {
    let nokeResult: string = await this.Api.get<string>("Rental/UpdatedFirmwareUpdateNoke", { macAddress: id, sessionString: sessionString });
    return this.handleNokeResult(id, nokeResult);
  }
  private async handleNokeResult(id: string, nokeResult: string): Promise<UnlockResult> {
    if (!nokeResult) {
      this.testData = "Yo";
      var result: UnlockResult = new UnlockResult;
      result.Success = false;
      return result;
    }
    try {
      let race = Promise.race([
        this.sendCommand<UnlockResult>(appUnlock, { LockId: id, Keys: nokeResult }),
        new Promise((resolve, reject) => {
          let wait = setTimeout(() => {
            clearTimeout(wait);
            resolve('TimeOut');
          }, 5000)
        })
      ]);

      var ret = await race;
      var result: UnlockResult = new UnlockResult;
      result.Success = (ret != "TimeOut");
      return result;
    }
    catch (error) {
      var result: UnlockResult = new UnlockResult;
      result.Success = false;
      return result;
    }
  }

  public async getLockInfo(macs): Promise<any> {
    var nokeResult = await this.Api.get<any>("Rental/UpdatedLockInfoNoke", { macs: macs });
    return nokeResult;
  }

  public async openExternal(url: string) {

    try {
      return await this.sendCommand(openExternal, { url: url });
    } catch (e) {
      window.open(url, '_blank');
    } finally {
      return null;
    }

  }

  public async restartApp(restart: boolean = true, bookingId = null) {
    if (restart) {
      await this.sendCommand(appRestartApp);
    }
  }

  //Old
  //public async unlock(id: string): Promise<UnlockResult> {
  //  try {
  //    return await this.sendCommand<UnlockResult>(appUnlock, { LockId: id });
  //  } catch (ex) {
  //    this.insights.logError(ex);
  //  }

  //  return null;
  //}

  /* Timers */
  private secondTimer: Observable<number>;
  private minuteTimer: Observable<number>;

  public getSecondTimer(): Observable<number> {
    if (this.secondTimer == null) this.secondTimer = timer(0, 1000);

    return this.secondTimer;
  }

  public getMinuteTimer(): Observable<number> {
    if (this.minuteTimer == null) this.minuteTimer = timer(0, 60000);

    return this.minuteTimer;
  }

  /* Inter-process communication */
  private async waitForCommand<T>(commandName: string): Promise<T> {
    // Clear pending calls
    this._pendingCalls[commandName] = undefined;

    // Wait for the return value
    var ret = await new Promise<T>(async (resolve, reject) => {
      var startTime = moment(); // Save start time

      // Wait until return value is set or 10 s has passed
      while (this._pendingCalls[commandName] == undefined) {
        // Timeout after 10s
        if (startTime < moment().add(-100, 'second')) {
          resolve(null);
          break;
        }

        // Sleep 100 ms before trying again
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // Return the value
      if (this._pendingCalls[commandName] == "") this._pendingCalls[commandName] = "{}"; // Hande empty results
      resolve(JSON.parse(this._pendingCalls[commandName]));
    });

    // Clear pending calls
    this._pendingCalls[commandName] = undefined;

    //Return the value
    return ret;
  }

  private async sendCommand<T>(command: Function, parameter?: any, timeout?: number): Promise<T> {
    if (!this.Device.IsInitialized) {
      this.insights.logEvent("Device not there!")
      return null;
    }

    var commandName = command.name;

    // Clear pending calls
    this._pendingCalls[commandName] = undefined;

    // Make the call
    this.insights.logEvent("Sending app command: " + commandName, { command: commandName, parameter: parameter });
    if (parameter)
      command(parameter);
    else
      command();

      // Dont wait for fir & forget commands
    if (fireAndForgetCommands.some(x => x == commandName)) return;

    // Wait for the return value
    var ret = await new Promise<T>(async (resolve, reject) => {
      var startTime = new Date(); // Save start time

      var hasTimedOut = false;
      // Wait until return value is set or 10 s has passed
      if (!timeout) timeout = 30; // Default 30s
      while (this._pendingCalls[commandName] == undefined) {
        // Timeout after 10s
        if (startTime.getTime() < new Date().getTime() - (timeout * 1000)) {
          //this.insights.logEvent("Command timeout", { command: commandName });
          hasTimedOut = true;
          resolve(null);
          break;
        }

        // Sleep 100 ms before trying again
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // Return the value
      if(!hasTimedOut) this.insights.logEvent("Command returned: " + commandName, { command: commandName, results: this._pendingCalls[commandName] });
      if (this._pendingCalls[commandName] == undefined) this._pendingCalls[commandName] = "{}";
      if (this._pendingCalls[commandName] == "") this._pendingCalls[commandName] = "{}"; // Hande empty results
      resolve(JSON.parse(this._pendingCalls[commandName]));
    });

    // Clear pending calls
    this._pendingCalls[commandName] = undefined;

    //Return the value
    return ret;
  }

  public async updateLockStatus(mac, version, updateStatus = true, type = 1) {
    var lockInfo = await this.getLockInfo([mac]);

    if (lockInfo && lockInfo.length == 1) {
      if (updateStatus) {
        await this.Api.get<any>("Rental/UpdateLockStatus", { macAddress: mac, battery: lockInfo[0].internal_battery });
      }

      await this.Api.get<any>("Rental/SaveLockActivity", { lockId: lockInfo[0].serial, batteryLevel: lockInfo[0].internal_battery, type: type, version: (version ? version : lockInfo[0].fw_version) });

    }
  }

  public async unlockIglooLock(payload: string, rentalId: number, timezone: string): Promise<UnlockResult> {
    if (!rentalId) {
      throw new Error('Attempted to call appIntegrationService.unlockIglooLock without the required rentalId');
    }
    let result: string = await this.Api.get<string>("Rental/UnlockIglooLock", { rentalId: rentalId, payload: payload, timezone: timezone });
    return; //this.handleNokeResult(id, nokeResult);
  }

  public async getTimeZoneConfiguration(timezoneId: string, rentalId: number): Promise<UnlockResult> {
    if (!rentalId) {
      throw new Error('Attempted to call appIntegrationService.getTimeZoneConfiguration without the required rentalId');
    }
    let result: string = await this.Api.get<string>("Rental/GetTimeZoneConfiguration", { rentalId: rentalId, timezoneId: timezoneId });
    return;// this.handleNokeResult(id, nokeResult);
  }

  public async getPairedLocks(rentalId: number): Promise<UnlockResult> {
    if (!rentalId) {
      throw new Error('Attempted to call appIntegrationService.getPairedLocks without the required rentalId');
    }
    let result: string = await this.Api.get<string>("Rental/GetPairedLocks", { rentalId: rentalId });
    return;// this.handleNokeResult(id, nokeResult);
  }

  public async unlockIglooLockGuest(lockId: string, rentalId: number, startDate: string, endDate: string, permissions: string[]): Promise<UnlockResult> {
    if (!rentalId) {
      throw new Error('Attempted to call appIntegrationService.unlockIglooLockGuest without the required rentalId');
    }
    let result: string = await this.Api.get<string>("Rental/UnlockIglooLockGuest", { rentalId: rentalId, lockId: lockId, startDate: startDate, endDate: endDate, permissions: permissions });
    return;// this.handleNokeResult(id, nokeResult);
  }

  public async updateIglooLockStatus(lockId, updateStatus = true, type = 1) {
    if (updateStatus) {
        await this.Api.get<any>("Rental/UpdateIglooLockStatus", { lockId: lockId });
      }

      await this.Api.get<any>("Rental/SaveLockActivity", { lockId: lockId, type: type, version: 1 });
  }

  public async getIglooLockInfo(lockId): Promise<any> {
    var nokeResult = await this.Api.get<any>("Rental/UpdatedLockInfoNoke", { lockId: lockId });
    return nokeResult;
  }

  private async handleIglooPinResult(id: string, iglooResult: string): Promise<UnlockIglooPinResult> {
    if (!iglooResult || iglooResult == "") {
      var result: UnlockIglooPinResult = new UnlockIglooPinResult;
      result.Success = false;
      return result;
    }
    try {
      var result: UnlockIglooPinResult = new UnlockIglooPinResult;
      result.Success = true;
      result.Pin = iglooResult;
      return result;
    }
    catch (error) {
      var result: UnlockIglooPinResult = new UnlockIglooPinResult;
      result.Success = false;
      return result;
    }
  }
}
