import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import moment from 'moment';
import { AuthService } from './auth.service';
import { InsightsService } from './insights.service';
import { SettingsService, T4Environment } from './settings.service';
import { last, map, tap } from 'rxjs/operators';
import { UIService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private pincode = {
    savedPin: "",
    timeStamp: moment()
  };

  private activeCalls: any[] = [];

    constructor(private http: HttpClient, private settings: SettingsService, private auth: AuthService, private dialog: MatDialog, private insights: InsightsService, private router: Router, private UI: UIService) {
        router.events.subscribe(event => {
            if (this._cancelOnNavigation && event instanceof NavigationStart) {
                while (this.activeCalls.length > 0) {
                    var c = this.activeCalls.pop();
                    c.subscription.unsubscribe();
                }
            }
        });
  }

  private _cancelOnNavigation: boolean = true;
  public setCancelOnNavigation(val: boolean) {
    this._cancelOnNavigation = val;
  }

  private _subContext: string = "";
  public setSubContext(subContext: string) {
    this._subContext = subContext;
  }

  async getVersion(): Promise<string> {
    var headers = await this.createRequestOptions();

    var realPath = "Turbo.Client.Version.txt?" + Math.round(Math.random() * 10000000000);
    var ret = await this.http.get(window.location.origin + "/" + realPath, { observe: 'response', headers: headers, responseType: 'text' }).toPromise();

    // Set environment based on T4 header
    if (ret.headers.get("t4environment")?.toLowerCase() === "test") {
      this.settings.setEnvironment(T4Environment.Test);
    }
    else if (ret.headers.get("t4environment")?.toLowerCase() === "development") {
      this.settings.setEnvironment(T4Environment.Development);
    }
    else if (ret.headers.get("t4environment")?.toLowerCase() === "staging") {
      this.settings.setEnvironment(T4Environment.Staging);
    }
    else if (ret.headers.get("t4environment")?.toLowerCase() === "anata") {
      this.settings.setEnvironment(T4Environment.Anata);
    }

    return ret.body;
  }

    getUri(path: string, parameters?: any) {
        var uri = path;
        if (parameters) {
            var p = new URLSearchParams();
            for (var x in parameters) {
                var k = x;
                var val = parameters[x];
                if (moment.isMoment(val))
                    p.append(k, val.format("YYYY-MM-DD HH:mm:ss"));
                else
                    p.append(k, val == null || val == undefined ? '' : val);
            }
            uri += '?' + p.toString();
        }

        return uri;
    }

  async get<T>(path: string, params?: any, pin?: string, extraHeaders?: any): Promise<T> {
    var headers = await this.createRequestOptions();

    var realPath = path;
    if (params) {
      var p = new URLSearchParams();
      for (var x in params) {
        var k = x;
        var val = params[x];
        if (moment.isMoment(val))
          p.append(k, val.format("YYYY-MM-DD HH:mm:ss"));
        else
          p.append(k, val == null || val == undefined ? '' : val);
      }
      realPath += '?' + p.toString();
    }

    if (pin || (this.pincode.savedPin != '' && this.pincode.timeStamp > moment().add(-5, 'minutes'))) {
      headers = headers.append("T-Pin", pin ? pin : this.pincode.savedPin);
    }

    // Append headers
    if (extraHeaders) {
      for (let header in extraHeaders)
        headers = headers.append(header, extraHeaders[header]);
    }

    var token = Math.random();
    try {
      var timing = new Date().getTime();

      var ret = await new Promise<T>((resolve, reject) => {
        // No cancellation can be done
        var value: T;
          var call = this.http.get<T>(this.absoluteUrl(realPath), { observe: 'body', headers: headers }).subscribe(
            v => { value = v; },
            err => {
              reject(err);
            },
            () => {
              resolve(value);
            });

          if (path.indexOf("Settings/") != 0) {
            this.activeCalls.push({
              token: token,
              subscription: call
            });
          }
      });
      this.insights.logDependency("GET", this.absoluteUrl(realPath), path, new Date().getTime() - timing, true, 200);

      this.registerRequest(200);
      try {
        return this.recurseObject(ret) as T;['.expires']
      } catch (ex) {
        return null;
      }
    } catch (e) {
      if (e.status == 401) {
        this.registerRequest(401);
      } else if (e.status == 412) { // Require pin
        var result = await this.UI.showPinDialog(pin);
        if (result) {
          this.pincode.savedPin = result;
          this.pincode.timeStamp = moment();
          return await this.get<T>(path, params, result);
        }
        else return null;
      } else {
        this.insights.logDependency("GET", this.absoluteUrl(realPath), path, new Date().getTime() - timing, false, e.Status);
        throw e;
      }
    } finally {
      var c = this.activeCalls.find(x => x.token == token);
      if (c) this.activeCalls.splice(this.activeCalls.indexOf(c), 1);
    }
  }

  async post<T>(path: string, data?: any, params?: any, pin?: string, extraHeaders?: any): Promise<T> {
    var headers = await this.createRequestOptions();

    var realPath = path;
    if (params) {
      var p = new URLSearchParams();
      for (var x in params) {
        var k = x;
        var val = params[x];
        p.append(k, val == null || val == undefined ? '' : val);
      }
      realPath += '?' + p.toString();
    }


    if (pin || (this.pincode.savedPin != '' && this.pincode.timeStamp > moment().add(-5, 'minutes'))) {
      headers = headers.append("T-Pin", pin ? pin : this.pincode.savedPin);
    }

    // Append headers
    if (extraHeaders) {
      for (let header in extraHeaders) 
        headers = headers.append(header, extraHeaders[header]);
      }

    try {
      var timing = new Date().getTime();

      var ret = await this.http.post<T>(this.absoluteUrl(realPath), data, { observe: 'body', headers: headers }).toPromise();
      this.insights.logDependency("POST", this.absoluteUrl(realPath), path, new Date().getTime() - timing, true, 200);

      this.registerRequest(200);
      try {
        return this.recurseObject(ret) as T;
      } catch (ex) {
        return null;
      }
    } catch (e) {
      if (e.status == 401) {
        this.registerRequest(401);
      } else      if (e.status == 412) { // Require pin
        var result = await this.UI.showPinDialog(pin);

        if (result) {
          this.pincode.savedPin = result;
          this.pincode.timeStamp = moment();

          return await this.post<T>(path, data, params, result);
        }
        else return null;
      } else {
        this.insights.logDependency("POST", this.absoluteUrl(realPath), path, new Date().getTime() - timing, true, e.status);
        throw e;
      }
    }
  }

  async put<T>(path: string, data?: any, params?: any, pin?: string, extraHeaders?: any): Promise<T> {
        var headers = await this.createRequestOptions();

        var realPath = path;
        if (params) {
            var p = new URLSearchParams();
            for (var x in params) {
                var k = x;
                var val = params[x]; 
                p.append(k, val == null || val == undefined ? '' : val);
            }
            realPath += '?' + p.toString();
        }

      if (pin || (this.pincode.savedPin != '' && this.pincode.timeStamp > moment().add(-5, 'minutes'))) {
        headers = headers.append("T-Pin", pin ? pin : this.pincode.savedPin);
      }

      // Append headers
      if (extraHeaders) {
        for (let header in extraHeaders)
          headers = headers.append(header, extraHeaders[header]);
      }

        try {
            var timing = new Date().getTime();

            var ret = await this.http.put<T>(this.absoluteUrl(realPath), data, { observe: 'body', headers: headers }).toPromise();
            this.insights.logDependency("PUT", this.absoluteUrl(realPath), path, new Date().getTime() - timing, true, 200);

          this.registerRequest(200);
            try {
                return this.recurseObject(ret) as T;
            } catch (ex) {
                return null;
            }
        } catch (e) {
          if (e.status == 401) {
            this.registerRequest(401);
          }  else if (e.status == 412) { // Require pin
            var result = await this.UI.showPinDialog(pin);
                if (result) {
                    this.pincode.savedPin = result;
                    this.pincode.timeStamp = moment();
                    return await this.put<T>(path, data, params, result);
                }
                else return null;
            } else {
                this.insights.logDependency("PUT", this.absoluteUrl(realPath), path, new Date().getTime() - timing, true, e.status);
                throw e;
            }
        }
    }

  async delete<T>(path: string, params?: any, pin?: string, extraHeaders?: any): Promise<T> {
        var headers = await this.createRequestOptions();

        var realPath = path;
        if (params) {
            var p = new URLSearchParams();
            for (var x in params) {
                var k = x;
                var val = params[x];
                if (moment.isMoment(val))
                    p.append(k, val.format("YYYY-MM-DD HH:mm:ss"));
                else
                    p.append(k, val == null || val == undefined ? '' : val);
            }
            realPath += '?' + p.toString();
        }

      if (pin || (this.pincode.savedPin != '' && this.pincode.timeStamp > moment().add(-5, 'minutes'))) {
        headers = headers.append("T-Pin", pin ? pin : this.pincode.savedPin);
      }

      // Append headers
      if (extraHeaders) {
        for (let header in extraHeaders)
          headers = headers.append(header, extraHeaders[header]);
      }

        var token = Math.random();
        try {
            var timing = new Date().getTime();

            var ret = await new Promise<T>((resolve, reject) => {
                // No cancellation can be done
                var value;
                var call = this.http.delete<T>(this.absoluteUrl(realPath), { observe: 'body', headers: headers }).subscribe(function (v) {
                    value = v;
                }, reject, function () {
                    resolve(value);
                });

            });
            this.insights.logDependency("DELETE", this.absoluteUrl(realPath), path, new Date().getTime() - timing, true, 200);

          this.registerRequest(200);
            try {
                return this.recurseObject(ret) as T;
            } catch (ex) {
                return null;
            }
        } catch (e) {
          if (e.status == 401) {
            this.registerRequest(401);
          } else if (e.status == 412) { // Require pin
            var result = await this.UI.showPinDialog(pin);
                if (result) {
                    this.pincode.savedPin = result;
                    this.pincode.timeStamp = moment();
                    return await this.delete<T>(path, params, result);
                }
                else return null;
            } else {
                this.insights.logDependency("DELETE", this.absoluteUrl(realPath), path, new Date().getTime() - timing, false, e.Status);
                throw e;
            }
        } finally {
            var c = this.activeCalls.find(x => x.token == token);
            if (c) this.activeCalls.splice(this.activeCalls.indexOf(c), 1);
        }
    }



  private absoluteUrl(rel: string) {
    var ret: string = this.settings.getApiBase();

    if (!ret.endsWith("/") && !rel.startsWith("/")) ret += "/" + rel;
    else if (ret.endsWith("/") && rel.startsWith("/")) ret += rel.substr(1);
    else ret += rel;

    return ret;
  }

  private async createRequestOptions(): Promise<HttpHeaders> {
    if (this.settings.AuthorizationToken && new Date(this.settings.AuthorizationToken['.expires']) < new Date()) {
      await this.auth.Reauthenticate();
    }

    var token = this.settings.getToken().access_token;

    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'AppName': this.settings.appName,
      'Authorization': 'Bearer ' + token,
      'CustomerUID': this.settings.customerUserId ? this.settings.customerUserId : '',
      'SubContext': this._subContext ? this._subContext : ''
    });
  }

  

  async call<T>(method: 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS', path: string, params?: any, pin?: string, extraHeaders?: any, progress?: (x:HttpProgress) => void): Promise<T> {
    var headers = await this.createRequestOptions();

    var realPath = path;

    if (params) {
      var p = new URLSearchParams();
      for (var x in params) {
        var k = x;
        var val = params[x];
        if (moment.isMoment(val))
          p.append(k, val.format("YYYY-MM-DD HH:mm:ss"));
        else
          p.append(k, val == null || val == undefined ? '' : val);
      }
      realPath += '?' + p.toString();
    }

    if (pin || (this.pincode.savedPin != '' && this.pincode.timeStamp > moment().add(-5, 'minutes'))) {
      headers = headers.append("T-Pin", pin ? pin : this.pincode.savedPin);
    }

    // Append headers
    if (extraHeaders) {
      for (let header in extraHeaders)
        headers = headers.append(header, extraHeaders[header]);
    }

    var token = Math.random();
    try {
      var timing = new Date().getTime();

      var ret = await new Promise<T>((resolve, reject) => {
        // No cancellation can be done
        var value: T;
        var req = new HttpRequest<T>(method, this.absoluteUrl(realPath), null, { headers: headers, reportProgress: (progress != undefined) });
        var call = this.http.request<T>(req)
          .pipe(
            map(event => this.getEventMessage<T>(event, progress)),
            last(), // return last (completed) message to caller
        )
        .subscribe(function (v) {
          value = v as T;
        }, reject, function () {
          resolve(value);
        });

        if (path.indexOf("Settings/") != 0) {
          this.activeCalls.push({
            token: token,
            subscription: call
          });
        }
      });
      this.insights.logDependency(method, this.absoluteUrl(realPath), path, new Date().getTime() - timing, true, 200);

      this.registerRequest(200);
      try {
        return this.recurseObject(ret) as T;
      } catch (ex) {
        return null;
      }
    } catch (e) {
      
      if (e.status == 401) {
        this.registerRequest(401);
      } else if (e.status == 412) { // Require pin
        var result = await this.UI.showPinDialog(pin);
        if (result) {
          this.pincode.savedPin = result;
          this.pincode.timeStamp = moment();
          return await this.get<T>(path, params, result);
        }
        else return null;
      } else {
        this.insights.logDependency("GET", this.absoluteUrl(realPath), path, new Date().getTime() - timing, false, e.Status);
        throw e;
      }
    } finally {
      var c = this.activeCalls.find(x => x.token == token);
      if (c) this.activeCalls.splice(this.activeCalls.indexOf(c), 1);
    }
  }

    private dateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?$/;
    private dateRegex2 = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?Z$/;
    private dateRegex3 = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\+\d\d:\d\d)?$/;
    private recurseObject(obj: any): any {
      var result: any = obj;
      var str = "";
        if (obj !== null) {

            // Handle arrays
            if (Array.isArray(obj)) {
                obj.forEach((item, index) => {
                    obj[index] = this.recurseObject(item);
                });

                return obj;
            }

            if (obj instanceof Object) {
                result = Object.assign({}, obj);

                // Parse properties of objects
                for (var key in result) {
                    var property = result[key];
                    if (typeof property === 'object') {
                        result[key] = this.recurseObject(property);
                    } else if (typeof property === 'string' && this.dateRegex.test(property)) {
                      result[key] = moment(new Date(property + 'Z'));
                      str += "Date value (" + key + "): " + property + " - " + moment(new Date(property + 'Z')).toISOString() + "\r\n";
                    } else if (typeof property === 'string' && (this.dateRegex2.test(property) || this.dateRegex3.test(property))) {
                      result[key] = moment(new Date(property));
                      str += "Date value (" + key + "): " + property + " - " + moment(new Date(property)).toISOString() + "\r\n";
                    }
                }
            }
      }

      //if(str != "") this.post("Util/Log", "\"" + encodeURI(str) + "\"", { uid: this.settings.getUser().FriendlyName });
        return result;
    };

  private getEventMessage<T>(event: HttpEvent<T>, progress?: (x: HttpProgress) => void) {
    switch (event.type) {
      case HttpEventType.Sent:
        if (progress) progress({ status: HttpProgressStatus.ProcessingOnServer, uploadPercentage: 100, downloadPercentage: 0, downloadedBytes: 0 });
        return "";

      case HttpEventType.UploadProgress:
        if (progress) progress({ status: HttpProgressStatus.Uploading, uploadPercentage: Math.round(100 * event.loaded / (event.total ?? 0)) });
        return "";

      case HttpEventType.DownloadProgress:
        if (progress) progress({ status: HttpProgressStatus.Downloading, uploadPercentage: 100, downloadPercentage: Math.round(100 * event.loaded / (event.total ?? 0)), downloadedBytes: event.loaded });
        return "";

      case HttpEventType.Response:
        return event.body;

      case HttpEventType.ResponseHeader:
        if (progress) progress({ status: HttpProgressStatus.Downloading, uploadPercentage: 100, downloadPercentage: 0 });
        return "";
    }
  }

  // Keep track of 401´s and try to reauthenticate/reload
  private requestResultQueue = [];
  private reauthenticationCount = 0;
  private reloaded = false;
  private registerRequest(statusCode: number) {
    this.requestResultQueue.push(statusCode == 401 ? 1 : 0);
    if (this.requestResultQueue.length < 5) return;
    if (this.requestResultQueue.length > 5) this.requestResultQueue.shift();

    var sum = 0;
    this.requestResultQueue.map(x => sum += x);

    if (!this.reloaded && sum > 2) {
      // Clear all stored tokens to make sure we re-authenticate from scratch
      this.auth.clearTokens(new Date(2200, 1, 1));

      if (this.reauthenticationCount < 1) {
        this.reauthenticationCount++;
        this.auth.Reauthenticate();
      } else {
        window.location.reload();
        this.reloaded = true;
      }

      this.requestResultQueue = [];
    }
  }
}

export class HttpProgress {
  public status: HttpProgressStatus;
  public uploadPercentage?: number;
  public downloadPercentage?: number;
  public downloadedBytes?: number;
}

export enum HttpProgressStatus {
  Created,
  Sent,
  Uploading,
  ProcessingOnServer,
  Downloading
}
