import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 't4-messagebox',
    templateUrl: 't4messagebox.html',
})
export class T4MessageBox {
  public data: MessageData;

  constructor(public dialogRef: MatDialogRef<T4MessageBox>, @Inject(MAT_DIALOG_DATA) data: any) {
      this.data = data;
  }

  public shouldTranslate(text: string): boolean {
    return text && (text.toLowerCase().indexOf('turbo.') >= 0 || text.toLowerCase().indexOf('intellitrailer.') >= 0 || text.toLowerCase().indexOf('to.') >= 0 || text.toLowerCase().indexOf('t4.') >= 0);
  }
}

export class MessageData {
  public headline: string = null;
  public headlineDefault?: string = null;
  public headlineData?: any[] = null;

  public translateHeadline?(): boolean {
    return this.headline && (this.headline.toLowerCase().indexOf('turbo.') >= 0 || this.headline.toLowerCase().indexOf('intellitrailer.') >= 0 || this.headline.toLowerCase().indexOf('to.') >= 0 || this.headline.toLowerCase().indexOf('t4.') >= 0);
  }

  public message: string = null;
  public messageDefault?: string = null;
  public messageData?: any[] = null;

  public translateMessage?(): boolean {
    return this.headline && (this.headline.toLowerCase().indexOf('turbo.') >= 0 || this.headline.toLowerCase().indexOf('intellitrailer.') >= 0 || this.headline.toLowerCase().indexOf('to.') >= 0 || this.headline.toLowerCase().indexOf('t4.') >= 0);
  }

  public buttons?: MessageButton[] = [];
}

export class MessageButton {
  public text: string = null;
  public textDefault?: string = null;
  public textData?: any[] = null;

  public translateText?(): boolean {
    return this.text && (this.text.toLowerCase().indexOf('turbo.') >= 0 || this.text.toLowerCase().indexOf('intellitrailer.') >= 0 || this.text.toLowerCase().indexOf('to.') >= 0 || this.text.toLowerCase().indexOf('t4.') >= 0)
  }

  public value: string = null;
  public primary?: boolean = false;
  public color?: string = null;

  public static Ok(): MessageButton {
    return {
      text: 'turbo.core.messagebox.cmdOk',
      textDefault: 'OK',
      value: "OK",
      primary: true
    }
  }

  public static Cancel(): MessageButton {
    return {
      text: 'turbo.core.messagebox.cmdCancel',
      textDefault: 'Cancel',
      value: "Cancel",
    }
  }

  public static Yes(): MessageButton {
    return {
      text: 'turbo.core.messagebox.cmdYes',
      textDefault: 'Yes',
      value: "Yes",
      color: '#33aa33'
    }
  }

  public static No(): MessageButton {
    return {
      text: 'turbo.core.messagebox.cmdNo',
      textDefault: 'No',
      value: "No",
      color: '#ff3333'
    }
  }
}
