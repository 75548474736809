<h1 *ngIf="shouldTranslate(data.headline)">{{ data.headline | translate : data.headlineDefault : data.headlineData }}</h1>
<h1 *ngIf="!shouldTranslate(data.headline)">{{ data.headline }}</h1>
<div>
  <div>
    <p *ngIf="shouldTranslate(data.message)">{{ data.message | translate : data.messageDefault : data.messageData }}</p>
    <span *ngIf="!shouldTranslate(data.message)" [innerHTML]="data.message"></span>
  </div>
    <div style="text-align: center;">
      <button *ngFor="let i of data.buttons" mat-button style="margin: 2px;" [color]="i.primary ? 'primary' : ''" [style.background]="i.color" [mat-dialog-close]="i.value">
        <ng-container *ngIf="shouldTranslate(i.text)">{{ i.text | translate : i.textDefault : i.textData }}</ng-container>
        <ng-container *ngIf="!shouldTranslate(i.text)">{{ i.text }}</ng-container>
      </button>
    </div>
</div>
