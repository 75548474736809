import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { ServiceLocator } from '../ServiceLocator';
import { ApiService } from '../services/api.service';
import { CacheService } from '../services/cache.service';
import { InsightsService } from '../services/insights.service';
import { SettingsService } from '../services/settings.service';
import { NotificationService } from '../services/notification.service';
import { TranslationService } from '../services/translation.service';
import { UIService } from '../services/ui.service';
import { timer, Subscription } from 'rxjs';
import { OnDestroy, Directive } from '@angular/core';
import { SystemRights } from '../models/contracts/Turbo.Data.Contracts';


@Directive()
export class ComponentBase implements OnDestroy {

    public isAuthenticated: Boolean = false;
    protected currentState: string;

    public Settings: SettingsService;
    public Api: ApiService;
    public UI: UIService;
    public Insights: InsightsService;
    public Translate: TranslationService;
  public Notifications: NotificationService;
    public Cache: CacheService;

    public Rights = SystemRights;

    public loading: any = {};

    public Dialog: MatDialog;

    public get now() { return moment(); } 
    public get today() { return moment().startOf('day'); }
    public get tomorrow() { return moment().add(1, 'day'); }
    public get yesterday() { return moment().startOf('day').add(-1, 'day'); }
    public get threeDaysBack() { return moment().startOf('day').add(-3, 'day'); }
    public get oneWeekBack() { return moment().startOf('day').add(-7, 'day'); }

    //public now: moment.Moment = moment();
    //public today: moment.Moment = moment().startOf('day');
    //public tomorrow: moment.Moment = moment().add(1, 'day');
    //public yesterday: moment.Moment = moment().startOf('day').add(-1, 'day');
    //public threeDaysBack: moment.Moment = moment().startOf('day').add(-3, 'day');
    //public oneWeekBack: moment.Moment = moment().startOf('day').add(-7, 'day');
    public date(days: number): moment.Moment { return moment().startOf('day').add(days, 'day'); }
    public time(hours: number): moment.Moment { return moment().add(hours, 'hour'); }

    public dtToday: Date = this.today.toDate();

    constructor() {
        this.Settings = ServiceLocator.injector.get(SettingsService);
        this.Api = ServiceLocator.injector.get(ApiService);
        this.UI = ServiceLocator.injector.get(UIService);
        this.Insights = ServiceLocator.injector.get(InsightsService);
        this.Translate = ServiceLocator.injector.get(TranslationService);
      this.Notifications = ServiceLocator.injector.get(NotificationService);
        this.Cache = ServiceLocator.injector.get(CacheService);

        this.Dialog = ServiceLocator.injector.get(MatDialog);

      this.authenticationSubscription = this.Settings.authenticationChanged.subscribe((x: boolean) => {
            this.isAuthenticated = x;
            this.authenticationChanged();
        });

      this.secondSubscription = timer(0, 1000).subscribe(x => this.secondTick());
      this.minuteSubscription = timer(0, 60000).subscribe(x => this.minuteTick());
    }

    protected GotoState(newState: string) {
        this.currentState = newState;
    }

    protected authenticationChanged() { }

    protected translate(key: string, defaultValue: string = null, data: any = null): string {
        return this.Translate.translateNow(key, defaultValue, data);
  }

  private authenticationSubscription: Subscription;
  private secondSubscription: Subscription;
  private minuteSubscription: Subscription;
  protected secondTick() { }
  protected minuteTick() { }

  protected _isDestroyed: boolean = false;
  ngOnDestroy() {
    if (this.authenticationSubscription) this.authenticationSubscription.unsubscribe();
    if (this.secondSubscription) this.secondSubscription.unsubscribe();
    if (this.minuteSubscription) this.minuteSubscription.unsubscribe();

    this._isDestroyed = true;
  }
}
