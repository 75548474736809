import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageBase, ReturnResultCode } from 't4core';
import { LayoutService } from 'app-components';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';

@Component({
  selector: 'app-stop-rentalstart-rental',
  templateUrl: './stop-rental.html',
})
export class StopRentalComponent extends PageBase implements OnInit {

  public loading: boolean = true;
  public errorCode: string = "";

  constructor(private navigationService: NavigationService, private rentalService: ActiveRentalService, el: ElementRef, private route: ActivatedRoute, private layoutService: LayoutService) {
    super(el);

    // Validate state
    //if (!this.rentalService.booking || this.rentalService.booking.Id == 0) {
      // Display error and abort
    //}

  }

  async ngOnInit() {
    var result = await this.rentalService.return();
    let booking = await this.rentalService.getBooking();
    // Successful?
    if (result == ReturnResultCode.Success) {
      this.navigationService.executeCommand("Returned", { rentalId: booking.Id });
    }

    // Payment failed
    else if (result == ReturnResultCode.PaymentActionRequired || result == ReturnResultCode.PaymentFailed) {
      this.navigationService.executeCommand("PaymentFailed", { rentalId: booking.Id });
    }

    // Something else is wrong. Display error and abort
    else {
      this.errorCode = result.toString();
      this.loading = false;

      if ((this.errorCode == "1" || this.errorCode == "10") && booking.Status == 200) {
        this.navigationService.executeCommand("Returned", { rentalId: booking.Id });
      }

    }
    this.loading = false;
  }

  public goTo(command: string) {
    this.navigationService.executeCommand(command);
  }


}
