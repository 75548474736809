import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 't4-PinDialog',
    templateUrl: 'PinDialog.html',
})
export class PinDialog {
    public retry: boolean;
    public pin: string = "";
    @ViewChild("txtPin", {static: false}) public txtPin: any;

    constructor(public dialogRef: MatDialogRef<PinDialog >, @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.retry) {
            this.retry = true;
        }
    }

    public numberClicked(x: number) {
        this.pin += x;
        this.txtPin.nativeElement.focus()
    }

    public close(pin: string) {
        if(pin) this.dialogRef.close(pin);
    }
}
