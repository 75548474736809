<app-view-title [showHeader]="false" [isPrimaryColor]="true"></app-view-title>
<div class="view-content-full centered-view">
  <div class="row mb-3">
    <div class="col-12">
      <ng-container *ngIf="loading">
        <app-loading-screen title="{{ 'Intellitrailer.Rental.Start.StartingRental' | translate : 'Starting rental' }}" description="{{ 'Intellitrailer.Rental.Start.StartingRental2' | translate : 'This will just take a moment' }}"></app-loading-screen>
      </ng-container>
      <ng-container *ngIf="!loading">
        <h2 style="margin-bottom:20px;">{{ 'Intellitrailer.Rental.Start.Failed' | translate : 'Could not start rental' }}</h2>
        <app-card>
          <p>{{ 'Intellitrailer.Rental.Start.msgErrorCode' | translate : 'Error code' }}:  <b>{{ errorCode }}</b></p>

          <img style="height:100px; margin:10px 5px 20px 5px;" src="/Images/ErrorImg.png" />
          <!-- Wrong Stauts -->
          <ng-container *ngIf="errorCode == '10' || errorCode == '1'">
            <p>{{ 'Intellitrailer.Rental.Start.Error10Message1' | translate : 'The booking cannot be started. It may have already been started, or there might be an issue with the booking details.' }}</p>
            <br />
            <p>{{ 'Intellitrailer.Rental.Start.Error10Message2' | translate : 'Please check and try again.' }}</p>
            <button *ngIf="!loading" mat-raised-button (click)="goTo('MyBookings')">
              {{ 'Intellitrailer.Rental.Start.btnActionError10' | translate : 'Go back' }}
              <mat-icon>undo</mat-icon>
            </button>
          </ng-container>
          <!-- No rental object found -->
          <ng-container *ngIf="errorCode == '9'">
            <p>{{ 'Intellitrailer.Rental.Start.Error9Message1' | translate : 'The booking cannot be started. A trailer is not assigned to this booking, or there might be an issue with the booking details.' }}</p>
            <br />
            <p>{{ 'Intellitrailer.Rental.Start.Error9Message2' | translate : 'Please check and try again or contact support for assistance.' }}</p>
            <button *ngIf="!loading" mat-raised-button (click)="goTo('MyBookings')">
              {{ 'Intellitrailer.Rental.Start.btnActionError9' | translate : 'Go back' }}
              <mat-icon>undo</mat-icon>
            </button>
          </ng-container>

          <!-- No rental found -->
          <ng-container *ngIf="errorCode == '8'">
            <p>{{ 'Intellitrailer.Rental.Start.Error8Message1' | translate : 'The booking cannot be started. We couldn’t find the booking, or there might be an issue with the booking details.' }}</p>
            <br />
            <p>{{ 'Intellitrailer.Rental.Start.Error8Message2' | translate : 'Please check and try again.' }}</p>
            <button *ngIf="!loading" mat-raised-button (click)="goTo('MyBookings')">
              {{ 'Intellitrailer.Rental.Start.btnActionError8' | translate : 'Go back' }}
              <mat-icon>undo</mat-icon>
            </button>
          </ng-container>

        </app-card>

      </ng-container>
    </div>
  </div>
</div>
