import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ClientSettings } from '../models/contracts/Turbo.Data.Contracts';
import { SettingsService } from './settings.service';
import { InsightsService } from './insights.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private _preferredLanguage: String = null;
  private _currentLanguage: string = "en";
  private _cache: any = {};

  private _loadedPackageNames: Array<string> = [];

  private _usageStatistics: any = {};

  constructor(private http: HttpClient, private settings: SettingsService, private dateAdapter: DateAdapter<Date>, private insights:InsightsService) {
    this.languageChanged = new EventEmitter();
    this.settings.settingsChanged.subscribe((x: ClientSettings) => {
      this.changeLanguage(x.Language);
      this.changeLocale(x.Language);
      this._usageStatistics = {};
    });

    setTimeout(() => this.storeUsage(), 60000);
  }

  private _storeExecutionCount = 0;
  private storeUsage() {
    var data = [];
    for (var prop in this._usageStatistics) {
      if (this._usageStatistics[prop] < 1) {
        data.push(prop);
        this._usageStatistics[prop]++;
      }
    }
    if (data && data.length > 0) {
      this.post("/Settings/TrackTranslations", data, { language: this._currentLanguage })
    }

    this._storeExecutionCount++;
    var timeout = Math.min(60000 * Math.pow(1.2, this._storeExecutionCount), 300000);
    setTimeout(() => this.storeUsage(), timeout);
  }

  public translate(key: string, defaultValue: string | null = null, data: any[] | null = null): Promise<string> {
    if (key == null) return new Promise((resolve, reject) => {
      resolve("");
    });

    // Log usage
    if (!this._usageStatistics[key]) this._usageStatistics[key] = 0;

    var resourceKeyName = key;
    resourceKeyName = resourceKeyName.replace(/\./g, '_');
    if (this._packagePromise) // Is package loading?
    {
      return this._packagePromise.then(() => {
        // Was the key loaded?
        if (this._cache[resourceKeyName])
          return this.format(this._cache[resourceKeyName] as string, data); // Return it
        else
          return this.translate(key, defaultValue, data); // Rerun the translate method
      });
    }
    else if (this._cache[resourceKeyName] != undefined) { // Get item
      if (this._cache[resourceKeyName] instanceof Promise) return (this._cache[resourceKeyName] as Promise<string>).then(x => this.format(x, data));

      return new Promise((resolve, reject) => {
        resolve(this.format(this._cache[resourceKeyName] as string, data));
      });
    }
    else {
      this._cache[resourceKeyName] = this.getSpecific(resourceKeyName, defaultValue);
      return (this._cache[resourceKeyName] as Promise<string>).then(x => this.format(x, data));
    }
  }

  public translateNow(key: string, defaultValue: string | null = null, data: any[] | null = null): string {
    if (key == null) return "";

    // Log usage
    if (!this._usageStatistics[key]) this._usageStatistics[key] = 0;

    var resourceKeyName = key.replace(/\./g, '_');

    var val = this._cache[resourceKeyName];
    if (val) return this.format(val, data);

    this.getSpecific(key, defaultValue);

    return "[" + key + "]";
  }

  private getSpecific(key: string, defaultValue: string | null = null): Promise<string> {
    this._cache[key] = key;

        var p = this.get<string>("Settings/GetTranslation", 
            {
                key: key,
              language: this._preferredLanguage ?? this._currentLanguage,
                defaultValue: defaultValue
             }
        )

    p.then(x => {
      this._cache[key] = x;
    });

    return p;
  }

    public async setTranslation(key: string, newVal: string, lang: string): Promise<string> {
        var ret = null;
        var success = await this.get<boolean>("Settings/SetTranslation", { key: key, newValue: newVal, language: lang });
        if (success) {
            this._cache[key] = newVal;
            this.languageChanged.emit();
            return newVal;
        }

    return ret;
  }

  private _packagePromise: Promise<any>;
  public load(): Promise<boolean> {
    if (!this._packagePromise) {
      if (this._loadedPackageNames.indexOf(this.settings.appName) < 0) this._loadedPackageNames.push(this.settings.appName);

      this._packagePromise = this.get<any>("Settings/GetAppTranslations",
        {
          language: this._preferredLanguage ?? this._currentLanguage,
        }
      )
        .then(x => {
          for (var prop in x)
            this._cache[prop] = x[prop];
        });
    }

    return this._packagePromise.then<boolean>(x => {
      this._packagePromise = null;
      return true;
    });
  }

  public changeLanguage(language: string): Promise<boolean> {
    this._cache = {};
    var promises: Array<Promise<boolean>> = [];

    this.insights.setProperty("Language", (this._preferredLanguage ?? language).toString());
    this._currentLanguage = language;
    if(this._loadedPackageNames.length > 0) promises.push(this.load());

    return Promise.all(promises).then<boolean>(x => {
      this.languageChanged.emit();
      return true;
    });
  }

  public setPreferedLanguage(language: string) {
    this._preferredLanguage = language;
    this.insights.setProperty("Language", (this._preferredLanguage ?? language).toString());
  }

  public changeLocale(language: string) {
    //Set date locale format
    this.dateAdapter.setLocale(language);
    console.log("Date locale changed");
  }

  // Helpers
  private format(str: string, data: any[] | null): string {
    if (data == null || str == null) return str;

    return str.replace(/{(\d+)}/g, function (match, number) {
      return typeof data[number] != 'undefined' ? data[number] : match;
    });
  };

  // Events
  public languageChanged: EventEmitter<boolean>;

  async get<T>(path: string, params?: any): Promise<T> {
    var headers = this.createRequestOptions();

    var realPath = path;
    if (params) {
      var p = new URLSearchParams();
      for (var x in params) {
        var k = x;
        var val = params[x];
        p.append(k, val == null || val == undefined ? '' : val);
      }
      realPath += '?' + p.toString();
    }
   
    try {
      var ret = await this.http.get<T>(this.absoluteUrl(realPath), { observe: 'body', headers: headers }).toPromise();

        return ret as T;
    } catch (ex) {
      console.log(ex);
        return null;
      }
  }

  async post<T>(path: string, payload: any, params?: any): Promise<T> {
    var headers = this.createRequestOptions();

    var realPath = path;
    if (params) {
      var p = new URLSearchParams();
      for (var x in params) {
        var k = x;
        var val = params[x];
        p.append(k, val == null || val == undefined ? '' : val);
      }
      realPath += '?' + p.toString();
    }

    try {
      var ret = await this.http.post<T>(this.absoluteUrl(realPath), payload, { observe: 'body', headers: headers }).toPromise();

      return ret as T;
    } catch (ex) {
      console.log(ex);
      return null;
    }
  }

  private absoluteUrl(rel: string) {
    var ret: string = this.settings.getApiBase();

    if (!ret.endsWith("/") && !rel.startsWith("/")) ret += "/" + rel;
    else if (ret.endsWith("/") && rel.startsWith("/")) ret += rel.substr(1);
    else ret += rel;

    return ret;
  }

  private createRequestOptions(): HttpHeaders {
    var token = this.settings.getToken().access_token;

    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'AppName': this.settings.appName,
      'Authorization': 'Bearer ' + token,
      'CustomerUID': this.settings.customerUserId ? this.settings.customerUserId : ''
    });
  }
}
