import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 't4-promptDialog',
    templateUrl: 'promptDialog.html',
})
export class PromptDialog implements OnInit {
    public response: string = "";
    public questionKey: string = "";
    public questionDefault: string = "";
  public questionData: any[] = [];
  public headlineKey: string = "";
    public headlineDefault: string = "";
  public headlineData: any[] = [];
    
    constructor(public dialogRef: MatDialogRef<PromptDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.headlineKey) this.headlineKey = data.headlineKey;
        if (data.headlineDefault) this.headlineDefault = data.headlineDefault;
        if (data.headlineData) this.headlineData = data.headlineData;
        if (data.questionKey) this.questionKey = data.questionKey;
        if (data.questionDefault) this.questionDefault = data.questionDefault;
      if (data.questionData) this.questionData = data.questionData;
      if (data.defaultResponse) this.response = data.defaultResponse;
    }

    ngOnInit(): void {
    }
}
