import { Injectable } from '@angular/core';
import { LocalStorage } from '../LocalStorage';


@Injectable({
  providedIn: 'root',
})
export class CacheService {
  public context: string = "";

    constructor( private localStorage: LocalStorage) {
    }

    public get(key: string): CacheItem {
        var item: CacheItem = this.localStorage.getObject(this.context + "Cache_" + key);

        if (item) {
            if (item.expires > new Date()) {
                return item;
            } else {
                this.del(key);
            }
        }

        return null;
    }

  public put(key: string, data: any, expires: Date) {
      if (expires < new Date()) return;

    var item: CacheItem = this.localStorage.getObject(this.context + "Cache_" + key);
        if (!item) item = new CacheItem();

        item.key = key;
        item.timestamp = new Date();
        item.data = data;
        item.expires = expires;

    this.localStorage.setObject(this.context + "Cache_" + key, item);
  }

  public storeIndefinately(key: string, data: any) {
    this.put(key, data, new Date(new Date().getTime() + (100 * 365 * 24 * 60 * 60 * 1000))); // Store for 100 years
  }

    public del(key: string) {
      this.localStorage.remove(this.context + "Cache_" + key);
    }
}

class CacheItem {
  public key: string;
  public data: any;
  public timestamp: Date;
  public expires: Date;
}
