import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 't4-numericKeyboard',
    templateUrl: './numericKeyboard.html',
    styleUrls: ['./numericKeyboard.css'],
})
export class NumericKeyboard {
    @Output() public number = new EventEmitter<number>();

    public click(num: number) {
        this.number.emit(num);
    }
    
}
