import { Component, Input } from '@angular/core';

@Component({
    selector: 't4-container',
    templateUrl: './t4Container.html',
    styleUrls: ['./t4Container.css'],
})
export class T4Container {
    @Input() errorMessage: string = "";
    @Input() title: string = "";
    @Input() isInvalid: boolean = false;
    @Input() isInline: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() loadingMessage: string = "";
    //Loading message

    @Input() height: string = "";
    public get overflow(): string {
        if (!this.height) {
            return "hidden";
        } else {
            return "auto";
        }
    }

    public items = new Array<any>();
}
