import { Injectable } from "@angular/core";
import * as signalR from "@aspnet/signalr";
import { Subject } from "rxjs";
import { TurboHubEvent } from '../models/contracts/Turbo.Data.Contracts';
import { SettingsService, T4Environment } from './settings.service';

    
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private connection: signalR.HubConnection;

  public onEvent: Subject<TurboHubEvent> = new Subject<TurboHubEvent>();

  constructor(private settings: SettingsService) {
    settings.authenticationChanged.subscribe(x => {
      if (this.connection) this.connection.stop();

      if (settings.isAuthenticated && this.settings.Environment == T4Environment.Production) {
        this.init();
      }
    })
  }

  public init() {
    if (!this.settings.isAuthenticated || this.settings.Environment != T4Environment.Production) return;

    var self = this;
    var uri =  this.settings.getApiBase();
    if (!uri.endsWith("/")) uri += "/";
    uri += "hubs/t4events";

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(uri, {
        accessTokenFactory: () => this.settings.getToken().access_token
      })
      .build();

    this.connection.onclose(function () {
      setTimeout(() => {
        self.connect();
      }, 1000); // Restart connection after 5 seconds.
    });

    // Register event hooks
    this.connection.on("entityUpdated", (data: any) => {
      var event: TurboHubEvent = {
        Type: data.type,
        ClientId: data.clientId,
        ContextId: data.contextId,
        Entity: data.entity,
        EntityId: data.entityId,
        Timestamp: data.timeStamp
      };
      this.onEvent.next(event);
    });

    self.connect();
  }

  private connect() {
    var self = this;

    this.connection.start().catch(() => {
      setTimeout(() => {
        self.connect();
      }, 5000); // Retry connection after 60 seconds.
    });
  }
}

