import { ComponentBase } from './component.base';

export class DialogBase extends ComponentBase {
    public loadingMessageKey: string;
    public loadingMessageDefault: string;

    private tokens: any[] = [];

    constructor(name:string) {
        super();

        this.Insights.logPageView("/Dialogs/" + name); 
    }

    protected beginLoad(messageKey: string, messageDefault: string, cancelCallback: Function | null): string {
        // Generate guid
        var token = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });

        this.tokens.push({ token: token, messageKey: messageKey, messageDefault: messageDefault });
        this.loading.dialog = true;
        this.loadingMessageKey = messageKey;
        this.loadingMessageDefault = messageDefault;

        return token;
    }
    protected endLoad(token: string) {
        var x = this.tokens.find(x => x.token == token);
        if (x) this.tokens.splice(this.tokens.indexOf(x), 1);

        if (this.tokens.length > 0) {
            this.loadingMessageKey = this.tokens[this.tokens.length - 1].messageKey;
            this.loadingMessageDefault = this.tokens[this.tokens.length - 1].messageDefault;
        } else {
            this.loadingMessageKey = null;
            this.loadingMessageDefault = null;
        }

        this.loading.dialog = this.tokens.length > 0;
    }
}
