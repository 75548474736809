import { ElementRef, Injectable, Renderer2, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { MessageButton, MessageData, T4MessageBox } from '../dialogs/t4MessageBox/t4messagebox';
import { SettingsService } from './settings.service';
import { PromptDialog } from '../dialogs/promptDialog/promptDialog';
import { ComponentType } from '@angular/cdk/portal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Type } from '@angular/compiler';
import { TranslationService } from './translation.service';
import { PinDialog } from '../dialogs/PinDialog/PinDialog';



export class LoaderItem {
    public token: any;
    public messageTranslationKey: string;
    public translationDefault: string;

    public cancelCallback: Function | null;

    public startTime: Date;
}

export interface T4Dialog {
  key: string;
  dialog: ComponentType<unknown>,
};

@Injectable()
export class UIServiceSettings {
  public dialogs?: T4Dialog[] = [];
};

@Injectable({
  providedIn: 'root',
})
export class UIService {
    public loaderQueue: LoaderItem[] = [];
    public loading: LoaderItem | null = null;
  public allowCancel: boolean = false;

  private uiSettings: UIServiceSettings = { dialogs: [] };

  public applySettings(settings: UIServiceSettings) {
    if (!settings) return;

    settings.dialogs.map(x => this.uiSettings.dialogs.push(x));
  }

  public async openDialog<T>(key: string, params: any) : Promise<T> {
    var x = this.uiSettings.dialogs.find(y => y.key == key);
    if (x) return await this.dialog.open(x.dialog, params).afterClosed().toPromise();
    
    return null;
  }

  public async showPinDialog(pin: string) : Promise<string> {
    return await this.dialog.open(PinDialog, { data: { retry: pin } }).afterClosed().toPromise();
  }

  constructor(private dialog: MatDialog, private settings: SettingsService, private _snackBar: MatSnackBar, private translate:TranslationService) { }

    /// Loader
    public beginLoading(msgTranslationKey: string, defaultValue: string, cancelCallback: Function | null): string {
        // Generate guid
        var token = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });

        var item = new LoaderItem();
        item.token = token;
        item.messageTranslationKey = msgTranslationKey;
        item.translationDefault = defaultValue;
        item.cancelCallback = cancelCallback;
        item.startTime = new Date();

        this.loaderQueue.push(item);
        this.loading = item;

        setTimeout(() => { this.updateCancelState(); }, 5000);

        return token;
    }

    public cancel() {
        var cancelToken = this.beginLoading("Turbo.Global.msgCancelingLoader", "Canceling", null);

        for (var i = this.loaderQueue.length - 2; i >= 0; i--) {
            var item = this.loaderQueue[i];
            if (item.cancelCallback) item.cancelCallback();

            this.loaderQueue.splice(i, 1);
        }

        this.loaderCompleted(cancelToken);
    }

    public loaderCompleted(token: string) : boolean {
        for (var i = 0; i < this.loaderQueue.length; i++) {
            if (this.loaderQueue[i].token == token) {
                this.loaderQueue.splice(i, 1);

                if (this.loading && this.loading.token == token) {
                    this.loading = (this.loaderQueue.length > 0) ? this.loaderQueue[this.loaderQueue.length - 1] : null;
                }

                return true;
            }
        }

        return false;
    }

    private updateCancelState() {
        if (this.loaderQueue.length > 0) {
            if (new Date(this.loaderQueue[this.loaderQueue.length - 1].startTime.getTime() + 1000 * 5) <= new Date()) {
                this.allowCancel = true;
                setTimeout(() => { this.updateCancelState(); }, 5000);
                return;
            }
        }

        this.allowCancel = false;
    }
    /// Loader

    /// Message box
  public alertBox(data: MessageData) {
    if (!data.buttons) {
      data.buttons = [ MessageButton.Ok()];
    }

    let dialog = this.dialog.open(T4MessageBox, { data: data });
    return dialog.afterClosed().toPromise();
  }
    public alert(headlineKey: string, headlineDefault: string, messageKey: string, messageDefault: string, headlineData: any[] | null = null, messageData: any[] | null = null): Promise<any> {
        var data: MessageData = {
            headline: headlineKey,
            headlineDefault: headlineDefault,
            headlineData: headlineData,
            message: messageKey,
            messageDefault: messageDefault,
            messageData: messageData
        };

      return this.alertBox(data);
    }

    public confirm(headlineKey: string, headlineDefault: string, messageKey: string, messageDefault: string, headlineData: any[] | null = null, messageData: any[] | null = null): Promise<boolean> {
      var data: MessageData = {
        headline: headlineKey,
        headlineDefault: headlineDefault,
        headlineData: headlineData,
        message: messageKey,
        messageDefault: messageDefault,
        messageData: messageData,
        buttons: [ MessageButton.Yes(), MessageButton.No() ]
      };

        return this.alertBox(data).then(x => {
            return (x == "Yes");
        });
    }

  public async prompt(headlineKey: string = null, headlineDefault: string = null, questionKey: string = null, questionDefault: string = "", headlineData: string = null, questionData: string = null, defaultResponse: string = null): Promise<string> {
        var data = {
            headlineKey: headlineKey,
            headlineDefault: headlineDefault,
            headlineData: headlineData,
            questionKey: questionKey,
            questionDefault: questionDefault,
          questionData: questionData,
          defaultResponse: defaultResponse
        };

        var dlg = this.dialog.open(PromptDialog, {
            data
        });

        var ret = await dlg.afterClosed().toPromise();

        return ret ? ret : null;
    }

    /// Message box

    /// Snack Bar
  public snackBar(message: string, action: string = "", duration: number = 2000) {
    this._snackBar.open(message, action, {
      duration: duration,
    });
  }

  public snackBarLocal(key: string, defaultValue:string, action: string = "", duration: number = 2000) {
    this._snackBar.open(this.translate.translateNow(key, defaultValue), action, {
      duration: duration,
    });
  }

    /// Snack Bar

    /// Floaters
    private _floatContainer: ElementRef = null;
    private renderer: Renderer2;
    public setFloatLayer(viewContainerRef: ElementRef, renderer: Renderer2) {
        this._floatContainer = viewContainerRef
        this.renderer = renderer; 
    }

    public float<T>(component: any, left:number = null, top:number = null, right:number = null, bottom:number = null, gapWidth:number = null, gapHeight: number = null) {
        this.renderer.appendChild(this._floatContainer.nativeElement, component); 
        this.renderer.setStyle(component, "position", "absolute");
        this.renderer.setStyle(component, "display", "block");

        if (left < 0) left = 0;
        if (left + component.clientWidth > window.document.body.clientWidth - 10) {
            if (gapWidth) {
                right = left - gapWidth;
                left = null;
            } else {
                left = window.document.body.clientWidth - component.clientWidth - 10;
            }            
        }

        if (top < 0) top = 0;
        if (top + component.clientHeight > window.document.body.clientHeight - 10) {
            if (gapHeight) {
                bottom = top - gapHeight;
                top = null;
            } else {
                top = window.document.body.clientHeight - component.clientHeight - 10;
            }   
        }

        if (top != null) this.renderer.setStyle(component, "top", top + "px");
        if (left != null) this.renderer.setStyle(component, "left", left + "px");
        if (right != null) this.renderer.setStyle(component, "left", right - component.clientWidth + "px");
        if (bottom != null) this.renderer.setStyle(component, "top", bottom - component.clientHeight + "px");
    }

    public drop<T>(component: ElementRef) {
        this.renderer.setStyle(component, "display", "none");
        this.renderer.removeChild(this._floatContainer.nativeElement, component);        
    }
    /// Floaters
}
