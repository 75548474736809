<h1>{{ 'turbo.core.PinDialog.txtHeadline' | translate : 'Confirm with your pin code' }}</h1>
<div>
    <div>
        <mat-form-field style="width: 100%; min-width: 200px;">
            <input #txtPin autocomplete="new-password" type="password" [(ngModel)]="pin" matInput placeholder="{{ 'turbo.core.PinDialog.tipPin' | translate : 'Enter pin code...' }}" [required] (keyup.enter)="close(txtPin.value)" />
        </mat-form-field>
        <div style="text-align: center">
            <t4-numericKeyboard (number)="numberClicked($event)"></t4-numericKeyboard>
        </div>
    </div>
    <div *ngIf="retry">{{ 'turbo.core.PinDialog.txtRetry' | translate : 'Invalid pin code' }}</div>
    <div style="text-align: center;">
        <button class="btn btn-default" style="margin: 2px;" color="primary"  [mat-dialog-close]="txtPin.value" [disabled]="!txtPin.value">{{ 'turbo.core.PinDialog.cmdSend' | translate : 'Confirm' }}</button>
        <button class="btn btn-default" style="margin: 2px;" mat-dialog-close>{{ 'turbo.core.messagebox.cmdCancel' | translate : 'Cancel' }}</button>
    </div>
</div>