<app-view-title [showHeader]="false" [isPrimaryColor]="true"></app-view-title>
<div class="view-content-full centered-view">
  <div class="row mb-3">
    <div class="col-12">
      <ng-container *ngIf="loading">
        <app-loading-screen title="{{ 'Intellitrailer.Rental.Stop.EndingRental' | translate : 'Ending rental' }}" description="{{ 'Intellitrailer.Rental.Start.EndingRental2' | translate : 'This will just take a moment' }}"></app-loading-screen>
      </ng-container>
      <ng-container *ngIf="!loading">
        <h2 style="margin-bottom:20px;">{{ 'Intellitrailer.Rental.Stop.Failed' | translate : 'Could not return trailer' }}</h2>
        <app-card>
          <p>{{ 'Intellitrailer.Rental.Stop.msgErrorCode' | translate : 'ErrorCode' }}:  <b>{{ errorCode }}</b></p>

          <img style="height:100px; margin:10px 5px 20px 5px;" src="/Images/ErrorImg.png" />
          <!-- Wrong Status -->
          <ng-container *ngIf="errorCode == '10' || errorCode == '1'">
            <p>{{ 'Intellitrailer.Rental.Stop.Error10Message1' | translate : 'The return cannot be completed. The booking may already be returned, or there might be an issue with the booking details.' }}</p>
            <br />
            <p>{{ 'Intellitrailer.Rental.Stop.Error10Message2' | translate : 'Please check and try again.' }}</p>
            <button *ngIf="!loading" mat-raised-button (click)="goTo('MyBookings')">
              {{ 'Intellitrailer.Rental.Stop.btnActionError10' | translate : 'Go back' }}
              <mat-icon>undo</mat-icon>
            </button>
          </ng-container>

          <!-- No Rental Found -->
          <ng-container *ngIf="errorCode == '8'">
            <p>{{ 'Intellitrailer.Rental.Stop.Error8Message1' | translate : 'The return cannot be completed. We couldn’t find the booking, or there might be an issue with the booking details.' }}</p>
            <br />
            <p>{{ 'Intellitrailer.Rental.Stop.Error8Message2' | translate : 'Please check and try again.' }}</p>
            <button *ngIf="!loading" mat-raised-button (click)="goTo('MyBookings')">
              {{ 'Intellitrailer.Rental.Stop.btnActionError8' | translate : 'Go back' }}
              <mat-icon>undo</mat-icon>
            </button>

          </ng-container>

        </app-card>
      </ng-container>
    </div>
  </div>
</div>
