<div style="display: inline-block">
    <div>
        <button mat-button (click)="click(1)">1</button>
        <button mat-button (click)="click(2)">2</button>
        <button mat-button (click)="click(3)">3</button>
    </div>
    <div>
        <button mat-button (click)="click(4)">4</button>
        <button mat-button (click)="click(5)">5</button>
        <button mat-button (click)="click(6)">6</button>
    </div>
    <div>
        <button mat-button (click)="click(7)">7</button>
        <button mat-button (click)="click(8)">8</button>
        <button mat-button (click)="click(9)">9</button>
    </div>
    <div style="text-align: center">
        <button mat-button (click)="click(0)">0</button>
    </div>
</div>