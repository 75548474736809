import * as moment from 'moment';
	export class ObjectCheckupInfo {
		Id: number;
		LocationCheckupId: number;
		RentalObjectId: number;
		Status: ObjectCheckupStatus | undefined;
		Actions: CheckupActionInfo[] = [];
		Spareparts: CheckupSparePartInfo[] = [];
	}
	export class RentalInfo {
		Id: number | undefined;
		BookingCode: string;
		PickupTime: moment.Moment;
		ReturnTime: moment.Moment;
		PrimaryObjectTypeId: number;
		PickupLocationId: number;
		ReturnLocationId: number | undefined;
		PrimaryObjectTypeCategoryId: number | undefined;
		IsWebRental: boolean;
		UseInsurance: boolean;
		Status: RentalStatus | undefined;
		Note: string;
		Customer: CustomerInfo | undefined;
		PickupLocation: LocationInfo | undefined;
		ReturnLocation: LocationInfo | undefined;
		PrimaryType: ObjectTypeInfo | undefined;
		RentalObject: RentalObjectInfo | undefined;
		SmartLock: SmartLockInfo | undefined;
		DiscountId: string;
		QueueIndex: number | undefined;
		WeekQueueIndex: number | undefined;
		ObjectId: number | undefined;
		QueuedAt: moment.Moment | undefined;
		CreatedAt: moment.Moment;
		ReturnedAt: moment.Moment | undefined;
		IsOneWay: boolean;
		ExtraReceiptSkipped: boolean;
		ExtraReceiptExists: boolean;
		Price: PriceModel | undefined;
		BindsAt: moment.Moment | undefined;
		ActivatedAt: moment.Moment | undefined;
		OutlandCode: string;
		ForeignCountry: string;
		AddedProductIds: number[] = [];
		AddedProducts: string;
		BoundAt: moment.Moment | undefined;
		AlternativesSentAt: moment.Moment | undefined;
		AlternativesChangedAt: moment.Moment | undefined;
		CanceledAt: moment.Moment | undefined;
		Flagged: boolean;
		SignedAt: moment.Moment | undefined;
		DigitalSignature: string;
		Insurance: number;
		IsOnlinePayment: boolean;
		HasTicket: boolean;
		LocalPickupTime: moment.Moment;
		LocalReturnTime: moment.Moment;
		LocalReturnedAt: moment.Moment | undefined;
		BookingLat: number | undefined;
		BookingLng: number | undefined;
		RowVersion: string;
	}
	export class BookingValidation {
		PickupErrorCode: BookingErrorCode | undefined;
		ReturnErrorCode: BookingErrorCode | undefined;
		PickupOpeningHours: OpenHours | undefined;
		ReturnOpeningHours: OpenHours | undefined;
		ValidPeriods: OpenHours[] = [];
		ErrorCode: BookingErrorCode | undefined;
		AggregatedResult: BookingErrorCode | undefined;
		BookingId: number | undefined;
		RequirePayment: boolean;
		PaymentURI: string;
		BookingInfoUri: string;
		CustomerBlockedReason: string;
	}
	export class MessageFormat {
		MessageId: string;
		MessageType: string;
		EntityId: string;
		CreatedAt: moment.Moment;
		StartAt: moment.Moment;
		Content: string;
	}
	export class MessageQueueInfo {
		Id: string;
		MessageTypeId: string;
		ReceiverId: string;
		Content: string;
		CreatedAt: moment.Moment;
		StartAt: moment.Moment | undefined;
		RetryCount: number;
		LastRetryAt: moment.Moment | undefined;
		LastErrorMessage: string;
		CompletedAt: moment.Moment | undefined;
		IsCompleted: boolean;
		EntityId: string;
		LastErrorCode: number | undefined;
		NextRetryAt: moment.Moment | undefined;
		MessageType: MessageTypeInfo | undefined;
		Receiver: ReceiverInfo | undefined;
	}
	export class MessageTypeInfo {
		Id: string;
		Name: string;
		Description: string;
	}
	export class ReceiverInfo {
		Id: string;
		ContextId: string;
		ContentType: WebHookFormat | undefined;
		DeliveryType: WebHookDeliveryType | undefined;
		WebHookSettings: string;
		NotificationEmail: string;
		ReceiverMessageTypes: ReceiverMessageTypeInfo[] = [];
		MessageTypes: MessageTypeInfo[] = [];
		ReceiverMessages: MessageQueueInfo[] = [];
		RentalLocationName: string;
	}
	export class ReceiverMessageTypeInfo {
		Id: string;
		ReceiverId: string;
		MessageTypeId: string;
		FilterConditions: string;
		Receiver: ReceiverInfo | undefined;
		MessageType: MessageTypeInfo | undefined;
	}
	export class ActiveOrderModel {
		Id: number;
		RegNo: string;
		OnRentDate: moment.Moment | undefined;
		OffRentDate: moment.Moment | undefined;
		ManufacturingDate: moment.Moment | undefined;
		Alias: string;
		NetAmount: number | undefined;
		XALCustomerNumber: string;
		UpdatedDt: moment.Moment | undefined;
		DeletedDt: moment.Moment | undefined;
		AxId: string;
		InventTransId: string;
		OriginalValue: number | undefined;
		ArtNo: string;
		SerialNo: string;
		DLLContractId: string;
		ContractType: number | undefined;
		IsRepurchased: boolean;
		IsTerminated: boolean;
		AssetId: string;
		RentalId: string;
		SalesId: string;
		ProductionId: string;
		ConfirmedDeliveryDt: moment.Moment | undefined;
		DeliveredAt: moment.Moment | undefined;
		Status: ContractStatus | undefined;
		CreatedDt: moment.Moment | undefined;
		StatusString: string;
		OffRentActionExists: boolean | undefined;
		NumberOfExtensions: number;
		ExtendTo: moment.Moment | undefined;
		CustomerName: string;
		ForceRepurchase: boolean;
	}
	export class ConfirmOrderModel {
	}
	export class OrderCustomerModel {
		Id: number;
		Name: string;
		InvoiceName: string;
		AxCustomerNo: string;
		XalAccountNo: string;
		RentalLocationGroupId: number;
		LegalAddress: string;
		LegalZipCode: string;
		LegalCity: string;
		LegalAddressCustomerName: string;
		DeliveryAddress: string;
		DeliveryZipCode: string;
		DeliveryCity: string;
		DeliveryAddressCustomerName: string;
		InvoiceAddress: string;
		InvoiceZipCode: string;
		InvoiceCity: string;
		StationNo: string;
		Attention: string;
		PaymentMethod: string;
		BFIKNR: string;
		Phone: string;
		InvoiceAccount: string;
		OrgNo: string;
		Subsidiary: string;
		Country: string;
		Mail: string;
		IsEnabled: boolean;
		IsDeleted: boolean;
		IsDemo: boolean;
		CreatedAt: moment.Moment;
		UpdatedAt: moment.Moment;
		LegalOrgNo: string;
		PriceListId: number | undefined;
		TrailerList: string;
		CustomerNumber: string;
		Chain: string;
		SalesRepId: string;
		T4StationId: number | undefined;
		T4IsOneWay: boolean;
		UseT4: boolean;
		MobilePhone: string;
		ServicePartner: string;
		RepairPartner: string;
		ServicePartnerId: number;
		RepairPartnerId: number;
		IsBlocked: boolean;
		T4ContextId: string | undefined;
	}
	export class CustomerSettingsModel {
		T4: T4Settings | undefined;
		OneWay: OneWaySettings | undefined;
		Payment: PaymentSettings | undefined;
		Service: ServiceSettings | undefined;
	}
	export class T4Settings {
		LocationGroupId: number | undefined;
		ImplicitGroupId: number | undefined;
	}
	export class OneWaySettings {
		AllowOneWay: boolean;
		SoftLimit: number;
		HardLimit: number;
		Priority: number;
		OverrideLockStatus: boolean;
		OpenForPickup: boolean;
		OpenForReturn: boolean;
	}
	export class ServiceSettings {
		IsServicePartner: boolean;
		RequestEmail: string;
		ServicePartnerId: number | undefined;
		ServiceZone: number | undefined;
		RepairPartnerId: number | undefined;
	}
export class PaymentSettings {
  InvoiceEmail: string;
  SupplierAccount: string;
  PayoutInfo: string;
  OCR: string;
}
	export class RentalContractInfo {
		Id: number;
		Alias: string;
		OffRentActionExists: boolean | undefined;
		RegNo: string;
		Type: string;
		OnRentDate: moment.Moment;
		OffRentDate: moment.Moment;
		PricePerMonth: number;
		ArtNo: string;
		OriginalValue: number;
		RemainingValue: number;
		Terminate: boolean | undefined;
		Price: number | undefined;
		TerminationId: number | undefined;
		TerminationDate: moment.Moment | undefined;
		TerminationType: number | undefined;
		Expired: boolean;
		Customer: OrderCustomerModel | undefined;
		OwnerId: string;
		IsTerminationProcessed: boolean;
		Status: ContractStatus | undefined;
		SalesRepId: string;
		ResidualValue: number;
		ExtendedTo: moment.Moment | undefined;
		VehicleId: string | undefined;
		CustomerId: number;
	}
	export class CustomerTrailerExcelModel {
		CustomerName: string;
		RegNo: string;
		Alias: string;
		PricePerMonth: number | undefined;
		StartWeek: moment.Moment | undefined;
		TerminationDate: moment.Moment | undefined;
	}
	export class DeletedOrderSummary {
		Id: number;
		CustomerName: string;
		CustomerAddress: string;
		CustomerId: number | undefined;
		Status: TurboOrderStatus | undefined;
		StatusName: string;
		UpdatedDt: moment.Moment | undefined;
		ConfirmedDt: moment.Moment | undefined;
		TerminationDt: moment.Moment | undefined;
		TerminationType: number;
		OwnerId: string;
		Customer: OrderCustomerModel | undefined;
		NumberOfObjects: number;
		OrderValue: number;
		CreatedDt: moment.Moment | undefined;
		TotalPrice: number | undefined;
	}
	export class DLLOrderModelBase {
		input: DLLOrderModel | undefined;
	}
	export class DLLOrderModel {
		Username: string;
		Password: string;
		CustomerOfferID: string;
		SalesPersonEmail: string;
		Billing: string;
		InterestType: string;
		PeriodLength: number;
		Duration: number;
		ResidualClause: string;
		ExternalReference: string;
		SendCreditRequest: boolean;
		CalculateByRent: boolean;
		Products: DLLProduct[] = [];
		CustomerOrgNo: string;
		CustomerInfo: DLLCustomerInfo | undefined;
		InvoiceAddress: DLLInvoiceAddress | undefined;
		InstallationAddress: DLLInstallationAddress | undefined;
	}
	export class DLLProduct {
		Type: string;
		Name: string;
		Specification: string;
		Quantity: number;
		Price: number;
		Rent: number;
	}
	export class DLLInvoiceAddress {
		CoName: string;
		Address: string;
		City: string;
		ZipCode: string;
	}
	export class DLLInstallationAddress {
		Address: string;
		Location: string;
	}
	export class DLLCustomerInfo {
		Contact: string;
		PhoneNo: string;
		Email: string;
		Fax: string;
	}
	export class OrderCustomerInfo {
		Id: number;
		LegalOrgNo: string;
		RentalLocationGroupId: number;
		Longitude: number | undefined;
		Latitude: number | undefined;
		Distance: number | undefined;
		Phone: string;
		DeliveryZipCode: string;
		DeliveryCity: string;
		DeliveryAddressCustomerName: string;
		DeliveryAddress: string;
		InvoiceName: string;
		InvoiceZipCode: string;
		InvoiceCity: string;
		InvoiceAddressCustomerName: string;
		InvoiceAddress: string;
		Email: string;
		SalesRep: SalesRepInfo | undefined;
		SalesRepId: string;
		MobilePhone: string;
		ServicePartnerId: number | undefined;
	}
	export class OrderFilter {
		customerId: number | undefined;
		status: number | undefined;
	}
	export class RentalOrderSummary {
		Id: number;
		CustomerName: string;
		CustomerAddress: string;
		CustomerId: number | undefined;
		Status: TurboOrderStatus | undefined;
		StatusName: string;
		UpdatedDt: moment.Moment;
		ConfirmedDt: moment.Moment | undefined;
		DeliveryDt: moment.Moment | undefined;
		OwnerId: string;
		OrderType: number | undefined;
		Customer: OrderCustomerModel | undefined;
		NumberOfObjects: number;
		OrderValue: number;
	}
	export class UserDropDownModel {
		Id: string;
		UserName: string;
	}
	export class CancelOrderModel {
		Id: number;
		Status: number;
		UpdatedDt: moment.Moment | undefined;
		CanceledDt: moment.Moment | undefined;
	}
	export class SendToAxOrderModel {
		Id: number;
		Status: number;
		UpdatedDt: moment.Moment | undefined;
		SentToAxDt: moment.Moment | undefined;
		ConfirmedDt: moment.Moment | undefined;
	}
	export enum TerminationType {
		Sell = 1,
		Transport = 3,
		SwitchWithNew = 4,
		ChangeOwner = 5,
		ToBeDetermined = 6,
		OneWay = 7,
		Forget = 100,
	}
	export class TerminationOrderInfo {
		CustomerId: number | undefined;
		TerminationType: TerminationType | undefined;
		Id: number;
		CreatedAt: moment.Moment;
		UpdatedAt: moment.Moment | undefined;
		Insurance: boolean;
		Comment: string;
		InvoiceAddress: string;
		InvoiceZipCode: string;
		InvoiceCity: string;
		InvoiceName: string;
		DeliveryAddress: string;
		DeliveryZipCode: string;
		DeliveryCity: string;
		DeliveryName: string;
		Phone: string;
		Contact: string;
		TerminateAt: moment.Moment | undefined;
		InvoiceAt: moment.Moment | undefined;
		TransferOwnershipAt: moment.Moment | undefined;
		Rows: TerminationRowInfo[] = [];
		UpdatedBy: string;
		CreatedBy: string;
		Customer: OrderCustomerModel | undefined;
	}
	export class TerminationRowInfo {
		TrailerId: number;
		Trailer: RentalContractInfo | undefined;
		Price: number;
		LowMargin: boolean;
		IsTerminated: boolean;
		IsProcessed: boolean;
		IsTransfered: boolean;
		IsCancelled: boolean;
		CancelledAt: moment.Moment | undefined;
		CancelledBy: string;
		Id: number;
	}
	export class ViewPriceListModel {
		Id: number;
		Title: string;
		Type: string;
		Relation: string;
		Fees: string;
		Rows: ViewPriceListRowModel[] = [];
		DefaultLength: number | undefined;
		OptionalServiceFee: boolean;
	}
	export class ViewPriceListRowModel {
		Id: number;
		PriceListId: number;
		ArticleNo: string;
		Description: string;
		PricePerMonth: number | undefined;
		SalesPrice: number | undefined;
		ServiceFee: number | undefined;
		ConceptFee: number | undefined;
	}
	export class ViewOrderModel {
		Id: number;
		OrgNo: string;
		Status: number;
		CreatedDt: moment.Moment;
		UpdatedDt: moment.Moment | undefined;
		ConfirmedDt: moment.Moment | undefined;
		CustomerId: number | undefined;
		Rate: number | undefined;
		OwnerId: string;
		CreatedBy: string;
		DLLId: string;
		AxId: string;
		OrderType: number;
		PriceListId: number | undefined;
		StripingManual: string;
		StripingMisc: string;
		StripingMiscInfo: string;
		MiscInfo: string;
		Months: number;
		Phone: string;
		LegalAddressCustomerName: string;
		LegalAddress: string;
		LegalZipCode: string;
		LegalCity: string;
		LegalCountry: string;
		InvoiceName: string;
		InvoiceStationNo: string;
		InvoiceAddress: string;
		InvoiceZipCode: string;
		InvoiceCity: string;
		InvoiceAccount: string;
		PaymentMethod: string;
		BFIKNR: string;
		InvoiceMail: string;
		DeliveryAddressCustomerName: string;
		DeliverySubsidiary: string;
		DeliveryAddress: string;
		DeliveryZipCode: string;
		DeliveryCity: string;
		DeliveryContact: string;
		DeliveryPhone: string;
		ArticlePricesEditable: boolean;
		OrderRows: ViewOrderRowsModel[] = [];
		PriceList: ViewPriceListModel | undefined;
		Customer: OrderCustomerModel | undefined;
		RequestedStartDate: moment.Moment | undefined;
		RequestedDeliveryDate: moment.Moment | undefined;
		LegalOrgNo: string;
		AgreementDocumentExists: boolean;
		Duration: number;
		PaymentAtBeginningOfPeriod: boolean;
		DeliveryEmail: string;
		AddServiceFee: boolean;
	}
	export class ViewOrderRowsModel {
		Id: number;
		ArticleNo: string;
		Alias: string;
		Description: string;
		Amount: number;
		IsStandardArticle: boolean;
		SalesPrice: number | undefined;
		ConceptFee: number | undefined;
		ServiceFee: number | undefined;
		TotalCost: number;
	}
	export class VehicleInfo {
		Id: string;
		SerialNo: string;
		RegNo: string;
		ArtNo: string;
		ArtDescription: string;
		ManufacturingDate: moment.Moment | undefined;
		AssetId: string;
		IsDLL: boolean;
		IsRepurhased: boolean;
		IsSold: boolean;
		IsScrapped: boolean;
		PurchaseDate: moment.Moment | undefined;
		PurchaseId: string;
		SellDate: moment.Moment | undefined;
		SalesId: string;
		Contracts: RentalContractInfo[] = [];
		Comment: string;
		LastKnownPosition: number | undefined;
		OverrideItemId: string;
	}
	export class ActivityPeriodSummary {
		Bookings: DateValue[] = [];
		WebBookings: DateValue[] = [];
		StationBookings: DateValue[] = [];
		Pickups: DateValue[] = [];
		Returns: DateValue[] = [];
		Cancellations: DateValue[] = [];
		CustomerCancellations: DateValue[] = [];
		StationCancellations: DateValue[] = [];
		AutoCancellations: DateValue[] = [];
		PickupsPY: DateValue[] = [];
	}
	export class ChartConfig {
		Accumulate: boolean;
		ColorSettings: ColorSettings | undefined;
		Unit: string;
		YAxisId: string;
		SuggestedMin: number;
		SuggestedMax: number;
	}
	export class ColorSettings {
		UseSameBackground: boolean;
		BackgroundColor: string;
		BorderColor: string;
	}
	export class DateChartData {
		ChartSeries: DateValue[][] = [];
		Config: ChartConfig | undefined;
	}
	export class ChartData {
		Labels: string[] = [];
		ChartSeries: number[][] = [];
		Config: ChartConfig | undefined;
	}
	export class DateValue {
		x: string;
		y: number;
	}
	export class PivotData {
		GroupableFields: string[] = [];
		FilterableFields: string[] = [];
	}
	export class RentalKPI {
		Key: string;
		Value: number;
		RelatedValue: number;
		Delta: number;
	}
	export class ReportInfo {
		Id: string;
		ContextId: string;
		Category: string;
		FriendlyName: string;
		TranslateFriendlyName: boolean;
		IsSchedulable: boolean;
	}
	export class ReportRunInfo {
		Id: string;
		ContextId: string;
		From: moment.Moment;
		To: moment.Moment;
		RunTime: moment.Moment | undefined;
		Format: ReportFormat | undefined;
		Uri: string;
		OrderedAt: moment.Moment;
		ErrorMessage: string;
	}
	export class TimeChartSeries {
		Date: moment.Moment;
		Value: number;
		Config: string;
	}
	export class ChartSeries {
		PrimaryDataset: number;
		Label: string;
		Value: number;
		Config: string;
	}
	export enum LockCommandResult {
		Success = 0,
		Failed = 1,
		ClientNotConnected = 2,
		Exception = 3,
		NotAuthenticated = 4,
		Timeout = 5,
		NotSet = 6,
		ClientBusy = 7,
	}
	export class ContentTemplateInfo {
		Key: string;
		Name: string;
		Description: string;
		ModelProperties: String[] | undefined;
		Content: string;
		IsDefined: boolean;
	}
	export class Country {
		Name: string;
		Code: string;
		Lng: number | undefined;
		Lat: number | undefined;
	}
	export class GeneralSetting {
		DataType: string;
		BoolValue: boolean;
		StringValue: string;
	}
	export enum SettingResult {
		NotFound = 0,
		Found = 1,
	}
	export class ClientSettings {
		AllowSms: boolean;
		RentalLocationId: number | undefined;
		RentalLocationGroupId: number | undefined;
		TimeZoneId: string;
		Language: string;
		Country: string;
		Currency: string;
		IsLocal: boolean;
		IsOneWay: boolean;
		GoogleApiKey: string;
		CanEditPricelists: boolean;
		Version: TurboVersion | undefined;
		Objects: ObjectSettings | undefined;
		Bookings: BookingSettings | undefined;
		CustomerSettings: CustomerContextSettings | undefined;
		CustomerFormValidation: string;
		ContextId: string | undefined;
		GDPRTerms: string;
		DefaultLatitude: number;
		DefaultLongitude: number;
		MaxBookingsPerDay: number;
		MaximumConcurrentBookings: number;
		UsePinFromLocalGroup: boolean;
		ShowNearbyOptions: boolean;
	}
	export class BookingSettings {
		AllowChangeWhenDelayed: boolean;
		DefaultBookingLength: number;
		DigitalSignatureProvider: string;
		UseDigitalSignature: boolean;
		RequireDigitalSignature: boolean | undefined;
		EnableRentFreeWhenClosed: boolean | undefined;
		EarlyPickupInMinutes: number;
		SupportsPaymentRequests: boolean;
	}
	export class ObjectSettings {
		AutoDisableOnNewIssue: boolean;
	}
	export class RightsInfo {
		Id: string;
		Category: string;
		Description: string;
		Name: string;
		Value: RightsValue | undefined;
	}
	export enum RightsValue {
		False = 0,
		True = 1,
		NotSet = 2,
	}
	export class Role {
		Id: string;
		Name: string;
	}
	export class T4PricelistInfo {
		Id: number;
		Title: string;
		Type: ProductType | undefined;
		IsActive: boolean;
		ValidFrom: moment.Moment;
		ValidThrough: moment.Moment | undefined;
		OnlineBookingFee: number | undefined;
		OfflineBookingFee: number | undefined;
		InPlaceBookingFee: number | undefined;
		FreeRentalDays: number | undefined;
		FreeRentalHours: number | undefined;
		ExcessPerHour: number | undefined;
		ExcessPerDay: number | undefined;
		OneTimeExcessFee: number | undefined;
		IgnoreExcessRent: boolean;
		AcceptedOverdueInMinutes: number;
		Intervals: T4PriceListInterval[] = [];
		Prices: T4Price[] = [];
	}
	export class T4PriceListInterval {
		Id: number;
		PriceListId: number;
		Interval: number;
		Unit: number;
		IsBaseInterval: boolean;
	}
	export class T4Price {
		PriceListId: number;
		ObjectTypeId: number;
		IntervalId: number;
		Price: number;
		ObjectType: ObjectTypeInfo | undefined;
	}
	export class T4SettingsPage {
		Key: string;
		Sections: T4SettingsSection[] = [];
		SortOrder: number;
	}
	export class T4SettingsSection {
		Key: string;
		Condition: string;
		Settings: T4Setting[] = [];
		SortOrder: number;
	}
	export class T4Setting {
		Key: string;
		Name: string;
		Description: string;
		SortOrder: number;
		ValidValues: T4SettingValue[] = [];
		Control: ControlType | undefined;
		ReadOnly: boolean;
		Value: any;
		OriginalValue: any;
		IsDefined: boolean;
		EffectiveValue: any;
		FinalSetting: boolean;
	}
	export class T4SettingValue {
		Id: string;
		Name: string;
	}
	export class TrailerPointInfo {
		TrailerPointRadius: number;
		TrailerPointLatitude: number;
		TrailerPointLongitude: number;
	}
	export class WebHookSettings {
		AdminEmail: string;
		WebHooks: WebHook[] = [];
	}
	export class WebHook {
		Event: WebHookEvent | undefined;
		Uri: string;
		Username: string;
		Password: string;
	}
	export class AssetHistory {
		Position: string;
		PositionIsSG: boolean;
		StartTime: moment.Moment;
		EndTime: moment.Moment | undefined;
		CreatedBy: string;
		ClosedBy: string;
		Action: number;
	}
	export class AssetTransactionInfo {
		Id: string;
		AssetId: string;
		ContextId: string;
		StartDate: moment.Moment;
		EndDate: moment.Moment | undefined;
		InvoiceStartDate: moment.Moment | undefined;
		LastInvoiceDate: moment.Moment | undefined;
		InvoiceCount: number;
		InvoiceSum: number;
		SerialId: string;
		CreatedBy: string;
		ClosedBy: string;
		ActionName: string;
	}
	export class CheckupActionInfo {
		Id: number;
		ObjectCheckupId: number;
		DamageId: number;
		DamageType: DamageType | undefined;
		Comment: string;
		Checkup: ObjectCheckupInfo | undefined;
	}
	export class CheckupInfo {
		TrailerId: number;
		Actions: CheckupActionInfo[] = [];
		Status: ObjectCheckupStatus | undefined;
	}
	export class CheckupSparePartInfo {
		Id: number;
		ObjectCheckupId: number;
		ItemId: string;
		ItemCount: number;
	}
	export class DamageInfo {
		TrailerId: number;
		DamageId: number;
		DamageType: DamageType | undefined;
		Description: string;
		ImageUris: string[] = [];
		SavedAt: moment.Moment | undefined;
		RentalLocationName: string;
		RentalLocationGroupName: string;
		RegNo: string;
		ModelTitle: string;
		HandledCS: boolean;
		HandledSG: boolean;
		IsEnabled: boolean;
		IsDisabledByAdmin: boolean;
		Count: number;
	}
	export class InsuranceSetOffLine {
		Id: string;
		InsuranceId: number;
		Status: ServiceRequestStatus | undefined;
		ObjectId: number;
		OnRentDate: moment.Moment;
		OffRentDate: moment.Moment;
		ManufacturingDate: moment.Moment;
		ObjectName: string;
		LocationId: number;
		LocationName: string;
		IncidentDate: moment.Moment;
		Finance: RequestFinance | undefined;
		Repurchase: boolean;
		Country: string;
		InsuranceValue: number;
		IsRepurchased: boolean;
		ModelName: string;
		IsTaggedStolen: boolean;
	}
	export class InventoryStatus {
		ServiceGuyId: string;
		Firstname: string;
		Lastname: string;
		CompanyName: string;
		LocationId: number;
		LocationName: string;
		ServiceGuyType: string;
		LocationCount: number;
		OkItems: number;
		WarningItems: number;
		LowItems: number;
	}
	export class InventoryAssetInfo {
		Id: string;
		ServiceGuyId: string;
		AssetId: string;
		Status: number;
		CreatedAt: moment.Moment;
		DeletedAt: moment.Moment | undefined;
		Owner: ServiceGuyInfo | undefined;
		SerialId: string;
		SGName: string;
		CreatedBy: string;
		CreatedByName: string;
		ClosedBy: string;
		CloseByName: string;
		ContextId: string;
		Position: string;
		Trailer: string;
	}
	export enum InventoryAssetsStatus {
		Transfer = 0,
		Scrap = 1,
	}
	export class InventoryDelta {
		ItemId: string;
		Delta: number;
	}
	export class InventoryItemInfo {
		ServiceGuyId: string;
		ItemId: string;
		Item: ItemInfo | undefined;
		OnHand: number;
		StockSetting: ServiceGuyTypeStockSettingInfo | undefined;
		Owner: ServiceGuyInfo | undefined;
	}
	export class ItemInfo {
		ItemId: string;
		Name: string;
		OrderAmount: number;
	}
	export class LocationCheckupInfo {
		Id: number;
		ServiceGuyId: string;
		PerformedAt: moment.Moment;
		LocationId: number;
		ServiceGuy: ServiceGuyInfo | undefined;
		Location: LocationInfo | undefined;
		Objects: ObjectCheckupInfo[] = [];
	}
	export class LockLogInfo {
		LockId: string;
		TimeStamp: moment.Moment;
		Logs: string;
		Tags: string;
		RentalId: number | undefined;
	}
	export class RentalObjectSecurityCheckInfo {
		Id: number;
		RentalObjectId: number;
		RentalId: number;
		SavedAt: moment.Moment;
		JsonData: string;
		IsReturn: boolean;
		IsHandledBySG: boolean;
		IsHandledByCS: boolean;
		RentalObject: RentalObjectInfo | undefined;
	}
	export class RequestDetails {
		Id: string;
		RequestNo: number;
		InsuranceId: number | undefined;
		RentalObjectId: number;
		RentalObject: RentalObjectInfo | undefined;
		ServiceLocationId: number | undefined;
		ServiceLocation: ServiceLocationInfo | undefined;
		ObjectLocation: LocationInfo | undefined;
		CreatedAt: moment.Moment;
		Description: string;
		Status: ServiceRequestStatus | undefined;
		ExpectedResolveDate: moment.Moment | undefined;
		ActualResolveDate: moment.Moment | undefined;
		Events: RequestEventDetails[] = [];
		Tags: String[] | undefined;
		IsOneWay: boolean;
		ImageIds: string;
		IncidentDate: moment.Moment | undefined;
		ContactFirstName: string;
		ContactLastName: string;
		ContactEmail: string;
		ContactPhone: string;
		RecordNumber: string;
		IsInsurance: boolean;
		LFInvoiced: boolean;
		LFInvoicedDate: moment.Moment | undefined;
		SparePartsCost: number | undefined;
		SparePartsDiscount: number | undefined;
		TransportCost: number | undefined;
		LaborCost: number | undefined;
		SelfRisk: number | undefined;
		InvoiceLF: boolean;
		SalesPrice: number | undefined;
		InsuranceValue: number;
		ResidualValue: number;
		Country: string;
		InsuranceTags: string;
		IsRepurchased: boolean;
		Owner: string;
    DiarieNumber: number;
	}
	export class RequestEventDetails {
		Id: string;
		RequestId: string;
		CreatedAt: moment.Moment;
		Createdby: string;
		Status: ServiceRequestStatus | undefined;
		Note: string;
	}
	export class RequestFinance {
		SpareParts: number;
		SparePartsDiscount: number;
		SparePartsTotal: number;
		Transport: number;
		Labor: number;
		RedemptionValue: number;
		OriginalValue: number;
		Depreciation: number;
		TotalRepairs: number;
		TotalCosts: number;
		SalesValue: number;
		SelfRisk: number;
		TotalValue: number;
	}
	export class RequestUpdate {
		RequestId: string;
		Description: string;
		NewStatus: ServiceRequestStatus | undefined;
		Comment: string;
		ExpectedResolveDate: moment.Moment | undefined;
		Tags: String[] | undefined;
		IsInsurance: boolean | undefined;
		SparePartsCost: number | undefined;
		SparePartsDiscount: number | undefined;
		Transport: number | undefined;
		Labor: number | undefined;
		SelfRisk: number | undefined;
		SalesPrice: number | undefined;
		activateObject: boolean | undefined;
		ServicePartner: number | undefined;
		InsuranceTags: string;
		Owner: string;
		DiarieNumber: number;
	}
	export class ServiceGuyToBeGone {
		Id: string;
		Firstname: string;
		Lastname: string;
		CompanyName: string;
		Phone: string;
		Email: string;
		ContextId: string;
		InventoryItems: InventoryItemInfo[] = [];
		Assets: InventoryAssetInfo[] = [];
		LocationCheckups: LocationCheckupInfo[] = [];
	}
	export class ServiceGuyInfo {
		Id: string;
		Identifier: string;
		Firstname: string;
		Lastname: string;
		Companyname: string;
		Phone: string;
		Email: string;
		ContextId: string;
		ServiceGuyTypeId: number;
		DeliveryAddress: string;
		DeliveryZipCode: string;
		DeliveryCity: string;
		DeliveryCountry: string;
		CustomerAccount: string;
		ServiceGuyType: ServiceGuyTypeInfo | undefined;
		IsDeleted: boolean;
		InventoryItems: InventoryItemInfo[] = [];
		Assets: InventoryAssetInfo[] = [];
		AssignedLocationsCount: number;
		AssignedLocations: LocationInfo[] | undefined;
		AssignedLocationsFirst: string;
		AssignedLocationsAll: string;
	}
	export class ServiceGuyTypeInfo {
		Id: number;
		Name: string;
		Description: string;
		CreatedAt: moment.Moment;
		DeletedAt: moment.Moment | undefined;
		StockSettings: ServiceGuyTypeStockSettingInfo[] = [];
	}
	export class ServiceGuyTypeStockSettingInfo {
		ServiceGuyTypeId: number;
		ItemId: string;
		MinLevel: number;
		RecommendedLevel: number;
		Item: ItemInfo | undefined;
		Type: ServiceGuyTypeInfo | undefined;
	}
	export class ServiceLocationInfo {
		Id: number;
		Longitude: number | undefined;
		Latitude: number | undefined;
		Phone: string;
		ZipCode: string;
		City: string;
		Name: string;
		public toString(): string { return this.Name; } 
		Address: string;
		LocationCode: string;
		Distance: number;
		IsPrefered: boolean;
		Email: string;
		ToString: string;
	}
	export class ServiceRequestDetails {
		Id: string;
		RequestNo: number;
		RentalObjectId: number;
		RegistrationNUmber: string;
		ServiceLocationId: number | undefined;
		ServiceLocationCode: string;
		RentalLocationId: number | undefined;
		RentalLocationCode: string;
		CreatedAt: moment.Moment;
		Description: string;
		Status: ServiceRequestStatus | undefined;
		ExpectedResolveDate: moment.Moment | undefined;
		ActualResolveDate: moment.Moment | undefined;
		Tags: String[] | undefined;
		IncidentDate: moment.Moment | undefined;
		ImageIds: string;
		ContactFirstName: string;
		ContactLastName: string;
		ContactEmail: string;
		ContactPhone: string;
		RecordNumber: string;
		IsInsurance: boolean;
		IsOneWay: boolean;
		LFInvoiced: boolean;
		LFInvoicedDate: moment.Moment | undefined;
		SparePartsCost: number | undefined;
		SparePartsDiscount: number | undefined;
		TransportCost: number | undefined;
		LaborCost: number | undefined;
		SelfRisk: number | undefined;
		InvoiceLF: boolean;
		SalesPrice: number | undefined;
		InsuranceId: number | undefined;
		IsRepurchased: boolean;
		RequestEventDetails: RequestEventDetails[] = [];
		RequestImages: T4File[] | undefined;
	}
	export class ServiceRequestUpdateDetails {
		Head: ServiceRequestUpdateHeader | undefined;
		Rows: ServiceRequestUpdateRow[] = [];
	}
	export class ServiceRequestUpdateHeader {
		CustomerNumber: number;
		CaseNumber: string;
		Store: number;
		Date: string;
		ContactName: string;
	}
	export class ServiceRequestUpdateRow {
		Text: string;
		ArticleNumber: string;
		Description: string;
		Amount: number;
		Price: number;
	}
	export class UsedPartInfo {
		Id: string;
		RequestId: number;
		RentalObjectId: number;
		ItemId: string;
		ItemDescription: string;
		CreatedAt: moment.Moment;
		Amount: number;
		Price: number;
		ServiceRequest: RequestDetails | undefined;
	}
	export class BookingAlternative {
		RentalLocation: LocationInfo | undefined;
		PickupLocationId: number;
		ObjectTypeId: number;
		PickupTime: moment.Moment;
		ReturnTime: moment.Moment;
		Period: Date;
		Distance: number;
		ObjectId: number;
	}
	export class ReturnValidation {
		Result: ReturnValidationResult | undefined;
		ReturnPrice: PriceModel | undefined;
		Action: ReturnResultAction | undefined;
		ReceiptExists: boolean;
	}
	export class CompanyInfo {
		Id: number;
		OrgNo: string;
		Name: string;
		Address: string;
		Street: string;
		ZipCode: string;
		City: string;
		Co: any;
		Country: any;
	}
	export class CostInfo {
		Type: CostType | undefined;
		TimeStamp: moment.Moment;
		IsCanceled: boolean;
		Amount: number;
		Id: number;
		CanceledAt: moment.Moment | undefined;
		Description: string;
		ArticleName: string;
		IsPaid: boolean;
		IsRefunded: boolean;
		PaymentId: number | undefined;
		RentalId: number;
	}
	export class CreateBookingModel {
		PickupTime: moment.Moment;
		ReturnTime: moment.Moment;
		PickupLocationId: number;
		ReturnLocationId: number;
		DiscountId: string;
		Notes: string;
		IsOneWay: boolean;
		Addons: number[];
		CustomerObject: CustomerInfo | undefined;
		CustomerId: number | undefined;
		RentalObjectTypeId: number | undefined;
		ObjectId: number | undefined;
		WebBooking: boolean | undefined;
		BookingLatitude: number | undefined;
		BookingLongitude: number | undefined;
		inStoreBooking: boolean | undefined;
	}
	export class CustomerInfoConverter {
		CanWrite: boolean;
		CanRead: boolean;
	}
	export class CustomerInfo {
		SSN: string;
		Firstname: string;
		Lastname: string;
		CompanyName: string;
		Address: string;
		Street: string;
		ZipCode: string;
		City: string;
		Phone: string;
		MobilePhone: string;
		Email: string;
		OrgNo: string;
		Id: number | undefined;
		Type: CustomerType | undefined;
		Payment: CustomerPaymentRules | undefined;
		Blocked: boolean;
		BlockedReason: string;
		Notes: string;
		UserId: string;
		HasDibsTicket: boolean;
		Fields: any = {};
	}
	export class CustomerInfoExtensions {
	}
	export enum MaskValueType2 {
		General = 0,
		Address = 1,
		Email = 2,
		Zip = 3,
	}
	export class StringExtensions {
	}
	export class DailySalesItem {
		Date: moment.Moment;
		RentalId: number;
		PaymentReference: string;
		Amount: number;
		VAT: number;
		Total: number;
	}
	export class DiscountReasonInfo {
		Id: string;
		Reason: string;
	}
	export class FindNearByOptionsResult {
		AvailableNextSlot: moment.Moment | undefined;
		NearByLocations: LocationInfo[] = [];
		OtherCategoryObjectTypes: ObjectTypeInfo[] = [];
	}
	export class FindSlotsQuery {
		From: moment.Moment;
		To: moment.Moment;
		Locations: number[] = [];
	}
	export class FindSlotsResults {
		LocationId: number;
		TypeId: number;
		TimeSlots: TimeSlot[] = [];
	}
	export class TimeSlot {
		SlotNo: number;
		Date: moment.Moment;
		From: moment.Moment;
		To: moment.Moment;
	}
	export class InvoicePayment {
		CustomerReference: string;
		PaymentReference: string;
		PaymentDate: moment.Moment;
		Amount: number;
		Vat: number;
		TotalAmount: number;
		Costs: CostInfo[] = [];
		RentalId: number;
	}
	export class LocationKpi {
		Turnover: number;
		Prognosis: number;
		Rentals: number;
		Utilization: number;
	}
	export class NokeResponse {
		result: string;
		error_code: number;
		message: string;
		request: string;
		success: boolean;
	}
	export class ObjectTypeInfo {
		Id: number;
		PictureUri: string;
		Alias: string;
		Title: string;
		TotalWeight: string;
		MaxLoad: string;
		Length: string;
		Width: string;
		Height: string;
		FreeText: string;
		CheckCondition: string;
		IsDefault: boolean;
		IsSelected: boolean;
		ReplacementFor: string;
		AskIfNotSelected: boolean;
		Description: string;
		Category: string;
		CategoryIndex: number;
		IsHidden: boolean;
		ContextId: string | undefined;
		OverridesType: string;
		ExcludeFromCalculation: boolean;
		UnlimitedAvailability: boolean;
		UnlimitedRelations: boolean;
		AdditiveTo: Additive[] = [];
		VATPercentage: number;
		SortOrder: number;
		IsInsurance: boolean;
		LocationId: number | undefined;
		GroupId: number | undefined;
		IsRefundable: boolean;
		ObjectCount: number;
		SpecialCondition: boolean;
		ArticleNo: string;
	}
	export class Additive {
		Id: string;
		MainObjectId: number;
	}
	export class OneWayAvailability {
		LocationId: number;
		LocationName: string;
		Distance: number;
		Small: number;
		Medium: number;
		Large: number;
		XL: number;
		IncomingSmall: number;
		IncomingMedium: number;
		IncomingLarge: number;
		IncomingXL: number;
	}
	export class PaymentInfo {
		Id: number;
		Amount: number;
		IsExtension: boolean | undefined;
		Reference: string;
		Methods: string;
		TimeStamp: moment.Moment;
		Refunded: number | undefined;
		IsRefund: boolean;
	}
	export class PhotoAngleInfo {
		Id: number;
		Name: string;
		Icon: string;
		ImageUri: string;
		Description: string;
		DefaultType: boolean;
		ContextId: string;
	}
	export enum AlternativType {
		Best = 0,
		Cheapest = 1,
		Other = 2,
		Perfect = 3,
	}
	export class RentalAlternative {
		Id: number;
		PickupLocationId: number;
		ReturnLocationId: number | undefined;
		PickupTime: moment.Moment;
		ReturnTime: moment.Moment;
		ObjectTypeId: number;
		Price: PriceModel | undefined;
		Type: AlternativType | undefined;
		HasPickupLocationChanged: boolean;
		HasPickupTimeChanged: boolean;
		HasReturnLocationChanged: boolean;
		HasReturnTimeChanged: boolean;
		HasObjectTypeChanged: boolean;
		IsPreliminary: boolean;
		LocationDiff: number;
		QueueIndex: number | undefined;
		AverageMinAvailability: number;
		AverageMaxAvailability: number;
		PredictedBindDate: moment.Moment | undefined;
	}
	export class RentalEventInfo {
		Id: string;
		RentalObjectId: number | undefined;
		TimeStamp: moment.Moment;
		EventId: RentalEventType | undefined;
		Note: string;
		UserId: string;
		SignedBy: string;
	}
	export class RentalObjectInfo {
		Id: number;
		Title: string;
		Alias: string;
		Image: string;
		Status: RentalObjectStatus | undefined;
		RentalLocation: LocationInfo | undefined;
		Type: ObjectTypeInfo | undefined;
		IsEnabled: boolean;
		IsDisabledByAdmin: boolean;
		NextInspectionDate: moment.Moment;
		RegistrationYear: number;
		ToString: string;
		ServiceRequests: RequestDetails[] = [];
		Movements: RentalObjectMovement[] = [];
		Category: string;
		CategoryIndex: number | undefined;
		SerialNo: string;
		ContractStartDate: moment.Moment | undefined;
		ContractEndDate: any;
		AvailableOnline: boolean;
		IsDeleted: boolean;
		IsDeletionConfirmed: boolean;
		Notes: NoteInfo[] = [];
		LockIds: string[] = [];
		LockId: string;
		IsManual: boolean;
		Locks: SmartLockInfo[] = [];
		Lock: SmartLockInfo | undefined;
		HasNotes: boolean;
		HasIssues: boolean;
		IsBrenderupTrailer: boolean;
		ActionExpire: moment.Moment | undefined;
	}
	export class RentalObjectMovement {
		Type: string;
		BookingId: number | undefined;
		Status: number | undefined;
		StartTime: moment.Moment;
		EndTime: moment.Moment | undefined;
		CanceledAt: moment.Moment | undefined;
		FromLocation: string;
		ToLocation: string;
	}
	export enum PickupResultCode {
		SuccessWithoutPayment = 0,
		InvalidRental = 1,
		NoLock = 2,
		PaymentFailed = 3,
		PaymentActionRequired = 4,
		SuccessWithPayment = 5,
		ObjectNotAvilable = 6,
		NotWithinTimeLimit = 7,
	}
	export enum ReturnResultCode {
		Success = 0,
		InvalidRental = 1,
		NoLock = 2,
		PaymentFailed = 3,
		PaymentActionRequired = 4,
		TrailerNotAtLocation = 5,
		LockOpen = 6,
		NotAtLocation = 7,
	}
	export enum ProlongRentalResult {
		Success = 0,
		InvalidRental = 1,
		NoLock = 2,
		Unavailable = 3,
	}
	export class ExtendRentalOption {
		Hours: number;
		EndTime: moment.Moment;
		Cost: number;
	}
	export enum SmartLockCondition {
		Repairable = 0,
		Working = 1,
		Broken = -1,
	}
	export enum AssetCondition {
		Repairable = 0,
		Working = 1,
		Broken = -1,
	}
	export class SmartLockInfo {
		BatteryLevel: number;
		IsClosed: boolean;
		IsLocked: boolean;
		Latitude: number | undefined;
		Longitude: number | undefined;
		AtStation: boolean;
		IsOnline: boolean;
		Id: string;
		Mac: string;
		UpdatedAt: moment.Moment;
		CurrentHolder: string;
		Condition: SmartLockCondition | undefined;
		Type: string;
		LastUpdate: moment.Moment;
		IccId: string;
		BluetoothMAC: string;
		DeviceType: DeviceType | undefined;
		Password: string;
		ValidTill: moment.Moment | undefined;
	}
	export class AssetInfo {
		Id: string;
		SerialId: string;
		Mac: string;
		CurrentHolder: string;
		Condition: AssetCondition | undefined;
		IsRetired: boolean;
		Scrapped: boolean;
		CurrentPosition: string;
		PositionTime: moment.Moment;
		Status: string;
		LastUpdated: moment.Moment | undefined;
		LocationStatus: AssetLocationStatus | undefined;
		CreatedBy: string | undefined;
		ClosedBy: string | undefined;
		ContextId: string;
		AssetTransactions: AssetTransactionInfo[] = [];
	}
	export class AssetsAndHolder {
		Holder: AssetHolder | undefined;
		Assets: AssetAndLock[] = [];
		Asset: AssetAndLock | undefined;
		Index: number;
	}
	export class AssetAndLock {
		Lock: SmartLockInfo | undefined;
		Asset: AssetInfo | undefined;
	}
	export class AssetHolder {
		DisplayName: string;
		Username: string;
		RentalObject: RentalObjectInfo | undefined;
		Location: LocationInfo | undefined;
	}
	export class PickupResult {
		Code: PickupResultCode | undefined;
		PaymentResult: string;
		PaymentResultCode: string;
		IsSuccess: boolean;
	}
	export class ReturnResult {
		Code: ReturnResultCode | undefined;
		PaymentResult: string;
		PaymentResultCode: string;
		IsSuccess: boolean;
	}
	export class TypeCategoryInfo {
		Id: string;
		Name: string;
		Description: string;
		SortOrder: number;
		ContextId: string;
		Content: ObjectTypeInfo[] = [];
		ImageUri: string;
		Countries: string[] = [];
	}
	export class UserSignInResult {
		Success: boolean;
		ErrorCode: SignInError | undefined;
	}
	export class UserValidationResult {
		Validated: boolean;
		UserId: string;
		IsNew: boolean;
	}
	export class IntervalPrice {
		Period: number;
		Price: number;
	}
	export class SparepartPriceModel {
		SparepartId: number;
		SparePartType: ServiceRequestTags | undefined;
		Price: number;
	}
	export class PaymentRequestInfo {
		Id: number;
		Phone: string;
		Email: string;
		Description: string;
		Status: PaymentRequestStatus | undefined;
		Amount: number;
		TimeStamp: moment.Moment;
		ExpiresAt: moment.Moment;
		CanceledAt: moment.Moment | undefined;
		CanceledBy: string;
		CompletedAt: moment.Moment | undefined;
		Rental: RentalInfo | undefined;
		RentalId: number;
		Payment: PaymentInfo | undefined;
		CreatedBy: string;
	}
	export class Invoice {
		InvoiceDate: moment.Moment | undefined;
		Amount: number;
		Provision: number;
	}
	export class InvoiceSpecification {
		Header: Invoice | undefined;
		Lines: InvoiceLine[] = [];
	}
	export class InvoiceLine {
		RentalId: number;
		From: string;
		To: string;
		PickupTime: moment.Moment;
		ReturnTime: moment.Moment;
		ActualReturnTime: moment.Moment | undefined;
		CustomerPayment: number;
		InvoicedInsurancePayment: number | undefined;
		InvoicedCustomerPayment: number | undefined;
		BookingProvision: number | undefined;
		DiscloseProvision: number | undefined;
		ReturnProvision: number | undefined;
		InsuranceProvision: number | undefined;
		Size: string;
		Amount: number;
		Provision: number;
		IsWebBooking: boolean;
		PaymentReference: string;
		PaymentTimestamp: moment.Moment;
	}
	export class LocationOverview {
		WeekId: number;
		BookingTypes: OneWayObjectTypes | undefined;
		ObjectTypes: OneWayObjectTypes | undefined;
		LocationId: number;
		BookingCount: number;
		UnboundBookingCount: number;
		ObjectCount: number;
		AvailableObjectCount: number;
		ConfirmableObjectCount: number;
		DisabledCount: number;
		LocationInfo: LocationInfo | undefined;
		WarningActivated: boolean;
	}
	export class LockPosition {
		ObjectId: number;
		Object: RentalObjectInfo | undefined;
		LockId: string;
		Lat: number;
		Lng: number;
		AtStation: boolean;
		LastUpdate: moment.Moment;
	}
	export class OneWayCountingInfo {
		Id: string;
		StartDate: moment.Moment;
		EndDate: moment.Moment;
		CreatedAt: moment.Moment;
		CreatedBy: string;
		LocationCount: number;
		CompletedLocationCount: number;
		Completion: number;
		CountingLocations: OneWayCountingLocationInfo[] = [];
	}
	export class OneWayCountingLocationInfo {
		Id: string;
		LocationId: number;
		CompletedAt: moment.Moment | undefined;
		CompletedBy: string;
		Location: LocationInfo | undefined;
		IsCompleted: boolean;
		Results: OneWayCountingResultInfo[] = [];
	}
	export class OneWayCountingResultInfo {
		Id: string;
		RentalObjectId: number;
		Result: OneWayCountingResult | undefined;
		HandledAt: moment.Moment | undefined;
		HandledBy: string;
		IsHandled: boolean;
		RentalObject: RentalObjectInfo | undefined;
		SystemLocation: LocationInfo | undefined;
		CountingLocation: LocationInfo | undefined;
	}
	export class OneWayCountingForAllLocationsInfo {
		LocationId: number;
		Location: LocationInfo | undefined;
		StartDate: moment.Moment;
		EndDate: moment.Moment;
		CompletedAt: moment.Moment | undefined;
	}
	export class RentalObjectPosition {
		BookingId: number | undefined;
		FromTime: moment.Moment;
		Id: number;
		FromWeekId: number | undefined;
		IsAvailable: boolean;
		Probability: TrailerPositionProbability | undefined;
		RentalLocationId: number;
		RentalObjectTypeId: number | undefined;
		ToTime: moment.Moment | undefined;
		Title: string;
		Alias: string;
		ToWeekId: number | undefined;
		RealFromTime: moment.Moment;
		IsConfirmed: boolean;
		InboundBookingId: number | undefined;
		InboundCustomerId: number | undefined;
		InboundCustomerName: string;
	}
	export class DisableObjectResult {
		IsSuccessful: boolean;
		MovedBookings: number;
		AffectedBookings: number;
	}
	export class FindTypesQuery {
		From: moment.Moment;
		To: moment.Moment;
		Locations: number[] = [];
	}
	export class FindTypesResults {
		LocationId: number;
		Types: ObjectTypeInfo[] = [];
	}
	export class NoteInfo {
		Id: number;
		Content: string;
		Action: string;
		CreatedAt: moment.Moment;
		CreatedBy: string;
		DeletedAt: moment.Moment | undefined;
		DeletedBy: string;
	}
	export class ObjectImportData {
		TrailerId: number;
		ExternalId: string;
		Alias: string;
		RegNo: string;
		TypeId: number;
		StationId: number;
		CostPerMonth: number;
		IsDeleted: boolean;
		SerialNo: string;
		ContractStartDate: moment.Moment | undefined;
		ContractEndDate: moment.Moment | undefined;
		Service: boolean;
	}
	export class UpdateObjectResults {
		Success: boolean;
		ValidationStatus: ObjectValidationStatus | undefined;
		Id: number | undefined;
	}
	export class NokeLockInfoResponse {
		Result: string;
		Message: string;
		ErrorCode: number;
		Data: NokeLockInfoResultWrapper | undefined;
	}
	export class NokeLockInfoResultWrapper {
		Locks: NokeLockInfoResult[] = [];
	}
	export class NokeLockInfoResult {
		LockId: string;
		Mac: string;
		BatteryLevel: number;
		FirmwareVersion: string;
	}
	export class NokeUnlockResponse {
		Result: string;
		Message: string;
		ErrorCode: number;
		Data: NokeUnlockCommand | undefined;
	}
	export class NokeUnlockCommand {
		Commands: string;
	}
	export class MessageModel {
		Id: string;
		Priority: MessagePriority | undefined;
		From: string;
		Headline: string;
		Content: string;
		IsUnread: boolean;
		SentAt: moment.Moment;
		ValidFrom: moment.Moment | undefined;
		ValidThrough: moment.Moment | undefined;
		RequireReadConfirmation: boolean;
		Reads: MessageReadModel[] = [];
	}
	export class MessageReadModel {
		Id: string;
		MessageId: string;
		Username: string;
		LocationId: number | undefined;
		Response: string;
		OpenedAt: moment.Moment;
		RespondedAt: moment.Moment | undefined;
		LocationName: string;
	}
	export class Receiver {
		Type: ReceiverType | undefined;
		ReceiverId: string;
		FriendlyName: string;
		Description: string;
		AddAsDefault: boolean;
		LocationId: number | undefined;
		GroupId: number | undefined;
	}
	export enum ReceiverType {
		User = 0,
		Location = 1,
		Group = 2,
		SendGroup = 3,
		Email = 4,
	}
	export class MapPoint {
		Lat: number;
		Lng: number;
		Description: string;
	}
	export class EntityEventInfo {
		Id: string;
		EntityId: string;
		EntityTypeId: string;
		CreatedAt: moment.Moment;
		EventId: number;
		Notes: string;
		Metadata: string;
		UserId: string;
		SignedBy: string;
		EntityType: EntityTypeInfo | undefined;
		EventType: string;
	}
	export class EntityTypeInfo {
		Id: string;
		Name: string;
		Description: string;
	}
	export class ShipmentInfo {
		Id: string;
		UserId: string;
		CreatedAt: moment.Moment;
		StartedAt: moment.Moment | undefined;
		CompletedAt: moment.Moment | undefined;
		Status: ShipmentStatus | undefined;
		LastKnownLatitude: number | undefined;
		LastKnownLongitude: number | undefined;
		Cargo: CargoInfo[] = [];
	}
	export class CargoInfo {
		RentalObject: RentalObjectInfo | undefined;
		PickupLocation: LocationInfo | undefined;
		ReturnLocation: LocationInfo | undefined;
		PickupTime: moment.Moment;
		DropTime: moment.Moment | undefined;
		IsLoaded: boolean;
		Id: string;
	}
	export class LocationImportData {
		Id: number;
		Name: string;
		Address: string;
		ZipCode: string;
		City: string;
		Country: string;
		Phone: string;
		Category: string;
		AllowOneWay: boolean;
		LocationId: string;
		Email: string;
		InvoiceAccount: string;
		SalesRep: string;
		ServicePartnerId: number | undefined;
		RepairPartnerId: number | undefined;
		RepairZone: number | undefined;
		GroupId: number;
		CustomerLocationId: string;
	}
	export class LocationUserInfo {
		Id: number;
		Firstname: string;
		Lastname: string;
		Phone: string;
		Email: string;
		PinCode: string;
	}
	export class OpenHours {
		Date: moment.Moment;
		IsClosed: boolean;
		Opens: moment.Moment;
		Closes: moment.Moment;
	}
	export class OpenHourSetting {
		Id: number;
		DayOfWeek: number | undefined;
		SpecificDate: moment.Moment | undefined;
		Opens: moment.Moment;
		Closes: moment.Moment;
		Closed: boolean;
	}
	export class LocationInfo {
		Id: number;
		RentalLocationGroupId: number;
		Longitude: number | undefined;
		Latitude: number | undefined;
		Distance: number | undefined;
		Phone: string;
		ZipCode: string;
		City: string;
		Country: string;
		Name: string;
		Address: string;
		LocationId: number;
		OpenHours: string;
		LocationCode: string;
		CustomerLocationId: string;
		Email: string;
		ServiceLocationId: number | undefined;
		LocalServiceLocationId: number | undefined;
		ServiceZone: number | undefined;
		ServiceLocation: ServiceLocationInfo | undefined;
		LocalServiceLocation: ServiceLocationInfo | undefined;
		IsServiceLocation: boolean;
		ToString: string;
		OpenForPickup: boolean;
		OpenForReturn: boolean;
		HardTrailerLimit: number;
		SoftTrailerLimit: number;
		ServiceAgreement: boolean;
		IsOneWay: boolean;
		IsDeleted: boolean;
		SalesRep: SalesRepInfo | undefined;
		TrailerPointLatitude: number;
		TrailerPointLongitude: number;
		TrailerPointRadius: number;
		HasFamilies: string;
		ServiceGuyId: string | undefined;
	}
	export class SalesRepInfo {
		Image: string;
		Username: string;
		Firstname: string;
		Lastname: string;
		Phone: string;
		Email: string;
	}
	export class UpdateLocationResults {
		Success: boolean;
		ValidationStatus: LocationValidationStatus | undefined;
		Id: number | undefined;
	}
	export class TurboHubEvent {
		Type: TurboHubEventType | undefined;
		ContextId: string;
		Timestamp: moment.Moment;
		Entity: any;
		EntityId: string;
		ClientId: string;
	}
	export class HierarchyObject {
		ID: string;
		Name: string;
		GroupId: number | undefined;
		Children: HierarchyObject[] = [];
		ImageUrl: string;
	}
	export class ExtractRegNoResponse {
		RegNo: string;
		Success: boolean;
		Error: string;
	}
	export class T4Image {
		EntityId: string;
		ContextId: string;
		Type: ImageType | undefined;
		Uri: string;
		Data: string;
	}
	export class T4File {
		ContextId: string;
		Type: FileType | undefined;
		Filename: string;
		Uri: string;
		Data: string;
	}
	export class T4ListItem {
		Id: string;
		Name: string;
	}
	export class T4Result {
		IsSuccess: boolean;
		StatusCode: number;
		Message: string;
		Data: any;
	}
	export class CustomerContextSettings {
		Fields: CustomerField[] = [];
	}
	export enum CustomerFieldType {
		Text = 0,
	}
	export class CustomerField {
		FieldId: string;
		Type: CustomerFieldType | undefined;
		Mapping: string;
		Visible: boolean;
		DefaultValue: string;
		Required: boolean;
		MinLength: number;
		MaxLength: number;
		RegEx: string;
		CopyFrom: string;
		Placeholder: string;
		Value: string;
		Columns: number;
		Label: string;
		ValidFor: CustomerType | undefined;
		IsValid: boolean;
	}
	export class ContextInfo {
		Id: string;
		Name: string;
		LocationDate: moment.Moment;
	}
	export class ProductPriceInfo {
		Id: string;
		ContextId: string;
		ProductId: string;
		Price: number;
		Type: ProductPriceType | undefined;
		UseAbsoluteAmount: boolean;
		Product: ProductInfo | undefined;
	}
	export class ProductInfo {
		Id: string;
		ItemId: string;
		Name: string;
		Unit: string;
		AxItemId: string;
		VatPercentage: number;
		RequireSigning: boolean;
		InvoiceSettings: ProductInvoiceSettings | undefined;
	}
	export class T4InvoiceInfo {
		Id: string;
		InvoiceAccount: string;
		GeneratedAt: moment.Moment;
		CompletedAt: moment.Moment | undefined;
		MessageId: string;
		SalesId: string;
		LineCount: number;
		InvoiceAmount: number;
		VatAmount: number;
		Specification: string;
		IsProcessing: boolean;
		AggregatedLines: T4InvoiceLine[] = [];
		SentAt: moment.Moment | undefined;
	}
	export class T4InvoiceLine {
		ProductName: string;
		Count: number;
		Price: number;
		ItemId: string;
	}
	export class CampaignInfo {
		DiscountCode: string;
		CreatedAt: moment.Moment;
		ValidFrom: moment.Moment;
		ValidThrough: moment.Moment;
		MultipleUse: boolean;
		RequireCode: boolean;
		Condition: string;
		MaxUseCount: number;
		UsageCount: number;
		IsUsed: number;
		Priority: number;
		IsValid: boolean | undefined;
		IsStackable: boolean;
		Comment: string;
		CommentIsPublic: boolean;
		ReasonId: string | undefined;
		MaxUseCountPerCustomer: number | undefined;
		ContextId: string | undefined;
		IsTemplate: boolean;
		TemplateCode: string;
		GenerationPattern: DiscountGenerationPattern | undefined;
		GenerationProvider: string;
		GenerationBatchId: string | undefined;
		ConditionText: string;
		CanEdit: boolean;
		Type: CampaignType | undefined;
		Value: number;
		ValueType: DiscountValueType | undefined;
	}
	export class DiscountCampaignReasonInfo {
		Id: number;
		Name: number;
	}
	export class GenerateVoucherResult {
		TemplateCode: string;
		BatchId: string;
		VoucherUrl: string;
		VoucherCodes: string[] = [];
		GroupId: number;
		Partner: string;
	}
	export class AddExternalLoginBindingModel {
		ExternalAccessToken: string;
	}
	export class ChangePasswordBindingModel {
		OldPassword: string;
		NewPassword: string;
		ConfirmPassword: string;
	}
	export class RegisterBindingModel {
		Email: string;
		Password: string;
		ConfirmPassword: string;
	}
	export class RegisterExternalBindingModel {
		Email: string;
	}
	export class RemoveLoginBindingModel {
		LoginProvider: string;
		ProviderKey: string;
	}
	export class SetPasswordBindingModel {
		NewPassword: string;
		ConfirmPassword: string;
	}
	export class ExternalLoginViewModel {
		Name: string;
		Url: string;
		State: string;
	}
	export class ManageInfoViewModel {
		LocalLoginProvider: string;
		Email: string;
		Logins: UserLoginInfoViewModel[] = [];
		ExternalLoginProviders: ExternalLoginViewModel[] = [];
	}
	export class UserInfoViewModel {
		Email: string;
		HasRegistered: boolean;
		LoginProvider: string;
	}
	export class UserLoginInfoViewModel {
		LoginProvider: string;
		ProviderKey: string;
	}
	export class AuthToken {
		userName: string;
		token_type: string;
		access_token: string;
		expires_in: number;
		expires: string;
		issued: string;
	}
	export class BIDToken {
		Token: string;
		Settings: ClientSettings | undefined;
	}
	export class PasswordResetResult {
		User: AccountCredentials | undefined;
		Admin: AccountCredentials | undefined;
		Email: string;
		EmailStatus: number;
	}
	export class AccountCredentials {
		Username: string;
		Password: string;
	}
	export class User {
		Username: string;
		Roles: string[] = [];
		Impersonating: User | undefined;
		MayImpersonate: boolean;
		FriendlyName: string;
		Email: string;
		Phone: string;
		IsInitiated: boolean;
		IsApi: boolean;
		ContextId: string;
		Hierarchy: string;
		Rights: string[] = [];
		ServiceGuyId: string;
		Settings: ClientSettings | undefined;
	}
	export class TrainingImageInfo {
		Id: number;
		filePath: string;
	}
	export class DiscountModel {
		Value: number;
		Type: DiscountValueType | undefined;
		DiscountId: string;
		Comment: string;
		IsEmpty: boolean;
		ReasonId: string | undefined;
		VoucherUri: string;
		VoucherDocumentUri: string;
	}
	export class DiscountModelExtentions {
	}
	export class PriceInfoModel {
		MinimumBookingPeriod: number;
		SelfriskCost: number;
		SelfriskReductionCost: number;
		IsSelfriskRecustionIncluded: boolean;
		OnlineBookingFee: number;
		Deposition: number;
		UsesGpsBasedBookingFee: boolean;
	}
	export class PriceModel {
		Lines: PriceModelRow[] = [];
		Deposition: number;
		RentalPrice: number;
		DelayPenalty: number;
		BookingFee: number;
		TotalPrice: number;
		Discount: number;
		Vat: number;
		DiscountableTotalPrice: number;
		NoDiscountablePrice: number;
		DiscountableAdditions: number;
		NoDiscountAdditions: number;
		NoDiscountAddedVAT: number;
		DiscountedTotalPrice: number;
		DiscountedVat: number;
		Method: string;
		AddedProducts: PriceModelRow[] = [];
		Payments: PaymentInfo[] = [];
		ToPay: number;
		DiscountSource: DiscountModel | undefined;
		CalculationContext: string | undefined;
	}
	export class PriceModelRow {
		ArticleNo: number;
		Name: string;
		OriginalPrice: number;
		OriginalVat: number;
		Price: number;
		Vat: number;
		VatPercentage: number;
		Discount: number;
		AllowDiscount: boolean;
		AllowRefund: boolean;
		ExcludeFromSum: boolean;
		Hidden: boolean;
	}
	export enum RentalFeePolicy {
		Default = 0,
		NotSameDay = 1,
		NotWithin24HR = 2,
	}
	export enum DibsPaymentStatus {
		Authorized = 1,
		Reserved = 2,
		Cancelled = 3,
		Captured = 4,
		Refunded = 5,
	}
	export enum DibsPurchaseType {
		Rental = 1,
		Deposition = 2,
		AutoCancelPenalty = 3,
		Delay = 4,
	}
	export enum DibsPaymentSuccess {
		Success = 1,
		NotFound = 2,
		FoundConflictingPayment = 3,
		Failed = 4,
		AlreadyApproved = 5,
	}
	export enum TurboPaymentAction {
		None = 0,
		Confirm = 1,
		Cancel = 2,
	}
	export enum DibsPaymentType {
		None = 0,
		Direct = 1,
		Ticket = 2,
		DirectReserve = 3,
	}
	export enum LockActivityType {
		Other = 0,
		UserUnlock = 1,
		AdminUnlock = 2,
		Unshackle = 3,
		FirmwareMode = 4,
	}
	export enum MessagePriority {
		Random = 0,
		Banner = 4,
		UrgentBanner = 5,
		Email = 10,
		Priority = 20,
		Urgent = 30,
	}
	export enum OneWayRentalObjectType {
		Large = 340,
		Small = 421,
		Medium = 422,
		XL = 423,
	}
	export enum RentalObjectStatus {
		Invalid = 0,
		Available = 1,
		Disclosed = 2,
		Service = 4,
		Late = 8,
		AvailableButBound = 16,
	}
	export enum RentalPaymentStatus {
		Default = 0,
		Unpaid = 1,
		Paid = 3,
		Reserved = 4,
		Ticket = 5,
	}
	export enum RentalStatus {
		Reserved = 0,
		Canceled = 1,
		ReservedAndBound = 50,
		Active = 100,
		Late = 150,
		Returned = 200,
		Service = -2,
		New = -1,
	}
	export enum TurboOrderStatus {
		New = 0,
		Draft = 1,
		DocumentCreated = 10,
		DocumentSigned = 25,
		AxProcessing = 50,
		Created = 100,
		Canceled = 101,
	}
	export enum BookingErrorCode {
		Valid = 0,
		LocationNotOpen = 1,
		PeriodUnavailable = 2,
		InvalidSecurityKey = 4,
		InvalidLocation = 8,
		InvalidPrimaryObjectType = 16,
		InvalidSecondaryObjectType = 32,
		ObjectUnavailable = 64,
		IsNotCustomersRental = 128,
		ChangesNotAllowed = 256,
		InvalidCustomer = 512,
		PickupReceiptUpdated = 1024,
		CustomerIsBlocked = 2048,
		MaxBookingsPerDay = 4096,
		MaximumConcurrentBookings = 8192,
	}
	export enum ContentType {
		Email = 0,
		Sms = 1,
		General = 2,
	}
	export enum ContentFormat {
		Plain = 0,
		Html = 1,
		Razor = 2,
	}
	export enum CostType {
		BookingFee = 0,
		Rental = 1,
		Insurance = 2,
		Discount = 3,
		Extra = 4,
		DelayPenalty = 5,
		AddOn = 6,
	}
	export enum CustomerDeletionResponse {
		OK = 0,
		InvalidInput = 1,
		NoUser = 2,
		PendingBooking = 3,
		RecentBooking = 4,
		Unauthorized = 5,
	}
	export enum CustomerPaymentRules {
		Default = 0,
		Invoice = 1,
	}
	export enum CustomerType {
		Company = 1,
		Consumer = 2,
	}
	export enum DamageType {
		Issue = 0,
		Note = 1,
		SecurityCheck = 2,
	}
	export enum EntityType {
		Rental = 0,
		Customer = 1,
		Lock = 2,
		RentalObject = 3,
		RentalLocation = 4,
	}
	export enum ImageType {
		LocationGroup = 0,
		LocationGroupPin = 1,
		ObjectType = 2,
		TypeCategory = 3,
		ServiceRequest = 4,
		PhotoAngle = 5,
	}
	export enum FileType {
		ServiceRequestDocument = 0,
	}
	export enum InvoiceLineType {
		BookingProvision = 0,
		PickupProvision = 1,
		WebPickupProvision = 2,
		ReturnProvision = 3,
		CustomerPayments = 4,
		InsuranceProvision = 5,
		CustomerPaymentsInsurance = 6,
	}
	export enum ObjectCheckupStatus {
		Ok = 0,
		RentedOut = 1,
		NotAtLocation = 2,
	}
	export enum OneWayCountingResult {
		Found = 1,
		Deleted = 2,
		NotFound = 3,
		Correct = 4,
	}
	export enum OneWayObjectTypes {
		All = 0,
		Small = 1,
		Medium = 2,
		Large = 4,
		XL = 8,
	}
	export enum ProductType {
		RentalObject = 0,
		SparePart = 1,
		Addon = 2,
		Default = 3,
	}
	export enum ReceiptType {
		Invalid = 0,
		Receipt = 1,
		Extension = 2,
		Delay = 4,
		OneWay = 8,
		Local = 16,
	}
	export enum ReceiptPickupType {
		Standard = 0,
		OneWay = 1,
		SeparateConditions = 2,
		Unmanned = 3,
		Use4Free = 4,
	}
	export enum ReceiptReturnType {
		Standard = 0,
		OneWay = 1,
		SeparateConditions = 2,
		Unmanned = 3,
		Use4Free = 4,
	}
	export enum RentalEventType {
		General = 0,
		Created = 10,
		Assigned = 20,
		Unassigned = 30,
		Confirmed = 40,
		Payment = 50,
		Disclosed = 60,
		Returned = 70,
		Sms = 500,
		Call = 501,
		Change = 502,
		Canceled = 503,
		Email = 504,
		DigitallySigned = 505,
		Unlocked = 506,
		Comment = 507,
	}
	export enum ReportInterval {
		Yearly = 0,
		Monthly = 1,
		Weekly = 2,
		Daily = 3,
	}
	export enum ReportFormat {
		Csv = 0,
		Excel = 1,
		Json = 2,
	}
	export enum ReturnResultAction {
		Unspecified = 0,
		Confirm = 10,
		Ignore = 20,
	}
	export enum ReturnValidationResult {
		Invalid = 0,
		Valid = 10,
		Changed = 20,
		Late = 30,
		WrongLocation = 40,
	}
	export enum ServiceRequestStatus {
		New = 0,
		Updated = 3,
		Incomplete = 5,
		Received = 10,
		Parked = 20,
		WaitingForServiceGuy = 21,
		TransportOrdered = 25,
		ServiceRequested = 29,
		ServiceOrdered = 30,
		ReportedStolen = 35,
		SparePartRequested = 39,
		SparePartOrdered = 40,
		Expired = 45,
		ReturnOrdered = 49,
		Completed = 50,
		Canceled = 100,
		ServiceParterChanged = 200,
		Invalid = -1,
	}
	export enum ServiceRequestTags {
		None = 0,
		Missing = 1,
		Inspection = 2,
		Immobilized = 4,
		InProduction = 8,
		Hitch = 16,
		Reflex = 32,
		Lamp = 64,
		Wire = 128,
		Tyre = 256,
		Chassi = 512,
		SupportWheel = 1024,
		Lock = 2048,
		Fender = 4096,
		MudFlap = 8192,
		StolenDuringRent = 16384,
		StolenFromLocation = 32768,
		InsuranceDamageDuringRent = 65536,
		InsuranceDamageAtLocation = 131072,
	}
	export enum RequestFeedbackType {
		General = 0,
		InsuranceRejection = 1,
		MoreInformationNeeded = 2,
	}
	export enum ShipmentStatus {
		Created = 1,
		LoadingStarted = 2,
		Started = 3,
		Completed = 4,
	}
	export enum SignInError {
		Success = 0,
		UserNotFound = 1,
		CustomerNotFound = 2,
		CodeNotSent = 3,
		EmailDoesNotMatchAccount = 4,
		UnknownError = 5,
		PhoneNumberAlreadyTaken = 6,
	}
	export enum TerminationEventType {
		PurchaseOrder = 0,
		SalesOrder = 1,
		OffRentAction = 2,
		Extension = 3,
	}
	export enum TrailerPositionProbability {
		AtStation = 1,
		PendingArrival = 2,
		Confirmed = 4,
		Preliminary = 8,
		Late = 16,
		Disabled = 32,
	}
	export enum Triggers {
		None = 0,
		OnPickup = 1,
		OnReturn = 2,
	}
	export enum TurboHubEventType {
		Booking = 0,
		Order = 1,
		SmartLock = 2,
	}
	export enum TurboVersion {
		Standard = 0,
		Professional = 10,
		UltraDeluxe = 20,
	}
	export enum WebHookEvent {
		AddLocation = 1,
		UpdateLocation = 2,
		DeleteLocation = 4,
		AddObject = 8,
		UpdateObject = 16,
		DeleteObject = 32,
		AddServiceRequest = 64,
		UpdateServiceRequest = 128,
		DeleteServiceRequest = 256,
	}
	export enum WebHookFormat {
		Json = 0,
		Xml = 1,
	}
	export enum WebHookMessageType {
		InvoiceInfo = 0,
		RentalInfo = 1,
		RentalLocationInfo = 2,
		RentalObjectInfo = 3,
		ServiceRequestInfo = 4,
	}
	export enum WebHookDeliveryType {
		HTTP = 0,
		FTP = 1,
	}
	export enum ProductInvoiceSettings {
		TotalCount = 0,
		MultiLine = 1,
	}
	export enum ControlType {
		TextBox = 0,
		Html = 1,
		Checkbox = 2,
		Radiobutton = 3,
		Json = 4,
		TextArea = 5,
		DropDown = 6,
		Numeric = 7,
	}
	export enum DeviceType {
		Lock = 0,
		Tracker = 1,
		NfcTag = 2,
	}
	export enum LocationValidationStatus {
		Valid = 0,
		GroupNotFound = 2,
	}
	export enum ObjectValidationStatus {
		Valid = 0,
		StationNotFound = 2,
		TypeNotFound = 3,
		Exception = 4,
	}
	export enum PaymentRequestStatus {
		Created = 0,
		Pending = 10,
		Expired = 50,
		Completed = 100,
		Canceled = 101,
		ManuallyInvoiced = 150,
	}
	export enum ProviderPaymentStatus {
		Authorized = 0,
		Reserved = 1,
		Captured = 2,
		Canceled = 3,
		Refunded = 4,
		Preliminary = -1,
	}
	export enum ContractStatus {
		Review = 0,
		OnOrder = 10,
		AwaitingProduction = 20,
		ProductionScheduled = 24,
		InProduction = 28,
		ReadyForDelivery = 50,
		DeliveryScheduled = 55,
		Delivered = 60,
		Terminated = 100,
	}
	export enum RepurchaseStatus {
		NoContracts = 0,
		Repurchased = 1,
		TermiantedAsAnInsuranceCase = 2,
	}
	export enum VehicleStatus {
		OnContract = 0,
		InStore = 1,
		Sold = 2,
		Scrapped = 4,
		Insurance = 8,
	}
	export enum PhotoAngle {
		Front = 0,
		Back = 1,
		Inside = 2,
	}
	export enum LockEventType {
		Info = 0,
	}
	export enum RentalLocationEventType {
		Info = 0,
	}
	export enum RentalObjectEventType {
		Info = 0,
		Created = 1,
		Deleted = 2,
	}
	export enum EntityType {
		ObjectType = 0,
		Category = 1,
		LocationGroup = 2,
	}
	export enum ProductPriceType {
		Fixed = 0,
		Percentage = 1,
	}
	export enum CampaignType {
		Voucher = 1,
		Campaign = 2,
		Template = 3,
	}
	export enum DiscountGenerationPattern {
		Manual = 0,
		Weekly = 1,
		Monthly = 2,
		Yearly = 3,
	}
	export enum DiscountValueType {
		Percentage = 0,
		Monetary = 1,
		Time = 2,
	}
	export enum SystemRights {
		None = 0,
		CC_ManagePrices = 1,
		CC_ManageProducts = 2,
		T4_Booking_CancelBooking = 3,
		T4_Booking_DiscloseBooking = 4,
		T4_Booking_EditBooking = 5,
		T4_Booking_Refund = 6,
		T4_Booking_ReturnBooking = 7,
		T4_Booking_SetDiscount = 8,
		T4_Booking_ViewSalesData = 9,
		T4_Fleet_ManageCategories = 10,
		T4_Fleet_ManageObjects = 11,
		T4_Fleet_ManageObjectTypes = 12,
		T4_Settings_ChangeSettings = 13,
		T4_Settings_ManageRights = 14,
		TO_Contracts_Manage = 15,
		TO_Insurance_FollowUp = 16,
		TO_Insurance_Manage = 17,
		TO_Insurance_SetOff = 18,
		TO_Insurance_View = 19,
	}
	export enum AssetLocationStatus {
		Unknown = 0,
		StigamoLager = 1,
		ServiceGuy = 10,
		Customer = 30,
		Rented = 40,
	}
