import moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorage {
    public localStorage:any;

    constructor() {
        try {
            if (!localStorage) {
                throw new Error('Current browser does not support Local Storage');
            }
            this.localStorage = localStorage;
        }catch(ex) {}
    }

    public set(key: string, value: string): void {
        try {
            this.localStorage[key] = value;
        } catch(ex) { }
    }

    public get(key: string): string {
        try {
            return this.localStorage[key] || false;
        } catch (ex) { }

        return '';
    }

    public setObject(key: string, value: any): void {
        try {
         this.localStorage[key] = JSON.stringify(value);
        }catch(ex) { }
    }

    public getObject(key: string): any {
        try {
            return this.recurseObject(JSON.parse(this.localStorage[key] || '{}'));
        }catch(ex) { }
    }

    public remove(key: string): any {
        try {
            this.localStorage[key] = null;
            this.localStorage.removeItem(key);
        } catch (ex) {
            console.log(ex);
        }
    }

    private dateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?$/;
    private dateRegex2 = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?Z$/;
    private dateRegex3 = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\+\d\d:\d\d)?$/;
    private recurseObject(obj: any): any {
        var result: any = obj;
        if (obj !== null) {

            // Handle arrays
            if (Array.isArray(obj)) {
                obj.forEach((item, index) => {
                    obj[index] = this.recurseObject(item);
                });

                return obj;
            }

            if (obj instanceof Object) {
                result = Object.assign({}, obj);

                // Parse properties of objects
                for (var key in result) {
                    var property = result[key];
                    if (typeof property === 'object') {
                        result[key] = this.recurseObject(property);
                    } else if (typeof property === 'string' && this.dateRegex.test(property)) {
                        result[key] = moment(new Date(property + 'Z'));
                    } else if (typeof property === 'string' && (this.dateRegex2.test(property) || this.dateRegex3.test(property))) {
                        result[key] = moment(new Date(property));
                    }
                }
            }
        }
        return result;
    };
}
