<div [ngClass]="{ 'Insurance-border-green': haveInsurance, 'Insurance-border-red': !haveInsurance }">
  <h3 class="text-center" mat-dialog-title>
    <span *ngIf="product.Title.indexOf('Turbo.') == 0"> {{ product.Title | translate: 'Insurance' }}</span>
    <span *ngIf="product.Title.indexOf('Turbo.') != 0"> {{ product.Title }}</span>
  </h3>
  <div *ngIf="product.Id == 1004 || product.Id == 901">
    <p *ngIf="haveInsurance" class="text-center">
      {{ 'Intellitrailer.Booking.UnConfirmedBooking.Price' | translate : 'Price:' }}
      {{ product.Price }} <span>{{ 'Intellitrailer.Booking.UnConfirmedBooking.PriceInformation' | translate : 'SEK per day' }}</span>
    </p>
    <p *ngIf="!haveInsurance" class="text-center">
      {{ 'Intellitrailer.Booking.BookingSummary.msgOptedOutIsuranceInfo' | translate : 'You have opted out of the insurance'  }}
    </p>
  </div>
  <div *ngIf="product.Id == 1004 || product.Id == 901" mat-dialog-content>
    <div class="container">
      <div *ngIf="haveInsurance">
        <div class="row">
          <div class="col-3 p-0">
            <div class="insurance-trailer-green"></div>
          </div>
          <div class="col-9 p-0">
            <p>{{ 'Intellitrailer.Booking.InsuranceInformation.trailerGreenInfo' | translate : 'The isurance covers the deductible for the trailer up to 5000 SEK' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-3 p-0">
            <div class="insurance-docs-green"></div>
          </div>
          <div class="col-9 p-0">
            <p>{{ 'Intellitrailer.Booking.InsuranceInformation.documentsGreenInfo1' | translate : 'The isurance covers all the damages to the trailer except spare parts' }}</p>
          </div>
        </div>
        <div *ngIf="!bookingConfirmed" class="row btn-container" mat-dialog-actions>
          <button mat-button mat-raised-button color="primary" (click)="confirm()" class="take-insurance-button">
            {{ 'Intellitrailer.Booking.BookingSummary.driveWithInsurance' | translate : 'Drive safely with insurance'  }}
          </button>
          <div *ngIf="!bookingConfirmed" (click)="onNoClick()" class="without-insurance-link">
            {{ 'Intellitrailer.Booking.BookingSummary.continueWithoutInsuranceInfo' | translate : 'I want to continue without insurance'  }}
          </div>
        </div>
      </div>
      <div *ngIf="!haveInsurance">
        <div class="row">
          <div class="col-3 p-0">
            <div class="insurance-trailer-red"></div>
          </div>
          <div class="col-9 p-0">
            <p>{{ 'Intellitrailer.Booking.InsuranceInformation.trailerRedInfo' | translate : 'In the event of damage, you are responsible for the deductible of 5000 SEK' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-3 p-0">
            <div class="insurance-docs-red"></div>
          </div>
          <div class="col-9 p-0">
            <p>{{ 'Intellitrailer.Booking.InsuranceInformation.documentsRedInfo' | translate : 'If an accident occurs , you will be responsible for managing the claims process' }}</p>
          </div>
        </div>
        <div *ngIf="!bookingConfirmed" class="row btn-container" mat-dialog-actions>
          <button mat-button mat-raised-button color="primary" (click)="confirm()" class="take-insurance-button">
            {{ 'Intellitrailer.Booking.BookingSummary.addInsuranceButtonText' | translate : 'Add insurance '}} {{ product.Price | currency }}
          </button>
          <div *ngIf="!bookingConfirmed" class="without-insurance-link" (click)="onNoInsurance()">
            {{ 'Intellitrailer.Booking.BookingSummary.continueWithoutInsuranceInfo' | translate : 'I want to continue without insurance'  }}
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="!(product.Id == 1004 || product.Id == 901)" mat-dialog-content>
    <div class="container">
      <div classs="row">
        <p style="font-size: 14px; font-weight: bold;">
          <span *ngIf="product.Description.indexOf('Turbo.') == 0"> {{ product.Description | translate }}</span>
          <span *ngIf="product.Description.indexOf('Turbo.') != 0"> {{ product.Description }}</span>
        </p>
        <p style="font-size: 14px; font-weight: bold;">
          {{ 'Intellitrailer.Booking.UnConfirmedBooking.ConfirmAddon' | translate : 'Do you want to continue with' }}
          <span *ngIf="product.Title.indexOf('Turbo.') == 0"> {{ product.Title | translate : 'Excess reduction' }}?</span>
          <span *ngIf="product.Title.indexOf('Turbo.') != 0"> {{ product.Title }}?</span>
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="!(product.Id == 1004 || product.Id == 901)" mat-dialog-actions>
    <button *ngIf="!bookingConfirmed" mat-button mat-raised-button color="primary" (click)="confirm()" style="width: 100%; margin-bottom: 10px;">{{ 'Intellitrailer.Booking.Dialog.FindLocationDialog_' + product.Id + '.Yes' | translate : 'Yes' }}</button>
    <button *ngIf="!bookingConfirmed" mat-button mat-raised-button color="accent" (click)="onNoClick()" style="width: 100%; margin-bottom: 10px; color: var(--action-background-color) !important; background-color: white !important; ">{{ 'Intellitrailer.Booking.Dialog.FindLocationDialog_' + product.Id + '.ContinueWithout' | translate : 'Continue without' }}</button>
  </div>
  <div class="d-flex justify-content-center">
    <button *ngIf="bookingConfirmed" mat-button mat-raised-button color="primary" (click)="closeDialog()" style="min-width: 50px; min-height: 35px;">{{ 'Intellitrailer.Booking.AddonDialog.CloseBtn' | translate : 'Close' }}</button>
  </div>
  </div>
