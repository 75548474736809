<div class="t4-container">
  <h2 *ngIf="title">{{ title }}&nbsp;</h2>
  <div class="turboContainer" [ngClass]="{ inline: isInline, error: isInvalid }" [ngStyle]="{ 'height': height, 'overflow': overflow }">
    <div class="toolbar">
      <ng-content select="[toolbar]"></ng-content>
    </div>
    <ng-content></ng-content>
    <div class="errorMessage">{{ errorMessage }}</div>
    <div class="loadingMessage" *ngIf="isLoading">{{ loadingMessage }}</div>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="turboContainerLoader"></mat-progress-bar>
  </div>
</div>
