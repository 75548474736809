import { Component, ElementRef } from '@angular/core';
import { BookingModel, BookingService, LayoutService, NavigationService } from 'app-components';
import { AppIntegrationService } from 't4-app-integration';
import { LocationInfo, PageBase, RentalInfo, TypeCategoryInfo } from 't4core';

@Component({
  selector: 'selected-object-info',
  templateUrl: './selected-object-info.html',
  styleUrls: ['./selected-object-info.css']
})
export class SelectedObjectInfo extends PageBase {
  public bookingState: BookingModel;
  public loading: boolean = true;

  public selectedCategory: TypeCategoryInfo;

  constructor(
    private navigator: NavigationService,
    el: ElementRef,
    private appService: AppIntegrationService,
    private bookingSvc: BookingService,
    public layout: LayoutService) {
    super(el);
  }

  async ngOnInit() {
    this.bookingState = await this.bookingSvc.getBookingState();
  }

  public back() {
    this.navigator.back();
  }

  public next() {
    this.navigator.executeCommand("Next");
  }

}
