import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service'; // our translate service

@Pipe({
    name: 'translate',
    pure: false
})

export class TranslatePipe implements PipeTransform {
    private _currentKey: string = "";

    private _latestValue: any = null;
    private _latestReturnedValue: any = null;

    private _subscription: Promise<any>;
    private _obj: Promise<any>;

    ngOnDestroy(): void {
        if (this._subscription) {
            this._dispose();
        }
    }

    transform(key: string, defaultValue: string = null, data: any[] = null): any {
        if (this._currentKey == "") {
            this._currentKey = key;
            this._obj = this._translate.translate(key, defaultValue, data);
            this._subscription = this._obj.then((value: Object) => this._updateLatestValue(this._obj, value), e => { throw e; });

            this._latestReturnedValue = this._latestValue;
            return this._latestReturnedValue;
        }

        if (key !== this._currentKey) {
            this._dispose();
            return this.transform(key as any, defaultValue, data);
        }

        if (this._latestValue === this._latestReturnedValue) {
            return this._latestReturnedValue;
        }

        this._latestReturnedValue = this._latestValue;
        return this._latestValue;
    }

    private _dispose(): void {
        this._currentKey = "";
        this._latestValue = null;
        this._latestReturnedValue = null;
        this._subscription = null;
    }

    private _updateLatestValue(async: any, value: Object): void {
        if (async === this._obj) {
            this._latestValue = value;
            this._ref.markForCheck();
        }
    }

    constructor(private _translate: TranslationService, private _ref: ChangeDetectorRef) {
        _translate.languageChanged.subscribe(() => this._dispose());
    }
}
