import { Component, ElementRef } from '@angular/core';
import { BookingModel, BookingService, LayoutService, NavigationService } from 'app-components';
import { AppIntegrationService } from 't4-app-integration';
import { AlternativType, BookingAlternative, LocationInfo, PageBase, RentalAlternative, RentalInfo, ApiService, PriceModel, T4MessageBox, UIService } from 't4core';
import * as moment from 'moment';

@Component({
  selector: 'select-alternative',
  templateUrl: './select-alternative.html',
  styleUrls: ['./select-alternative.css']
})
export class OWAlternativeSelectorComponent extends PageBase {
  public bookingState: BookingModel;
  public loading: boolean = true;


  public availableOptions: RentalAlternative[] = [];
  public myOption: RentalAlternative;
  public isMyOptionAvailable: boolean = false;

  constructor(public Api: ApiService, public UI: UIService, private navigator: NavigationService, el: ElementRef, private appService: AppIntegrationService, private bookingSvc: BookingService, public layout: LayoutService) {
    super(el);
  }
   
  async ngOnInit() {
    this.bookingState = this.bookingSvc.getBookingState();

    this.loading = true;
    var token = this.UI.beginLoading("Intellitrailer.OW.Alternatives.msgCheckingAvailability", "Checking availability", null);
    this.availableOptions = await this.getOptions();
    this.UI.loaderCompleted(token);
    this.loading = false;

  }
   
  public back() {
    this.navigator.back();
  }

  public async selectOption(option: RentalInfo) {
    this.bookingState.booking.PrimaryObjectTypeId = option.PrimaryObjectTypeId;
    this.bookingState.booking.PrimaryType = option.PrimaryType;
    this.next();
  }

  public next() {
    this.bookingSvc.saveState(this.bookingState);

    this.navigator.executeCommand("Next");
  }

  public async getOptions(): Promise<RentalAlternative[]> {
    var ret = await this.Api.get<any>("Rental/FindAlternativeBookings",
      {
        pickupTime: this.bookingState.booking.PickupTime.clone().toISOString(),
        returnTime: this.bookingState.booking.ReturnTime.clone().toISOString(),
        typeCategoryId: 22,
        objectTypeId: this.bookingState.booking.PrimaryObjectTypeId,
        pickupLocationId: this.bookingState.booking.PickupLocation.Id,
        returnLocationId: this.bookingState.booking.ReturnLocation.Id
      });

    if (!ret || !ret.some(x => x.Type == 3)) {
      var price = await this.Api.get<PriceModel>("Price/GetRentalPrice",
        {
          pickupTime: this.bookingState.booking.PickupTime.clone().toISOString(),
          returnTime: this.bookingState.booking.ReturnTime.clone().toISOString(),
          objectTypeId: this.bookingState.booking.PrimaryObjectTypeId,
          pickupLocationId: this.bookingState.booking.PickupLocation.Id,
          returnLocationId: this.bookingState.booking.ReturnLocation.Id,
          useInsurance: true,
          webBooking: true,
          createdAt: moment().toDate().toISOString(),
          actualReturnTime: moment().toDate().toISOString(),
          discountId: null,
          addedProducts: null,
          includeDefaultAddons: true,
          customerId: null,
          userId: null,
          bookingLat: null,
          bookingLng: null
        });

      this.myOption = {
        Type: AlternativType.Perfect,
        PickupTime: this.bookingState.booking.PickupTime.clone(),
        ReturnTime: this.bookingState.booking.ReturnTime.clone(),
        PickupLocationId: this.bookingState.booking.PickupLocation.Id,
        ReturnLocationId: this.bookingState.booking.ReturnLocation.Id,
        ObjectTypeId: this.bookingState.booking.PrimaryObjectTypeId,
        Id: 0,
        Price: price,
        HasPickupLocationChanged: false,
        HasPickupTimeChanged: false,
        HasReturnLocationChanged: false,
        HasReturnTimeChanged: false,
        HasObjectTypeChanged: false,
        IsPreliminary: true,
        LocationDiff: 0,
        QueueIndex: 0,
        AverageMinAvailability: 0,
        AverageMaxAvailability: 0,
        PredictedBindDate: undefined
      };
    } else if (ret.some(x => x.Type == 3)) {
      var opt = ret.find(x => x.Type == 3);
      this.myOption = opt;
      ret.splice(ret.indexOf(opt), 1);
      this.isMyOptionAvailable = true;
    }


    for (var i = 0; i < ret.length; i++) {
      var item = ret[i];
      if (ret.some(x =>
        //x.PickupLocationId == item.PickupLocationId && // Same pickup
        x.ObjectTypeId == item.ObjectTypeId && // Same Type
        x.LocationDiff < item.LocationDiff && // CLoser
        x.Price.DiscountedTotalPrice <= item.Price.DiscountedTotalPrice // Not cheaper
      )) {
        ret.splice(i, 1);
        i--;
      }
    }

    return ret.sort(x => x.LocationDiff);
  }

  public async queueMe(alt: RentalAlternative) {
    await this.UI.alertBox({
      headline: "Information",
      message: "Du har valt att ställa dig i kö på ett alternativ som just nu inte finns tillgängligt. Vi kommer aktivt arbeta för att uppfylla dina önskemål och hitta ett släp som ligger så nära som möjligt. Du kommer få en bekräftelse när vi hittat ett.",
      buttons: [{ text: "Ok, jag förstår!", value: 'Ok', primary: true }]
    });

    this.book(alt);
  }

  public async book(alt: RentalAlternative) {
    this.bookingState.booking.PickupLocation = await this.bookingSvc.getLocation(alt.PickupLocationId);
    this.bookingState.booking.ReturnLocation = await this.bookingSvc.getLocation(alt.ReturnLocationId);
    this.bookingState.booking.PickupTime = alt.PickupTime;
    this.bookingState.booking.ReturnTime = alt.ReturnTime;
    this.bookingState.booking.PrimaryObjectTypeId = alt.ObjectTypeId;
    this.bookingState.booking.PrimaryType = await this.bookingSvc.getType(alt.ObjectTypeId)

    this.next();
  }
}
