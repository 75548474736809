import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceLocator } from '../ServiceLocator';
import { ComponentBase } from '../components/component.base';




export class PageBase extends ComponentBase {
    protected moduleId: string = "";

    private ComponentElement: ElementRef;

    constructor(el: ElementRef, moduleId: string = "turbo") {
        super();

        this.moduleId = moduleId;
        this.ComponentElement = el as ElementRef;
        
        var router = ServiceLocator.injector.get(Router);

      var path = "";
      if (router.parseUrl(router.routerState.snapshot.url)?.root?.children?.primary?.segments) {
        for (var x of router.parseUrl(router.routerState.snapshot.url)?.root?.children?.primary?.segments)
          path += "/" + x;
      } else {
        path = router.routerState.snapshot.url;
      }


      this.Insights.logPageView(router.routerState.snapshot.url);
    }
}
