import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injector, NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
/* Authentication */
import { NumericKeyboard } from './components/numericKeyboard/numericKeyboard';
import { T4Container } from './components/t4Container/t4Container';
import { PinDialog } from './dialogs/PinDialog/PinDialog';
import { PromptDialog } from './dialogs/promptDialog/promptDialog';
import { T4MessageBox } from './dialogs/t4MessageBox/t4messagebox';
/* Other */
import { LocalStorage } from './LocalStorage';
import { CurrencyPipe, DecimalPipe, PercentPipe, TimePipe } from './pipes/number.pipe';
/* Pipes */
import { TranslatePipe } from './pipes/translation.pipe';
import { ServiceLocator } from './ServiceLocator';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { CacheService } from './services/cache.service';
import { InsightsService } from './services/insights.service';
/* Services */
import { SettingsService, T4Environment } from './services/settings.service';
import { TranslationService } from './services/translation.service';
import { UIService, UIServiceSettings } from './services/ui.service';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomerFormComponent } from './components/customerForm/customer-form';
import { TextFieldComponent } from './components/formTextField/text-field';
import { PhoneFieldComponent } from './components/formPhoneField/phone-field';
import { NotificationService } from './services/notification.service';
export function getWindow() { return window; }

@NgModule({
    declarations: [
        TranslatePipe,
        DecimalPipe,
        PercentPipe,
        CurrencyPipe,
        TimePipe,
        T4MessageBox,
        PromptDialog,
        PinDialog,
        T4Container,
        NumericKeyboard,
        CustomerFormComponent,
        TextFieldComponent,
        PhoneFieldComponent
    ],
    imports: [BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        MatDialogModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        MatInputModule,
        MatButtonModule,
        MatSnackBarModule,
    ],
    providers: [
        SettingsService,
        ApiService,
        UIService,
        UIServiceSettings,
        AuthService,
        TranslationService,
        LocalStorage,
        InsightsService,
        CacheService,
        TranslatePipe,
        DatePipe,
        DecimalPipe,
        PercentPipe,
        CurrencyPipe,
        TimePipe,
        NotificationService,
        { provide: ErrorHandler }
    ],
    exports: [
        T4Container,
        T4MessageBox,
        NumericKeyboard,
        TranslatePipe,
        DecimalPipe,
        PercentPipe,
        CurrencyPipe,
        PinDialog,
      CustomerFormComponent,
      TextFieldComponent
    ]
})
export class T4coreModule {

  private static mergedUISettings: UIServiceSettings = new UIServiceSettings();

  constructor(injector: Injector) {
    ServiceLocator.injector = injector;
  }

  public static forRoot(settings: UIServiceSettings): ModuleWithProviders<T4coreModule> {
    return {
      ngModule: T4coreModule,
      providers: [
        { provide: UIServiceSettings, useValue: T4coreModule.mergedUISettings}
      ]
    }
  }
}


