<mat-dialog-content>
    <h1>{{ headlineKey | translate : headlineDefault : headlineData }}</h1>
    <t4-container>
        <div style="text-align: center">{{questionKey | translate : questionDefault : questionData}}</div>
        <mat-form-field style="width: 100%">
            <textarea matInput placeholder="{{'Turbo.core.PromptDialog.txtResponse' | translate : 'Enter response here...'}}" [(ngModel)]="response" matTextareaAutosize matAutosizeMinRows="5" matAutosizeMaxRows="5"></textarea>
        </mat-form-field>
    </t4-container>
</mat-dialog-content>
<mat-dialog-actions>
        <button mat-button style="margin: 2px;" color="primary" [mat-dialog-close]="response">{{ 'turbo.core.PromptDialog.cmdConfirm' | translate : 'OK' }}</button>
        <button mat-button style="margin: 2px;" [mat-dialog-close]="false">{{ 'turbo.core.PromptDialog.cmdCancel' | translate : 'Cancel' }}</button>
</mat-dialog-actions>