import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { LocalStorage } from '../LocalStorage';
import { User, ClientSettings, BIDToken } from '../models/contracts/Turbo.Data.Contracts';

import { InsightsService } from './insights.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Moment } from 'moment';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private locationId: string = "";
  private username: string = "";


  private storedTokens: StoredToken[] = [];

  constructor(private http: HttpClient, private settings: SettingsService, private localStorage: LocalStorage, private insights: InsightsService) {
    var savedData = this.localStorage.getObject("StoredTokens");

    if (savedData && savedData.length > 0) {
      this.storedTokens = savedData.filter(x => x.expires > new Date());
    }
  }

  public async Authenticate(locationId: string, username: string, password: string, customerUserId: string = null): Promise<boolean> {
    var stored = this.storedTokens.find(x => x.username == username && x.locationId == locationId && x.customerUserId == customerUserId);

    if (stored) {
      if (stored.expires > new Date()) {
        return await this.SetAuthentication(stored);
      } else {
        this.storedTokens.splice(this.storedTokens.indexOf(stored), 1);
      }
    }

      var userId = username;
    if (locationId) userId = locationId + "_" + userId;
    var data = "grant_type=password&username=" + userId + "&password=" + password;

      this.locationId = locationId;
      this.username = username;
      var token = await this.token<string>("token", data);
      token[".expires"] = new Date(new Date().getTime() + (token["expires_in"] * 900));

        this.settings.AuthorizationToken = token;

        var userInfo = await this.refreshSettings(customerUserId);
        this.settings.setAutentication(token, userInfo, false);
        this.insights.setAuthenticatedUserId(userInfo.Username);

    stored = {
      locationId: locationId,
      username: username,
      customerUserId: customerUserId,

      token: token,
      expires: token[".expires"],
      userInfo: userInfo,
      rights: this.settings.rights,
      clientSettings: this.settings.clientSettings,
    };

    // Store settings if not impersonating
    if (!this.settings.impersonateUser) {
      this.storedTokens.push(stored);
      this.localStorage.setObject("StoredTokens", this.storedTokens);
    }
        var savedData = {
            locationId: locationId,
            username: username,
            password: password,
        };

        this.localStorage.setObject("AuthenticationData", savedData);

        return true;
  }

  public clearTokens(until: Date) {
    for (var i = 0; i < this.storedTokens.length; i++) {
      if (this.storedTokens[i].expires < until) {
        this.storedTokens.splice(i, 1);
        i--;
      }
    }

    this.localStorage.setObject("StoredTokens", this.storedTokens);
  }

  public async SetAuthentication(storedToken: StoredToken): Promise<boolean> {
    this.settings.AuthorizationToken = storedToken.token;
    this.settings.AuthorizationToken[".expires"] = storedToken.expires;

    this.settings.clientSettings = storedToken.clientSettings;
    this.settings.rights = storedToken.rights;
    this.settings.setAutentication(storedToken.token, storedToken.userInfo, false);
    this.insights.setAuthenticatedUserId(storedToken.userInfo.Username);

    return true;
  }


    public async Reauthenticate(): Promise<boolean> {
        var savedData = this.localStorage.getObject("AuthenticationData");
        if (savedData && savedData.password != undefined) {
            var locationId = savedData.locationId;
          var username = savedData.username;
            var password = savedData.password;

            return await this.Authenticate(locationId, username, password);
        }

        return false;
  }

  public async refreshSettings(customerUserId: string = null) : Promise<User> {
    var userInfo = await this.GetUserInfo(customerUserId);
    this.settings.clientSettings = userInfo.Settings;
    this.settings.rights = userInfo.Rights;

    return userInfo;
  }

    public Logout() {
        this.localStorage.setObject("AuthenticationData", null);
        this.settings.authenticationExpired();
    }

    public GetUserInfo(customerId:string = null): Promise<User> {
        return this.get<User>("Account/GetUserInfo", null, customerId).then<User>(
            (user: User) => {
                return user;
            });
  }
  
  private async token<T>(path: string, data?: any): Promise<T | null> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic VDRDbGllbnQ6NTExNTM2RUYtRjI3MC00MDU4LTgwQ0EtMUM4OUMxOTRGNjlB'
    });

      try {
        return await this.http.post<T>("https://auth.turbo4.net/connect/token", data, { observe: 'body', headers: headers }).toPromise();
      } catch (ex) {
        data = data.toUpperCase().replace("GRANT_TYPE=PASSWORD", "grant_type=password");
        return await this.http.post<T>("https://auth.turbo4.net/connect/token", data, { observe: 'body', headers: headers }).toPromise();
      }

  }

  private async get<T>(path: string, params?: any, customerId?: string): Promise<T> {
        var headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.settings.getToken().access_token,
          'TI': this.settings.impersonateUser ? this.settings.impersonateUser : '',
          "CustomerUID": (customerId ?? "")
        });

        if (params) {
            var p = new URLSearchParams();
            for (var x in params) {
                var k = x;
                var val = params[x];
                p.append(k, val);
            }
            path += '?' + p.toString();
        }

      try {
        return await this.http.get<T>(this.settings.getApiBase() + path, { observe: 'body', headers: headers }).toPromise();
      } catch (ex) {
        ex.path = path;
        ex.params = JSON.stringify(params);

        for (var h in headers.keys)
          ex["Header_" + h] = headers.get(h);

        throw ex;
      }
  }
}


export class StoredToken {
  public locationId: String;
  public username: String;
  public customerUserId: String;

  public token: any;
  public expires: Date;

  public userInfo: User;
  public rights: string[];
  public clientSettings: ClientSettings;
}
