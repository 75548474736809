import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationStart, Route, Router, RouterStateSnapshot, Routes } from '@angular/router';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { ApiService, AuthService, InsightsService, RentalInfo, SettingsService, TranslationService, UIService } from 't4core';
import * as uuid from 'uuid';
import { ApplicationContext, NavigationStep, NavType } from '../../Models/NavigationStep';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { LayoutService } from '../LayoutService/layout.service';
import { BookingService } from '../BookingService/booking-service.service';
import { NavigationAction } from '../../Models/NavigationAction';

@Injectable({
  providedIn: 'root',
})
export class NavigationResolver  {
  private static _firstNavigation: boolean = true;
  constructor(
    private aviationService: NavigationService,
    private authService: AuthService,
    private Api: ApiService,
    private appSettings: AppSettingsService,
    private settings: SettingsService,
    private insights: InsightsService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string> {
    // If no step is loaded or we are loading the reset view. Do nothing
    if (!this.aviationService.activeStep || route.url.toString().indexOf("ResetView") >= 0) {
      return;
    }

    // Determine the run context of the view we are loading
    var newContext: ApplicationContext = ApplicationContext.Owner;
    if (this.aviationService.activeStep.Context) newContext = this.aviationService.activeStep.Context;

    // Make sure the view will run in the correct context
    if (this.aviationService._activeContext != newContext || NavigationResolver._firstNavigation) {
      // When the first view is loaded its no longer the initial step
      NavigationResolver._firstNavigation = false;

      // ===== Customer context ===== ///
      // Make sure a user is authenticated and available in the app
      if (newContext == ApplicationContext.Customer) {
        if (this.appSettings.userId) { // If there is a user id available
          this.insights.setProperty("Context", this.appSettings.userId);
          this.insights.logEvent("Switching to customer context", { step: this.aviationService.activeStep.Key });

          // Sign in as the customer user (UserName/Password are the same)
          await this.authService.Authenticate("", this.appSettings.userId, this.appSettings.userId);
        }
        else { // If user id is not available, sign out and reset
          // Log event
          this.insights.logEvent("401 - Unauthenticated", { step: this.aviationService.activeStep.Key });

          // Reset user
          this.appSettings.setUser(null);

          // Fall back to main context
          this.insights.setProperty("Context", this.appSettings.contextId);
          this.insights.logEvent("Falling back to owner context", { step: this.aviationService.activeStep.Key });
          await this.authService.Authenticate("", this.appSettings.contextId, this.appSettings.privateKey, this.appSettings.userId);

          // Navigate to app start page
          this.aviationService.navigateToView("Start");
        }
      }

      // ===== Regional context ===== //
      else if (newContext == ApplicationContext.Region && this.settings.clientSettings.RentalLocationId == null) {
        // Find the region based on selected location id
        var region = await this.getClosestParent(this.appSettings.localLocationId);
        if (region) {
          this.insights.setProperty("Context", region.Item1);
          this.insights.logEvent("Switching to regional context", { step: this.aviationService.activeStep.Key, region: region.Item2 });
          await this.authService.Authenticate("", region.Item1, region.Item2, this.appSettings.userId);
        }
        else {
          // Fallback to  owner context
          this.insights.setProperty("Context", this.appSettings.contextId);
          this.insights.logEvent("Falling back to owner context", { step: this.aviationService.activeStep.Key });
          await this.authService.Authenticate("", this.appSettings.contextId, this.appSettings.privateKey, this.appSettings.userId);
        }
      }

      // ===== Owner context ===== //
      else {
        this.insights.setProperty("Context", this.appSettings.userId);
        this.insights.logEvent("Switching to owner context", { step: this.aviationService.activeStep.Key });
        await this.authService.Authenticate("", this.appSettings.contextId, this.appSettings.privateKey, this.appSettings.userId);
      }

      // Save the new context
      this.aviationService._activeContext = newContext;
    }
  }

  // Cached regional contexts
  private regionalContexts: any[] = [];
  // Regonal contexts are the level below the owner context. They contain localized settings for that particular region
  // This method gets the regional context based on a rental location
  // The regional context returned will be the root of the leg containing the location
  public async getClosestParent(locationId: number): Promise<any> {
    // Validate input
    if (!locationId) return null;

    // Load from memory if available
    var ret = this.regionalContexts.find(x => x.LocationId == locationId);
    if (ret) return ret.Data;

    // Get regional context, cache and return
    var closestParent = await this.Api.get<any>("Account/FindClosestParent", { locationId: locationId, parentId: this.appSettings.contextId });
    this.regionalContexts.push({ LocationId: locationId, Data: closestParent });

    return closestParent;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private static Steps: NavigationStep[] = [];
  private routes: Routes;

  private isInitialStep: boolean = true;
  private isGoingBack: boolean = false;
  public _activeContext: ApplicationContext = ApplicationContext.Owner;
  public _isInitialized: boolean = false;
  private history: NavigationStep[] = [];

  public settingsParameter: string;

  private _activeStep: NavigationStep;
  public get activeStep(): NavigationStep { return this._activeStep; }
    private setActiveStep(step: NavigationStep) {
        if (!step) {
            this.insights.logError(new Error("Active step set to null"));
        }

        this._activeStep = step;
    }

  // Converts the loaded NavigationSteps to routes that can be used in the angular routing engine (Should only be called from app.module)
  public static getRoutes(steps: NavigationStep[]): Routes {
    this.Steps = steps;
    let routes = steps.map<Route>(x => ({ path: x.Path, component: x.Component, data: { animation: x.Key }, resolve: { init: NavigationResolver } }));
    return routes;
  }



  // Load application navigation flows and store in memory
  private async LoadRoutes() {
    // Load routes either from context page or from theme file
    var steps = this.appSettings.getContextParam('routes');
    if (!steps) {
      let theme: string = this.appSettings.settings.overrideRoutes ? this.appSettings.settings.theme : 'Default';
      let path: string = `/Styles/${theme}/Routes/${this.appSettings.typeSettings.appType}.json?v=` + this.appSettings.clientVersion;

      steps = await this.http.get<NavigationStep[]>(path).toPromise();
    }

    // Store in memory
    NavigationService.Steps = steps;

    // Convert to routes and return
    this.routes =  NavigationService.Steps.map<Route>(step => ({
      path: step.Path,
      data: { animation: step.Key },
      resolve: { init: NavigationResolver },
      component: AppSettingsService.components[step.Component.toString()]
    }));
  }

  // Finds the NavigationStep corresponding to a given relative path
  private findStepByPath(path: string): NavigationStep {
    // Detect parameters in path
    var len = -1;
    if (path.indexOf(";") > 0) len = path.indexOf(";");
    if (path.indexOf("?") > -1) len = path.indexOf("?");

    // Remove any parameters and try to find the step based on the path
    if (len >= 0)
      return NavigationService.Steps.find(x => x.Path == path.substring(0, len));
    else
      return NavigationService.Steps.find(x => x.Path == path);
  }

  constructor(
    public router: Router,
    private location: Location,
    private appService: AppIntegrationService,
    private Api: ApiService,
    private UI: UIService,
    public layoutService: LayoutService,
    zone: NgZone,
    private http: HttpClient,
    private appSettings: AppSettingsService,
    private insights: InsightsService,
    private bookingSvc: BookingService
    ) {
    window['back'] = () => {
      zone.run(() => {
        this.back();
      });
    };

    // Monitor navigation event to keep update step up to date
    this.router.events.pipe(filter(x => x instanceof NavigationStart))
      .subscribe((x: NavigationStart) => {
        if (x.navigationTrigger == 'popstate') {
          this.back();
        }
      });
  }

  // Initialize the navigation service
  public async init() {
    // Load the routes
    await this.LoadRoutes();

    // Reset router configuration (Make sure it picks up the newly loaded routes)
    this.router.resetConfig(this.routes);

    // Reset history
      this.history = [];

      // If a step is not already loaded. Determine and load first view
    if (!this._activeStep) {
      var path = this.router.url.substring(1);
      await this.processInitialNavigation(path);
    }
  }

  // Process the things necessary to load the initial view.
  public async processInitialNavigation(path: string) {
    // Find the initial step based on the path
    let step: NavigationStep = this.findStepByPath(path);
    var params = {};

      // If a matching step is not found
      // OR this is the default route ("/")
      // OR the step does not allow cold start (Supports starting at that view).
      if (!step || step.Path == "" || !step.AllowColdStart) {
        const userId: string = this.appSettings.userId;
        if (userId) {
          // Determine whit step to start from
          var action = await this.determineStartingStep();
          step = action.Step;
          params = action.Params;
        }
        else {
          // Find the default route
          step = NavigationService.Steps.find(x => x.Path == "");
        }
    }

    // Add query string parameters
    if (this.appSettings.params) {
      // Update all parameters that has not been specifically set by the command
      for (var i = 0; i < Object.keys(this.appSettings.params).length; i++) {
        var key = Object.keys(this.appSettings.params)[i];

        // Only set the parameter value if it has not already been specified
        if (params[key] == undefined) params[key] = this.appSettings.params[key];
      }
    }

    // Execute the new step
    await this.executeInitialNavigation(step, params);
  }

  // Determine which view to load when the user opens the app based on the situation
  private async determineStartingStep(): Promise<NavigationAction> {
    // Find all active or future bookings
    const activeOrFutureBookings: RentalInfo[] = await this.Api.get<RentalInfo[]>("Rental/GetMyActiveAndFutureBookings");
    const upcomingBookings = activeOrFutureBookings?.filter(rental => moment.duration(rental.PickupTime.diff(moment.now())).hours() <= 2);
    const activeBookings = activeOrFutureBookings?.filter(rental => rental.Status == 100 || rental.Status == 150);

    // If the user has an active booking, open that (User is currently renting)
    if (activeBookings && activeBookings.length === 1) {
      let tmp = NavigationService.Steps.find(x => x.Path == "Rental/InFlight");
      return { Step: tmp, Params: { rentalId: activeBookings[0].Id } };
    }
    // If the user has 1, ond only 1, future booking. open that.
    else if (upcomingBookings && upcomingBookings.length === 1) {
      let tmp = NavigationService.Steps.find(x => x.Path == "Rental/BookingInfo");
      return { Step: tmp, Params: { rentalId: upcomingBookings[0].Id } };
    }
    // If there are more than one future booking. Show that list.
    else if (activeOrFutureBookings && activeOrFutureBookings.length > 0) {
      var tmp =  NavigationService.Steps.find(x => x.Path == "MyPage/Overview");
      return { Step: tmp, Params: {  } };
    }
    // Else we load the default route
    else {
      var tmp = NavigationService.Steps.find(x => x.Path == "");
      return { Step: tmp, Params: { } };
    }
  }

  // Invoke the routing system by navigating to the selected step
  private async executeInitialNavigation(step: NavigationStep, params: any) {
    // Get parameters
    //let params = action.Params;
    //if (!params) params = this.appSettings.params;

    // If rentalId is specified initialize services
    //if (params && params["rentalId"]) {
    //  await this.bookingSvc.restartSession(parseInt(this.appSettings.params["rentalId"]));
    //}

    // Set active step
    await this.setActiveStep(step);

    // Clear layout elements
    this.layoutService.clear();


    // Apply default route params if they are not already set
    if (step.Params) {
      for (var p in step.Params)
        if (!params[p]) params[p] = step.Params[p];
    }

    // Navigate  to the view with or without 
    if (params && Object.keys(params).length > 0 && step.Path != "") {
      await this.router.navigate([step.Path, params]);
    }
    else {
      await this.router.navigate([step.Path]);
    }
  }

  // Checks if authentication is required for a given view
  public isAuthenticationRequired(key: string): boolean {
    var step = NavigationService.Steps.find(x => x.Key == key);
    if (step && step.Context) {
      return step.Context === ApplicationContext.Customer;
    }
    else return false;
  }


  
  // Execute a command on the current view, taking  the user to the next view
  public async executeCommand(command: string, params: any = {}, isGoingBack: boolean = false) {
    // Store direction information
    if (!this.activeStep) {
      this.insights.logError(new Error("ActiveStep is null: ExecuteCommand"), { command: command, Initialized: this.appService.Device.IsInitialized.toString() });
    }
    if (this.activeStep.ConfirmOnLeave && (this.activeStep.ConfirmOnLeave.DisplayOn.find(x => x as NavType == NavType.NormalNavigation as NavType) > -1)) {
      var confirm = await this.UI.confirm(this.activeStep.ConfirmOnLeave.TitleKey, this.activeStep.ConfirmOnLeave.TitleText, this.activeStep.ConfirmOnLeave.ContentKey, this.activeStep.ConfirmOnLeave.ContentText);
      if (!confirm) return;
    }
    this.layoutService.clear();
    this.isGoingBack = isGoingBack;

    // Log command
    this.insights.logEvent('Executing command: ' + command, { Params: JSON.stringify(params) });

    //Find the requested action
    var action = this.activeStep.Actions.find(x => x.Command == command);
    if (action) {

      // Find the step we are going to navigate to
      var nextStep = NavigationService.Steps.find(x => x.Key == action.TargetStep);
      if (nextStep) {
        this.gotoStep(nextStep, params);
      }
    }
  }

  // Load a specified navigation step
  private async gotoStep(nextStep: NavigationStep, params: any = {}) : Promise<boolean> {
    // Handle any "confirm on leave" settings for the current view
    if (this.activeStep.ConfirmOnLeave && (this.activeStep.ConfirmOnLeave.DisplayOn.find(x => x as NavType == NavType.NormalNavigation as NavType) > -1)) {
      var confirm = await this.UI.confirm(this.activeStep.ConfirmOnLeave.TitleKey, this.activeStep.ConfirmOnLeave.TitleText, this.activeStep.ConfirmOnLeave.ContentKey, this.activeStep.ConfirmOnLeave.ContentText);
      if (!confirm) return false;
    }

    // If we are navigating to the same view (refresh) - cancel
    if (this.activeStep.Path === nextStep.Path) {
      return false;
    }

    // Reset visual elements
    this.layoutService.clear();

    // If we are moving forward we need to add a step to the history in order for the back button to work correctly
    if (!this.isGoingBack) this.history.push(this.activeStep);

    // Set the target step as the active one
    this.setActiveStep(nextStep);

    // Load parameters from step specification
    if (!params) params = {};
    if (this.activeStep.Params) {
      // Update all parameters that has not been specifically set by the command
      for (var i = 0; i < Object.keys(this.activeStep.Params).length; i++) {
        var key = Object.keys(this.activeStep.Params)[i];

        // Only set the parameter value if it has not already been specified
        if (params[key] == undefined) params[key] = this.activeStep.Params[key];
      }
    }

    // If no params exists set to null to avoid routing problems
    if (!params || Object.keys(params).length <= 0) params = null;

    // Navigate to the new view with or without parameters
    if (!params)
      this.router.navigate([this.activeStep.Path]);
    else
      this.router.navigate([this.activeStep.Path, params]);

    return false;
  }

  //Navigation from the menu
  //Menu navigation allows routing to any view
  public async navigateToView(command: string, params: any = {}) {
    // Store direction
    this.isGoingBack = false;

    // Log the navigation event
    this.insights.logEvent('Navigating to view: ' + command, {...params});

    // Find and navigate to the requested step
    var nextStep = NavigationService.Steps.find(x => x.Key == command);
    if (nextStep) {
      this.gotoStep(nextStep, params);
    }
  }

  //Reload current route
  public async reload(params: any = {}) {
    var route = this.activeStep.Path;
    this.router.navigateByUrl('/Reset/ReloadView', { skipLocationChange: true }).then(() =>
      this.router.navigate([route, params]));
  }

  // Retrieves the value of a given parameter
  public getParam(param: string): string {

    //Angular query paremeter structure
    if (this.router.url.indexOf(";") > -1) {
      var params: string = this.router.url.substring(this.router.url.indexOf(";") + 1, this.router.url.length + 1);
      var paramlist: string[] = params.split(";");
      for (let p of paramlist) {
        if (p.indexOf(param + "=") !== -1) {
          //Parameter found, returns value
          var paramValue = p.split("=")[1];
          if (paramValue && paramValue.length > 0) {
            return paramValue;
          }
        }
      }
    }

    //Normal query parameter structure
    if (this.router.url.indexOf("?") > -1) {
      var params: string = this.router.url.substring(this.router.url.indexOf("?") + 1, this.router.url.length + 1);
      var paramlist: string[] = params.split("&");
      for (let p of paramlist) {
        if (p.indexOf(param + "=") !== -1) {
          //Parameter found, returns value
          var paramValue = p.split("=")[1];
          if (paramValue && paramValue.length > 0) {
            return paramValue;
          }
        }
      }
    }

    // Static parameter defined on route?
    if (this.activeStep.Params && this.activeStep.Params[param]) {
      return this.activeStep.Params[param];
    }

    //Parameter not found
    return null;
  }

  // Go 1 step backwards in the current step sequence
  public async back() {
    // Does active step have a defined back action?
    var backCommand = this.activeStep.Actions.find(x => x.Command == "Back");
    if (backCommand) {
      if (backCommand.TargetStep != "Stay") {
        // Get the last item of the history (Put it back afterwards to enable correct processing below)
        var pop = this.history.pop();
        this.history.push(pop);

        // Find the step from the back command
        var next = NavigationService.Steps.find(x => x.Key == backCommand.TargetStep);

        // Go through history until we find the target step, removing one by one
        while (pop && next && pop.Path != next.Path) {
          pop = this.history.pop();
        }

        // Then extecute the back command on the active step
        await this.executeCommand("Back", null, true);
      }
    }
    else // If no back-command is specified, use history
    {
      // Are there any history in the logs?
      if (this.history.length > 0) {
        // Get the last one
        var nextStep = this.history.pop();

        // Navigate to it
        this.isGoingBack = true;
        await this.gotoStep(nextStep);
      }
      else 
      {
        // Do nothing (Reached the end of history)
      }
    }
  }
}
