import { Injectable, EventEmitter, isDevMode } from '@angular/core';
import {   } from 'rxjs';
import { ClientSettings, SystemRights } from '../models/contracts/Turbo.Data.Contracts';
import { User } from '../models/contracts/Turbo.Data.Contracts';


@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  public Environment: T4Environment = T4Environment.Production;
  private environmentSpecified: boolean = false;

  private apiBase: string;
  private apiBaseSpecified: boolean = false;

  public appInsightsKey = "";

  public appName: string = "";
  public appVersion: string = "";

    public AuthorizationToken: any;

  public impersonateUser: string;
  public customerUserId: string;

    public rights: string[] = [];
    public may(right: SystemRights) {
      return this.rights.indexOf(SystemRights[right]) >= 0;
    }

    public getToken(): any {
        if (this.AuthorizationToken != undefined) return this.AuthorizationToken;

        return "";
    }

    private user: User | null;
    public getUser(): User { return this.user!; }    

    private _clientSettings: ClientSettings;
    public get clientSettings() { return this._clientSettings; }
    public set clientSettings(value: ClientSettings) {
        this._clientSettings = value;
        this.settingsChanged.emit(value);
    }

  constructor() {
    this.authenticationChanged = new EventEmitter();
    this.settingsChanged = new EventEmitter();

    if (isDevMode()) {
      this.setEnvironment(T4Environment.Development);
      this.apiBaseSpecified = true;
    } else {
      this.setEnvironment(T4Environment.Production);
    }
  }


  public setEnvironment(env: T4Environment, override: boolean = false) {
    if (this.environmentSpecified) return;
    if (override) this.environmentSpecified = true;

    this.Environment = env;

    // Do not override if specifically set
    if (this.apiBaseSpecified) return;

    switch (env) {
      case T4Environment.Development:
        this.apiBase = "https://localhost:44310/";
        break;

      case T4Environment.Production:
        this.apiBase = "https://t4api.brenderuprental.com/"; //"https://api.turbo4.net/";
        break;

      case T4Environment.Staging:
        this.apiBase = "https://api-staging.turbo4.net/";
        break;

      case T4Environment.Test:
        this.apiBase = "https://api-test.turbo4.net/";
        break;

      case T4Environment.Anata:
        this.apiBase = "https://t4api-anata.azurewebsites.net/";
        break;
    }

    if (override) this.apiBaseSpecified = true;
  }

  public getApiBase() { return this.apiBase; }
  public setApiBase(uri: string) {
    this.apiBase = uri;
    this.apiBaseSpecified = true;
  }
    /// Authentication
    isAuthenticated(): boolean {
        return !!this.AuthorizationToken;
    }

    public setAutentication(token: string, user: User, isImpersonating: boolean) {
        if (!isImpersonating) {
            this.AuthorizationToken = token;
            this.user = user;
        }
        else {
            this.user!.Impersonating = user;
        }

        this.authenticationChanged.emit(true);
    }

    public authenticationExpired(){
        this.user = null;
        this.AuthorizationToken = undefined;
      this.impersonateUser = undefined;

        this.authenticationChanged.emit();
    }

    // Events
    public authenticationChanged: EventEmitter<boolean>;
    public settingsChanged: EventEmitter<ClientSettings>;    
}

export enum T4Environment {
  Production,
  Development,
  Staging,
  Test,
  Anata
}
